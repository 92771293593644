import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useFeatureToggle } from '@flopflip/react-broadcast';

import useApi from '../../hooks/useApi';
import GlobalDraftTab from '../GlobalDraftTab/index';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import { setGlobalDraft } from '../../actions/globalDraft';
import { SelectedGlobalDraftType } from '../../types';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

type DataPropType = {
  data: [
    {
      code: string;
      name: string;
    }
  ];
};

type FilteredGlobalDraftsPropType = {
  value: string;
  label: string;
};

const NavigationTabs = ({ location: { pathname }, history }: RouteComponentProps): JSX.Element => {
  const [globalDraftOptions, setGlobalDraftOptions] = useState<Array<FilteredGlobalDraftsPropType>>([]);

  const isGlobalDraftEnabled = useFeatureToggle('globalDrafts');
  const { data: globalDrafts } = useApi<DataPropType>('get', '/api/v1/brands?globalNetwork=true');
  // eslint-disable-next-line
  const {
    state: {
      draftView: { marketBrands },
    },
    dispatch,
  } = useGlobalStateValue();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (globalDrafts) {
      const filteredGlobalDrafts = globalDrafts?.data?.map(({ code, name }) => ({
        value: code,
        label: name,
      }));
      setGlobalDraftOptions(filteredGlobalDrafts);
    }
  }, [globalDrafts]);

  useEffect(() => {
    if (pathname.endsWith('/globalDrafts') || pathname.endsWith('/globalDrafts/')) {
      if (!urlParams.get('brands')) {
        globalDrafts && urlParams.set('brands', globalDrafts.data[0]?.code);
        const decodedUri = `${decodeURIComponent(urlParams.toString())}`;
        globalDrafts &&
          dispatch &&
          dispatch(
            setGlobalDraft({
              value: globalDrafts.data[0]?.code,
              label: globalDrafts.data[0]?.name,
            })
          );
        history.replace(`/globalDrafts${decodedUri ? `?${decodedUri}` : '/'}`);
      } else {
        let selectedGlobalDraft = globalDrafts?.data?.find(
          (globalBrand: { code: string | null }) => globalBrand.code === urlParams.get('brands')
        );
        if (!selectedGlobalDraft) {
          const targetBrand = marketBrands?.data?.find((codeDetail) => codeDetail.code === urlParams.get('brands'));
          selectedGlobalDraft =
            targetBrand &&
            globalDrafts?.data?.find((globalBrand: { code: string }) => {
              return globalBrand.code === targetBrand?.brandCode;
            });
        }
        if (selectedGlobalDraft) {
          dispatch &&
            dispatch(
              setGlobalDraft({
                value: selectedGlobalDraft.code,
                label: selectedGlobalDraft.name,
              })
            );
          urlParams.set('brands', selectedGlobalDraft.code);
          const decodedUri = `${decodeURIComponent(urlParams.toString())}`;
          history.replace(`/globalDrafts${decodedUri ? `?${decodedUri}` : '/'}`);
        }
      }
    }
    // eslint-disable-next-line
  }, [pathname.endsWith('/globalDrafts'), pathname.endsWith('/globalDrafts/'), globalDrafts, marketBrands]);
  const globalDraftsChangeHandler = (e: SelectedGlobalDraftType) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('brands') !== e.value) {
      const newUrlParams = new URLSearchParams({ brands: e.value });
      const decodedUri = `${decodeURIComponent(newUrlParams.toString())}`;
      dispatch && dispatch(setGlobalDraft(e));
      history.replace(`/globalDrafts${decodedUri ? `?${decodedUri}` : '/'}`);

      trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
        component: 'navigation_tabs',
        label: 'global_drafts',
        type: 'change',
      });
    }
  };

  return (
    <ul className="navigation tabs--horizontal" data-cy="discovery-secondary-navigation">
      <li className={pathname === '/' ? 'tabs--active' : ''}>
        <Link to="/">Syndication</Link>
      </li>
      <li className={pathname === '/globalDrafts' ? 'tabs--active' : ''}>
        {(isGlobalDraftEnabled || pathname.includes('/globalDrafts')) && (
          <GlobalDraftTab title="Shared Drafts" options={globalDraftOptions} onChange={globalDraftsChangeHandler} />
        )}
      </li>
    </ul>
  );
};

export default withRouter(NavigationTabs);
