import React, { MouseEvent } from 'react';
import Slat from '../ContentSlat/Slat';
import SlatNew from '../ContentSlat/SlatNew';
import { RedirectToPreviewLink } from '../Preview';
import { trackEvent } from '../../helpers/googleAnalytics';
import GlobalDraftTitle from '../GlobalDraftTab/GlobalDraftTitle';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import { DiscoverySlatType } from './util/helper';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

type discoverySlatProp = {
  contents: Array<DiscoverySlatType>;
  isFeatureEnabled: boolean;
};

const DiscoverySlat: React.FC<discoverySlatProp> = ({ contents, isFeatureEnabled }: discoverySlatProp) => {
  const {
    state: { globalDraft },
  } = useGlobalStateValue();
  const marketName = (slatData: DiscoverySlatType) => slatData.marketBrand.name;

  const onPreviewLinkClick = (e: MouseEvent, slatData: DiscoverySlatType): void => {
    e.stopPropagation();
    window.open(slatData.publishUri, '_blank', 'noopener,noreferrer');
    trackEvent('Syndicate slat', 'Clicked entity');
    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'discovery_slat',
      label: 'discovery_slat_preview',
      type: 'click',
    });
  };

  const globalDraftSlatTitle = `${globalDraft.selectedGlobalDraft.label} Shared Draft`;
  const getSlat = (slatData: DiscoverySlatType) => (
    <div key={slatData.id}>
      {isFeatureEnabled ? (
        <SlatNew
          key={slatData.id}
          slatData={slatData}
          title={
            <>
              {window.location.pathname.startsWith('/globalDrafts') ? (
                <GlobalDraftTitle title={globalDraftSlatTitle} />
              ) : (
                <RedirectToPreviewLink
                  name={marketName(slatData)}
                  onClick={(event: MouseEvent) => onPreviewLinkClick(event, slatData)}
                  isNameDisabled={!slatData.canSyndicate}
                />
              )}
            </>
          }
        ></SlatNew>
      ) : (
        <Slat key={slatData.id} slatData={slatData} />
      )}
    </div>
  );
  return <>{contents && contents.map((slatData) => getSlat(slatData))}</>;
};

export default DiscoverySlat;
