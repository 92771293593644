import React from 'react';
import { withRouter } from 'react-router';
import { ReloadIcon, UserIcon } from '@condenast/gemini/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import adminLinks from './admin-links.json';
import { ThemeProvider, Card } from '@condenast/gemini';

type menuItemProp = {
  title: string;
  to: string;
  description: string;
  icon: string;
};

const AdminPage = styled.div`
  width: 880px;
  margin: auto;
  max-width: 100%;
`;

const AdminTitle = styled.h2`
  margin: 30px 0;
  font-weight: 700;
  font-size: 32px;
`;

const MenuWrap = styled.div`
  text-align: left;
  margin-left: 8px;
  color: #171717;
`;

const MenuHead = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
`;

const MenuDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const CardStyle = {
  CardShadow: '0px 2px 4px 0px rgba(23, 23, 23, 0.25)',
  CornerRadius: '4px',
};

const CardWrap = styled.div`
  background: #fff;
  &:hover {
    background: #f3f3f3;
  }
  &:active {
    background: #dcdcdc;
  }
`;

const LinkStyle = {
  width: '100%',
  'border-bottom': '1px solid #DCDCDC',
  padding: '16px',
  display: 'inline-flex',
  color: '#3246B9',
};

export const Admin = (): JSX.Element => {
  return (
    <AdminPage>
      <AdminTitle>Encore Admin</AdminTitle>
      <ThemeProvider theme="light">
        {adminLinks.map(
          (menuItem: menuItemProp): JSX.Element => (
            <CardWrap key={menuItem.title}>
              <Card theme={CardStyle}>
                <Link style={LinkStyle} to={menuItem.to} title={menuItem.description}>
                  {menuItem.title.startsWith('Reingest') ? <ReloadIcon size="regular" /> : <UserIcon size="regular" />}
                  <MenuWrap>
                    <MenuHead>{menuItem.title}</MenuHead>
                    <MenuDescription>{menuItem.description}</MenuDescription>
                  </MenuWrap>
                </Link>
              </Card>
            </CardWrap>
          )
        )}
      </ThemeProvider>
    </AdminPage>
  );
};

export default withRouter(Admin);
