import React, { useState } from 'react';
import useApi from '../../hooks/useApi';
import styled from 'styled-components';
import { SyncLoader, ClockWiseRotateIcon } from '../Icons';

const ReIngestMessageWrapper = styled.span`
  background: ${(props) => (props.type === 'error' ? 'red' : 'green')};
  padding: 10px 7px;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  pointer-events: none;
`;

const ButtonWrapper = styled.span`
  button {
    background: none;
    border: 1px solid #adacac;
    color: #3953e7;
    margin: 0px 10px;
    padding: 0px 7px;
    :hover,
    :focus {
      border: 1px solid #3953e7;
      box-shadow: none;
    }
    .re-ingest {
      display: flex;
    }
    svg {
      margin-right: 4px;
    }
  }
  svg {
    g {
      fill: #3953e7;
    }
  }
  @keyframes hop {
    0% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(3px);
    }

    40% {
      transform: translateY(0);
    }
  }

  .hellip {
    display: block;
    margin: auto;

    &.animated {
      circle {
        animation: hop 1s ease infinite;

        &:nth-of-type(1) {
          animation-delay: 0.1s;
        }

        &:nth-of-type(2) {
          animation-delay: 0.2s;
        }
      }
    }
  }
`;

type ReingestProps = {
  slatData: {
    id: string;
    marketBrandCode: string;
  };
};

type SyntheticEvent = {
  stopPropagation(): void;
};

const ReIngest = ({ slatData }: ReingestProps): JSX.Element => {
  const [reIngesting, setReIngesting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasIngested, setHasIngested] = useState(false);
  const { callApi } = useApi(`post`, `/api/v2/contents/re-ingest`);
  const handleReIngest = async (event: SyntheticEvent) => {
    event.stopPropagation();
    setHasError(false);
    setReIngesting(true);
    setHasIngested(false);
    const { id, marketBrandCode } = slatData;

    const config = {
      data: [{ id, marketBrandCode }],
      url: '/api/v2/contents/re-ingest',
      validateStatus: (status: number) => {
        return [200].includes(status);
      },
    };

    const response = await callApi(config);
    if (response.error) {
      setHasError(true);
      setReIngesting(false);
    } else {
      setHasIngested(true);
      setReIngesting(false);
    }
  };

  const reIngestDetails = {
    type: hasError ? 'error' : hasIngested ? 'success' : '',
    message: hasError ? 'Error in Re-ingesting' : hasIngested ? 'Successfully Re-ingested' : '',
  };
  return (
    <>
      <ButtonWrapper>
        <button onClick={(event: SyntheticEvent) => handleReIngest(event)}>
          {reIngesting ? (
            <SyncLoader data-testid="sync-loader" />
          ) : (
            <div className="re-ingest">
              <ClockWiseRotateIcon data-testid="clockwise-rotate" />
              Re-ingest
            </div>
          )}
        </button>
      </ButtonWrapper>
      {(hasError || hasIngested) && (
        <ReIngestMessageWrapper type={reIngestDetails.type}>{reIngestDetails.message}</ReIngestMessageWrapper>
      )}
    </>
  );
};

export default ReIngest;
