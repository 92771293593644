import React from 'react';
import { useIntl } from 'react-intl';
import { RedirectToPreviewLink } from '../../Preview';
import defaultLanguageResource from '../../../translations/en.json';
import styled from 'styled-components';

const SyndicationItemWrapper = styled.li`
  border-bottom: 1px solid #dcdcdc;
  gap: 4px;
`;

const LanguageLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  padding: 2px 6px;
  gap: 4px;
  border: 1px solid #8b8b8b;
  border-radius: 4px;
`;

const Date = styled.span`
  font-size: 14px;
  line-height: 16px;
  padding: 2px 6px;
  gap: 4px;
`;

const SyndicationDetailsWrapper = styled.div`
  margin-bottom: 8px;
`;

const SyndicatorName = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

type syndicationItemProps = {
  marketBrand: { name: string };
  language: string;
  publishDate: string;
  publishUri: string;
};

const SyndicationItem = ({ marketBrand, language, publishDate, publishUri }: syndicationItemProps): JSX.Element => {
  const intl = useIntl();
  const languageLabel = defaultLanguageResource[language as keyof typeof defaultLanguageResource] || language;
  return (
    <SyndicationItemWrapper>
      <SyndicatorName>
        <RedirectToPreviewLink
          name={marketBrand.name}
          syndicationHistory={true}
          onClick={() => {
            window.open(publishUri, '_blank', 'noopener,noreferrer');
          }}
          isNameDisabled={true}
        />
      </SyndicatorName>
      <SyndicationDetailsWrapper>
        <LanguageLabel>{languageLabel}</LanguageLabel>
        <Date>{intl.formatDate(publishDate)}</Date>
      </SyndicationDetailsWrapper>
    </SyndicationItemWrapper>
  );
};
export default SyndicationItem;
