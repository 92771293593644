import styled from 'styled-components';

const SectionWrap = styled.div`
  margin: 16px 0 18px 0;
`;
const HelpButton = styled.button`
  padding: 0 0.5625em 0 0.5625em;
  min-height: 32px;
  line-height: 0;
  background: transparent;
`;

const SyndicationPolicyLink = styled.a`
  font-weight: 400;
  border-bottom: none;
  text-decoration: none;
`;

const BorderBottom = styled.div`
  border-bottom: 1px solid #dcdcdc;
`;
const HelpHeader = styled.div`
  font-size: 24px;
  font-weight: 400;
`;

const ResourceHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
`;
const ResourceDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0 10px 0;
  margin: 0;
`;
const HeadWrap = styled.div`
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid #dcdcdc;
`;

export {
  HelpButton,
  SyndicationPolicyLink,
  HelpHeader,
  BorderBottom,
  ResourceHeader,
  ResourceDescription,
  SectionWrap,
  HeadWrap,
};
