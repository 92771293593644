import React, { ReactNode, useContext } from 'react';
import { Layout as FormationLayout } from '@condenast/formation-ui-components';
import PageContentWrap from '../PageContentWrapper';
import encoreLogo from '../../styles/images/encore-logo.svg';
import Nav from '../HeaderNavBar/Nav';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { FEATURE_TRANSLATIONS, LEGACY_SYNDICATION } from '../../helpers/flags';
import { useIntercomBoot } from '../../hooks/useIntercomBoot';
import { withUser, AuthContext, UserType } from './AuthContext';

type LayoutProp = {
  children?: ReactNode;
  className?: string;
  nav?: React.FC | null;
};

const Layout = ({ children, nav: NavChildren = null, className = '' }: LayoutProp): JSX.Element => {
  const { user } = useContext(AuthContext);
  useIntercomBoot(user as UserType);
  const isDiscoveryPage = ['/', '/globalDrafts'].includes(window.location.pathname);
  let isFeatureEnabled = useFeatureToggle(FEATURE_TRANSLATIONS) && isDiscoveryPage;
  if (useFeatureToggle(LEGACY_SYNDICATION)) {
    // Tried && !condition. Hooks don't work that way apparently
    isFeatureEnabled = false;
  }
  return (
    <FormationLayout layoutType="topnav" className={className}>
      <Nav slotKey="appNav" logo={encoreLogo} homePath="/">
        {isFeatureEnabled && <LanguageSelector />}
        {NavChildren && <NavChildren />}
      </Nav>
      <PageContentWrap slotKey="pageContent">{children}</PageContentWrap>
    </FormationLayout>
  );
};

export default withUser(Layout);
