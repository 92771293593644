import * as React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';

import { sentryTrack, SentryOptions } from '../../helpers/sentryTrack';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  beforeCapture?(): SentryOptions;
  onError?(): void;
  isFallback?: boolean;
};

const Wrapper = styled.main`
  text-align: center;
  padding-top: 4em;
`;

const Link = styled.a`
  padding: 0.625em 0.875em;
  background-color: #3d5afe;
  min-height: 40px;
  vertical-align: middle;
  display: inline-block;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  touch-action: manipulation;
  border-bottom: none;
  text-decoration: none;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  :link {
    color: white;
  }
`;

export const FallbackComponent = (): JSX.Element => (
  <Wrapper>
    <p>Something went wrong!</p>
    <Link href="/">Back to Encore</Link>
  </Wrapper>
);

const ErrorBoundary = ({ children, beforeCapture, onError }: ErrorBoundaryProps): JSX.Element => {
  const handleBeforeCapture = () => {
    const contextOptions = beforeCapture && beforeCapture();
    sentryTrack(contextOptions as SentryOptions);
  };
  const handleOnError = () => onError && onError();

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} beforeCapture={handleBeforeCapture} onError={handleOnError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
