import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import { messages } from '../../helpers/messages';

type LocalisationPropTypes = {
  children: ReactNode;
};
const Localisation = ({ children }: LocalisationPropTypes): JSX.Element => {
  const {
    state: {
      locales: { defaultLocale, language },
    },
  } = useGlobalStateValue();

  return (
    <IntlProvider messages={messages[language]} locale={language} defaultLocale={defaultLocale}>
      {children}
    </IntlProvider>
  );
};

export default Localisation;
