import React from 'react';
import AdminPageWrapper from './AdminPageWrapper';
import RefreshButton from '../../ApproveAuthorList';

export const UpdateMastheadPage = (): JSX.Element => {
  return (
    <AdminPageWrapper title="Update Masthead">
      <RefreshButton />
    </AdminPageWrapper>
  );
};

export default UpdateMastheadPage;
