import { LOCALE_CONSTANTS } from '../constants/reducer-constants';
const { SET_LOCALE, SET_TARGET_LANG } = LOCALE_CONSTANTS;
import { LocalesType, Action } from '../types';
import { initialLocales } from './';

type ReducerType = (state?: LocalesType, action?: Action) => LocalesType;

const localesReducer: ReducerType = (state = initialLocales, action) => {
  switch (action?.type) {
    case SET_LOCALE:
      return {
        ...state,
        language: action?.payload,
      };
    case SET_TARGET_LANG:
      return {
        ...state,
        targetLang: action?.payload,
      };
    default:
      return state;
  }
};

export default localesReducer;
