import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ClockWiseIcon } from './clockwise-circular-arrow.svg';

const Svg = styled(ClockWiseIcon)``;

const ClockWiseRotateIcon = ({
  ...props
}: React.SVGProps<SVGSVGElement> & { children?: React.ReactNode }): JSX.Element => <Svg {...props}></Svg>;

export default ClockWiseRotateIcon;
