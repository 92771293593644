// eslint-disable-next-line
export const messages: any = {
  'de-DE': require('../translations/de-DE.json'),
  'en-US': require('../translations/en-US.json'),
  en: require('../translations/en.json'),
  'es-ES': require('../translations/es-ES.json'),
  'fr-FR': require('../translations/fr-FR.json'),
  'it-IT': require('../translations/it-IT.json'),
  'ja-JP': require('../translations/ja-JP.json'),
  'ru-RU': require('../translations/ru-RU.json'),
  'zh-Hans': require('../translations/zh-HANS.json'),
  'zh-Hant-TW': require('../translations/zh-HANT-TW.json'),
};
