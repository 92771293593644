export const addRemoveItemFromArray = (array: string[] = [], value: string): string[] => {
  const id = array.indexOf(value);
  if (id > -1) {
    array.splice(id, 1);
  } else {
    array.push(value);
  }
  return array;
};
export const covertToCapitalize = (str = ''): string => `${str[0].toUpperCase()}${str.substring(1)}`;

export const convertToSnakeCase = (error: string): string => {
  return error
    ?.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

export const calculateStringSize = (str: string): number => {
  return new TextEncoder().encode(str).length;
};

// Function to calculate string size
export const stringChunk = (largeString: string, limit: number): string[] => {
  if (!largeString) return [];
  // Calculate the size of the large string
  const stringSize = calculateStringSize(largeString);

  // Split the string into chunks of chunkSize bytes
  const chunks = [];
  let start = 0;
  while (start < largeString.length) {
    let end = start + limit;
    let chunk = largeString.slice(start, end);

    // Ensure the last chunk doesn't exceed the string length
    const chunkSize = calculateStringSize(chunk);
    if (chunkSize > stringSize - start) {
      end = largeString.length;
      chunk = largeString.slice(start);
    }

    chunks.push(chunk);
    start = end;
  }

  return chunks;
};
