import React from 'react';
import { useIntl } from 'react-intl';
import Close from '@condenast/formation-styleguide/src/assets/icons/close.svg';
import { CloseModalWrapper } from '../styles';
type closeModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const CloseModal = ({ setShowModal }: closeModalProps): JSX.Element => {
  const intl = useIntl();
  return (
    <CloseModalWrapper>
      <img
        data-testid="closeIcon"
        alt={intl.formatMessage({
          id: 'close.icon.alt',
          defaultMessage: 'Close Modal',
          description: 'Text to display if icon not loaded',
        })}
        src={Close}
        onClick={() => setShowModal(false)}
      />
    </CloseModalWrapper>
  );
};
export default CloseModal;
