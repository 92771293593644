export const setCookie = (name: string, value: string, days: number | undefined): void => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (key: string): string | null => {
  const cookies = document.cookie;
  if (cookies.indexOf(key.trim()) > -1) {
    const cookiesArray = cookies.split(';');
    const matchingCookies = cookiesArray.filter((part) => part.indexOf(key.trim()) > -1);
    return matchingCookies[0].split('=')[1];
  }
  return null;
};
