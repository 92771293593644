import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import LoadingSkeleton from '../../LoadingSkeleton';

const LoaderWrapper = styled.div`
  display: flex;
  margin-bottom: 0.3rem;
`;

const StoryImage = styled.div`
  margin-right: 1rem;
`;

const SlatContents = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  span {
    width: 100%;
    span:nth-child(1) {
      width: 25%;
    }
    span:nth-child(2) {
      margin: 0.8rem 0;
    }
    span:nth-child(3) {
      width: 50%;
    }
  }
`;
type loaderProps = {
  count: number;
};

const SkeletonComponent = () => (
  <LoadingSkeleton>
    <LoaderWrapper data-testid="loader">
      <StoryImage>
        <Skeleton width={72} height={110} />
      </StoryImage>
      <SlatContents>
        <Skeleton count={3} />
      </SlatContents>
    </LoaderWrapper>
  </LoadingSkeleton>
);

const Loader = ({ count = 1 }: loaderProps) => {
  const createSkeleton = () =>
    Array(count)
      .fill(null)
      .map((_, i) => <SkeletonComponent key={i} />);
  return <>{createSkeleton()}</>;
};

export default memo(Loader);
