import React from 'react';
import DiscoveryMarkets from '../../DiscoveryMarkets/DiscoveryMarkets';
import AdminPageWrapper from './AdminPageWrapper';
import { Title } from '@condenast/gemini';

export const ReingestPage = (): JSX.Element => {
  return (
    <AdminPageWrapper title="Reingest Content">
      <Title>Reingest Content</Title>
      <DiscoveryMarkets />
    </AdminPageWrapper>
  );
};

export default ReingestPage;
