import React, { MouseEvent, KeyboardEvent, ReactNode } from 'react';
import { ReactComponent as Close } from '@condenast/formation-styleguide/src/assets/icons/close.svg';

type DestructibleTagTypes = {
  children: ReactNode;
  onClick(e: MouseEvent): void;
  onKeyPress(event: KeyboardEvent): void;
};
const DestructibleTag = ({ children, onClick, onKeyPress }: DestructibleTagTypes): JSX.Element => (
  <span
    className="destructibletag"
    onClick={(e: MouseEvent) => onClick(e)}
    onKeyPress={(e: KeyboardEvent) => onKeyPress(e)}
    role="option"
    aria-selected="true"
    tabIndex={0}
  >
    {children}
    <Close />
  </span>
);

export default DestructibleTag;
