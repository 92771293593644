import React, { ReactNode } from 'react';

import { ReactComponent as SmallLanguageIcon } from './16px/16px-language.svg';
import { ReactComponent as SmallGlobalDraftIcon } from './16px/16px-global-draft.svg';
import { ReactComponent as Loader } from './syncLoader/sync-loader.svg';
import { ReactComponent as ReloadIcon } from './reloadIcon/reload.svg';
import NavigateIcon from './NavigateIcon';
import ChevronDownIcon from './ChevronDownIcon';
import ClockWiseRotateIcon from './ClockWiseRotateIcon';

const LanguageIcon = (props: React.SVGProps<SVGSVGElement> & { children?: ReactNode }): JSX.Element => (
  <SmallLanguageIcon {...props} />
);

const GlobalDraftIcon = (props: React.SVGProps<SVGSVGElement> & { children?: ReactNode }): JSX.Element => (
  <SmallGlobalDraftIcon {...props} />
);

const SyncLoader = (): JSX.Element => <Loader />;

const Reload = (): JSX.Element => <ReloadIcon />;

export { LanguageIcon, SyncLoader, NavigateIcon, ChevronDownIcon, GlobalDraftIcon, ClockWiseRotateIcon, Reload };
