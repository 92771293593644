import React from 'react';
import Select from '../Select/Select';
import { covertToCapitalize } from '../../utils/commonMethods';
import { filtersType, filteredOptionType } from './types';

type DiscoveryMultiSelectFiltersProps = {
  // eslint-disable-next-line
  filters: any;
  onChange(filter: string, { label, value }: { label: string; value: string }): void;
};

const DiscoveryMultiSelectFilters = ({ filters, onChange }: DiscoveryMultiSelectFiltersProps): JSX.Element => {
  const getFilters = (filter: string) => {
    const selectedOptions = filters[`selected${covertToCapitalize(filter)}`];
    const filteredOptions = filters[filter]?.filter(({ code }: filteredOptionType) => !selectedOptions?.includes(code));
    return filteredOptions?.map((item: filteredOptionType) => ({ value: item.code, label: item.name }));
  };

  return (
    <>
      {(filters as filtersType)?.multiSelectFilters?.map((filter: string) => (
        <Select key={filter} options={getFilters(filter)} onChange={(option) => onChange(filter, option)}>
          <Select.Title>{filter}</Select.Title>
        </Select>
      ))}
    </>
  );
};

export default React.memo(DiscoveryMultiSelectFilters);
