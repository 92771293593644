import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Caution } from './caution.svg';

const Svg = styled(Caution)``;

const CautionIcon = ({ ...props }: React.SVGProps<SVGSVGElement> & { children?: React.ReactNode }): JSX.Element => (
  <Svg {...props}></Svg>
);

export default CautionIcon;
