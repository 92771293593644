import localesReducer from './localesReducer';
import draftViewReducer from './draftViewReducer';
import globalDraftReducer from './globalDraftReducer';
import { getCookie } from '../utils/cookies';
import { COOKIE_TARGET_LANG } from '../constants/cookie-constants';
import { GlobalStateInterface, Action } from '../types';

const initialLocales = {
  defaultLocale: 'en',
  language: 'en',
  targetLang: getCookie(COOKIE_TARGET_LANG) || '',
};

const initialState: GlobalStateInterface = {
  locales: initialLocales,
  draftView: draftViewReducer(),
  globalDraft: globalDraftReducer(),
};

type ReducerType = (state: GlobalStateInterface, action: Action) => GlobalStateInterface;

const reducers: ReducerType = ({ locales, draftView, globalDraft }, action) => ({
  locales: localesReducer(locales, action),
  draftView: draftViewReducer(draftView, action),
  globalDraft: globalDraftReducer(globalDraft, action),
});

export { reducers, initialState, initialLocales };
