import React from 'react';
import styled from 'styled-components';
import Select from '../Select/Select';

type ValueType = {
  value: string;
  label: string;
};
interface SelectedGlobalDraftType {
  value: string;
  label: string;
}
type globalDraftTabPropType = {
  title: string;
  onChange: (e: SelectedGlobalDraftType) => void;
  options: Array<ValueType> | undefined;
};

const StyledSelect = styled.div`
  button {
    border: 0;
    line-height: 0;
    min-height: 0;
    padding: 0.512rem 2rem 0.7rem 0;
    :hover {
      background-color: transparent;
    }
    :focus {
      border: 0;
    }
    &[aria-expanded='true'] {
      box-shadow: none;
    }
    &[aria-expanded='true'] svg {
      transform: none;
    }
    svg {
      top: 1px;
    }
  }
  button + div {
    margin-top: 18px;
    ul {
      margin: 0.4rem 0 0.5rem 0;
    }
    ul li {
      padding: 0.4rem 0.5rem;
      width: 100%;
      :hover {
        border-bottom: 0;
        padding: 0.4rem 0.5rem;
      }
    }
  }
`;

const GlobalDraftTab = ({ title, options = [], onChange }: globalDraftTabPropType): JSX.Element => (
  <StyledSelect>
    <Select options={options} onChange={onChange}>
      <Select.Title>{title}</Select.Title>
    </Select>
  </StyledSelect>
);

export default GlobalDraftTab;
