import styled from 'styled-components';

const DiscoveryFiltersCard = styled.div`
  border-radius: 4px;
  background-color: white;
  padding: 1.25rem;
  box-shadow: 0 2px 2px 0 rgb(23 23 23 / 7%), 0 3px 1px -2px rgb(23 23 23 / 10%), 0 1px 5px 0 rgb(23 23 23 / 6%);
  margin-top: 1.875em;
`;

const DiscoveryFiltersAvailable = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin-right: 0.512rem;
  }
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const DiscoverySyndicatableToggle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  position: relative;
  input[type='checkbox'] + label {
    font-weight: 700;
  }
  @media (min-width: 769px) {
    margin-left: auto;
  }
`;

const DiscoveryActiveFilter = styled.div`
  display: inline-block;
  margin-right: 1rem;
  text-transform: capitalize;
`;

const DiscoveryFiltersActive = styled.div``;

export {
  DiscoveryFiltersCard,
  DiscoveryFiltersAvailable,
  DiscoveryFiltersActive,
  DiscoverySyndicatableToggle,
  DiscoveryActiveFilter,
};
