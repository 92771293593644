import React, { useState, useEffect } from 'react';
import {
  ImageTabContentWrap,
  ImageChipWrap,
  Text,
  ImagesWrapper,
  ImageBlock,
  ImageWrap,
  NoteClearImage,
  CopyUrl,
  AuthorWrap,
  ThumbnailBind,
  SpinnerWrapper,
  NonImageContent,
} from './DraftPreview.ImageContentStyle';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import { Toast, ThemeProvider, CSSVariables, IntlProvider, Spinner } from '@condenast/gemini';
import { FormattedMessage } from 'react-intl';
import { CheckCircleIcon, CautionIcon, LinkIcon } from '@condenast/gemini/icons';
import useApi from '../../../../hooks/useApi';
import { setImagesWithRightsStatus, setRightsToastStatus, setImageRightsCount } from '../../../../actions/draftView';
import { ContentItem } from '../../../../types';

const defaultImagesWithRights = {
  totalImageAvailable: 0,
  totalImageUnAvailable: 0,
  imageAvailableAssetDetails: [],
  imageUnAvailableAssetDetails: [],
};

type ImagesWithRightsResponseType = {
  totalImageAvailable: number;
  totalImageUnAvailable: number;
  imageAvailableAssetDetails: Array<ContentItem>;
  imageUnAvailableAssetDetails: Array<ContentItem>;
};

type errorMsg = {
  response: {
    data: string;
  };
};

const ImageTabContent: React.FC = () => {
  const [active, setVal] = useState<string>('clear');
  const [copySuccess, setCopySuccess] = useState(false);
  const ref = React.useRef<null | ReturnType<typeof setTimeout>>(null);
  const {
    dispatch,
    state: { draftView },
  } = useGlobalStateValue();
  const { targetBrand, sourceLang, marketBrands } = draftView;
  const [imagesWithRights, setimagesWithRights] = useState<ImagesWithRightsResponseType>(defaultImagesWithRights);
  const activeTab = (val: string) => {
    setVal(val);
  };
  const targetBrandDetails = marketBrands.data && marketBrands.data.find((brand) => brand.code === targetBrand);
  const copyToClip = async () => {
    await navigator.clipboard.writeText(location.href);
    setCopySuccess(true);
  };
  const { callApi: getAvails } = useApi(`post`, `/api/v1/getImagesWithRights`);
  const { callApi: mutationRequest } = useApi(`post`, `/api/v1/createStoryOnRightsline`);

  const [imagesLoading, setimagesLoading] = useState(false);
  const [ErrorLoadingImages, setErrorLoadingImages] = useState(false);
  const [ErrorLoadingMsg, setErrorLoadingMsg] = useState('Error loading images');
  const id = draftView.contentData.id;
  const getImagesWithRights = async (tryAgain = false) => {
    setimagesLoading(true);
    dispatch && dispatch(setImagesWithRightsStatus('fetching'));
    const config = {
      data: {
        id: id,
        title: draftView.contentData.hed,
        sourceBrand: draftView.contentData.marketBrandCode,
        targetMarket: draftView.targetBrand,
        targetLang: draftView.targetLang || sourceLang,
        type: draftView.contentData.type,
      },
    };
    const res = await getAvails(config);
    const { data, status, error } = res;
    if (data) {
      setimagesLoading(false);
      setErrorLoadingImages(false);
      const { totalImageUnAvailable, totalImageAvailable } = data as ImagesWithRightsResponseType;
      setimagesWithRights(data as ImagesWithRightsResponseType);
      dispatch &&
        dispatch(
          setImageRightsCount({
            availabeImages: totalImageAvailable,
            unAvailableImages: totalImageUnAvailable,
          })
        );

      dispatch &&
        dispatch(
          setRightsToastStatus({
            showToast: true,
            toastType: 'success',
          })
        );

      if (totalImageUnAvailable !== 0 && totalImageAvailable === 0) {
        setVal('check');
      }
      if (totalImageUnAvailable !== 0 || totalImageAvailable !== 0) {
        setErrorLoadingImages(false);
        totalImageUnAvailable !== 0
          ? dispatch && dispatch(setImagesWithRightsStatus('warning'))
          : dispatch && dispatch(setImagesWithRightsStatus('success'));
      } else {
        dispatch && dispatch(setImagesWithRightsStatus('none'));
      }
    } else {
      const {
        response: { data },
      } = (error as unknown) as errorMsg;
      if (!tryAgain) {
        setTimeout(() => {
          getImagesWithRights(true);
        }, 20000);
      }

      if (tryAgain) {
        dispatch &&
          dispatch(
            setRightsToastStatus({
              showToast: true,
              toastType: 'error',
            })
          );

        dispatch && dispatch(setImagesWithRightsStatus('error'));
        setErrorLoadingImages(true);
        if (data?.includes('Story is being created on RL')) {
          setErrorLoadingMsg("Failed to load image rights. Please wait a few moments, and click 'Try Again'.");
        } else {
          setErrorLoadingMsg(data);
        }

        setimagesLoading(false);
      }

      if (!tryAgain && (status === 404 || status === 200)) {
        const config = {
          data: {
            id: id,
            title: draftView.contentData.hed,
            brand: draftView.contentData.marketBrandCode,
            lang: sourceLang,
            dek: draftView.contentData.description,
            type: draftView.contentData.type,
          },
        };
        mutationRequest(config);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(draftView.contentData).length) getImagesWithRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, targetBrand]);

  const mouseLeaveHandler = () => {
    if (copySuccess) {
      ref.current = setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  };

  const imagesLoaded = () => (
    <>
      <div className="pills">
        {imagesWithRights.totalImageAvailable !== 0 && (
          <ImageChipWrap active={active === 'clear'} onClick={() => activeTab('clear')}>
            <CheckCircleIcon size="small" color="#245F34" />
            <Text data-testid="cleared-images">{`Cleared for reuse (${imagesWithRights.totalImageAvailable})`}</Text>
          </ImageChipWrap>
        )}
        {imagesWithRights.totalImageUnAvailable !== 0 && (
          <ImageChipWrap active={active === 'check'} onClick={() => activeTab('check')}>
            <CautionIcon size="small" color="#8A7428" />
            <Text>{`Check reuse rights (${imagesWithRights.totalImageUnAvailable})`}</Text>
          </ImageChipWrap>
        )}
      </div>
      {active && active === 'clear' ? (
        <>
          <NoteClearImage>
            These images have been cleared <span>for {targetBrandDetails?.name}</span>. The images must be used for
            editorial purposes only and in the same context as the original story.
          </NoteClearImage>
          <ImagesWrapper>
            {imagesWithRights.imageAvailableAssetDetails.map((data) => (
              <ImageBlock key={data.vulcanUrl}>
                <ThumbnailBind>
                  <ImageWrap>
                    <img alt="Preview" src={data.vulcanUrl} />
                  </ImageWrap>
                  <AuthorWrap>{data.credit}</AuthorWrap>
                </ThumbnailBind>
              </ImageBlock>
            ))}
          </ImagesWrapper>
        </>
      ) : (
        <>
          <ThemeProvider theme="light">
            <IntlProvider>
              <CSSVariables />
              <Toast
                action={
                  <CopyUrl onMouseLeave={mouseLeaveHandler} onClick={() => copyToClip()}>
                    <LinkIcon size="small" color="#3349C4" />
                    {copySuccess ? 'Copied' : 'Copy URL to clipboard'}
                  </CopyUrl>
                }
                type="warning"
                details="A syndication can still be created with all the images. Before publishing the images with the syndication, please contact the brand that published the original story to check the image reuse rights."
              >
                <FormattedMessage
                  id="Check Image Rights"
                  defaultMessage={`These images have undetermined reuse rights for ${targetBrandDetails?.name}`}
                />
              </Toast>
            </IntlProvider>
          </ThemeProvider>
          <ImagesWrapper>
            {imagesWithRights.imageUnAvailableAssetDetails.map((data) => (
              <ImageBlock key={data.vulcanUrl}>
                <ThumbnailBind>
                  <ImageWrap>
                    <img alt="Preview" src={data.vulcanUrl} />
                  </ImageWrap>
                  <AuthorWrap>{data.credit}</AuthorWrap>
                </ThumbnailBind>
              </ImageBlock>
            ))}
          </ImagesWrapper>
        </>
      )}
    </>
  );
  const noTargetBrand = () => (
    <NonImageContent>Select a destination to view image usage rights for your brand and market</NonImageContent>
  );
  const errorLoadingImages = () => (
    <NonImageContent>
      <div>{ErrorLoadingMsg}</div>
      <a onClick={() => getImagesWithRights(true)}>Try again</a>
    </NonImageContent>
  );
  const noStoryImages = () => (
    <NonImageContent>
      <div>This story has no images</div>
    </NonImageContent>
  );

  return (
    <ImageTabContentWrap>
      {imagesLoading ? (
        <SpinnerWrapper>
          <ThemeProvider theme="light">
            <IntlProvider>
              <CSSVariables />
              <Spinner size="large" />
            </IntlProvider>
          </ThemeProvider>
        </SpinnerWrapper>
      ) : (
        <>
          {!targetBrand && noTargetBrand()}
          {ErrorLoadingImages && errorLoadingImages()}
          {!ErrorLoadingImages &&
            (imagesWithRights.totalImageAvailable !== 0 || imagesWithRights.totalImageUnAvailable !== 0) &&
            imagesLoaded()}
          {!ErrorLoadingImages &&
            imagesWithRights.totalImageAvailable === 0 &&
            imagesWithRights.totalImageUnAvailable === 0 &&
            noStoryImages()}
        </>
      )}
    </ImageTabContentWrap>
  );
};

export default ImageTabContent;
