import React, { ReactNode } from 'react';
import { ModalBodyWrapper } from './styles';
type ModalBodyPropTypes = {
  children?: ReactNode;
  style?: React.CSSProperties;
};
const ModalBody = ({ children, style }: ModalBodyPropTypes): JSX.Element => (
  <>{!!children && <ModalBodyWrapper style={style}>{children}</ModalBodyWrapper>}</>
);

export default ModalBody;
