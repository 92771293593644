const getUpdatedDate = (updatedAt: string): string => {
  let fullDate = '';
  const date = new Date(updatedAt);
  const month = date.toLocaleString('default', { month: 'long' });
  const updatedDate = date.getDate();
  const year = date.getFullYear();
  fullDate = `${month} ${updatedDate}, ${year}`;
  return fullDate;
};

export default getUpdatedDate;
