import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { initialState, reducers } from '../reducers';
import { ContextProps, ContextHook } from '../types';

export const GlobalStateContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => {
    // do nothing
  },
});
export const GlobalStateProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(reducers, initialState);

  const value = { state, dispatch };
  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
};

export const useGlobalStateValue: ContextHook = () => {
  const { state, dispatch: globalDispatch } = useContext(GlobalStateContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = (action: any): void => {
    if (typeof action === 'function') {
      action(dispatch, state);
    } else {
      globalDispatch(action);
    }
  };
  return { state, dispatch };
};
