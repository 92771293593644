import { useEffect } from 'react';
import { resetDraftViewReducer } from '../../../actions/draftView';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';

/* eslint-disable react-hooks/exhaustive-deps */

const useCleanup = (): void => {
  const { dispatch } = useGlobalStateValue();
  useEffect(() => {
    return () => {
      dispatch && dispatch(resetDraftViewReducer());
    };
  }, []);
};

export default useCleanup;
