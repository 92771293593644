import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { setDraftViewToast } from '../../../actions/draftView';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';

/* eslint-disable react-hooks/exhaustive-deps */
type targetBrandType = {
  value: string;
  label: string;
};
const useSetSyndicationToastMessage = (targetBrands: Array<targetBrandType> = [], targetBrand: string): void => {
  const {
    state: {
      draftView: { syndicationStatus },
    },
    dispatch,
  } = useGlobalStateValue();
  const intl = useIntl();
  let targetBrandName: targetBrandType | undefined;
  // eslint-disable-next-line
  targetBrandName = targetBrands.find((brand: targetBrandType) => brand.value === targetBrand);
  useEffect(() => {
    switch (syndicationStatus) {
      case 'success':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'success',
              message: intl.formatMessage(
                {
                  id: 'create.modal.successSyndicationMessage',
                  defaultMessage: 'A draft of this content has been created in Copilot for {brand}',
                  description: 'Success syndication message',
                },
                {
                  brand: targetBrandName?.label,
                }
              ),
            })
          );
        break;
      case 'duplicate':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'duplicate',
              message: intl.formatMessage(
                {
                  id: 'create.modal.duplicateSyndicationMessage',
                  defaultMessage: 'A draft of this content already exists in Copilot for {brand}',
                  description: 'Duplicated syndication message',
                },
                {
                  brand: targetBrandName?.label,
                }
              ),
            })
          );
        break;
      case 'timeout':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'timeout',
              message: intl.formatMessage(
                {
                  id: 'create.modal.timeoutSyndicationMessage',
                  defaultMessage:
                    'Your attempt to create a draft of this content for {brand} is taking longer than expected. It is still in progress.',
                  description: 'Refresh to check status',
                },
                {
                  brand: targetBrandName?.label,
                }
              ),
            })
          );
        break;
      case 'in_progress':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'in_progress',
              message: intl.formatMessage(
                {
                  id: 'create.modal.inprogressSyndicationMessage',
                  defaultMessage: 'A syndication of this content in Copilot for {brand} is already in progress',
                  description: 'Syndciation in progress message',
                },
                {
                  brand: targetBrandName?.label,
                }
              ),
            })
          );
        break;
      case 'missingDestinationContentType':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'missingDestinationContentType',
              message: intl.formatMessage(
                {
                  id: 'create.modal.missingDestinationContentType',
                  defaultMessage:
                    'A draft of this content has not been created because the Destination brand does not have the Hotel content type available in Copilot',
                  description: 'description',
                },
                {
                  brand: targetBrandName?.label,
                }
              ),
            })
          );
        break;
      case 'error':
        dispatch &&
          dispatch(
            setDraftViewToast({
              type: 'danger',
              message: intl.formatMessage({
                id: 'create.modal.errorSyndicationMessage',
                defaultMessage:
                  'Oh no! We are having problems creating your syndication. Please use the Chat Bubble to contact Product Support.',
                description: 'Error syndication message',
              }),
            })
          );
    }
  }, [syndicationStatus]);
};

export default useSetSyndicationToastMessage;
