import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import encoreLogo from '../../styles/images/encore-logo.svg';
import HelpResource from '../HelpResource/HelpResource';

type navPropType = {
  children?: ReactNode;
  homePath?: string;
  logo?: string;
  className?: string;
  slotKey?: string;
};

const Nav = (props: navPropType): JSX.Element => {
  const { children, homePath = '/', logo = encoreLogo } = props;
  return (
    <nav data-cy="header" className="top-nav color-x-light">
      <NavLink to={homePath} className="top-nav--header">
        <img src={logo} alt="logo" className="icon icon-xx-large nav-header--icon" />
        <span className="nav-header--text notranslate">Encore</span>
      </NavLink>
      <div className="top-nav__right-side-section">
        <HelpResource />
        {children}
      </div>
    </nav>
  );
};

export default Nav;
