import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { FormattedDate } from 'react-intl';
import SyndicationToast from '../DraftView/SyndicationToast';
import { Link } from './styles';
import useApi from '../../hooks/useApi';
import { withUser } from '../App/AuthContext';
import { Response, UserDetails, MastheadResponse } from './utils/types';
import Spinner from '../Spinner';
import { Button, Title, Card } from '@condenast/gemini';
import { ReloadIcon } from '@condenast/gemini/icons';

export const RefreshButton = ({ user }: UserDetails): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [useToastMsg, setToastMsg] = useState('');
  const [logsData, setLogsData] = useState<MastheadResponse[]>([]);
  const [showToastBlock, setToastBlock] = useState(false);
  const { callApi } = useApi(`post`, `/api/v1/update-masthead`);
  const { callApi: getMastheadLogs, data: MastheadLogs } = useApi<MastheadResponse>(`get`, `/api/v1/masthead-logs`);
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
  const closeToast = () => setToastBlock(false);
  const dataType = {
    type: 'info',
  };
  const refreshFn = async () => {
    const config = {
      data: { userEmail: user.email },
    };
    const { error }: Response = await callApi(config);
    let toastMessageValue = '';
    if (error) {
      toastMessageValue = error?.response?.data?.message;
    } else {
      toastMessageValue = 'Update masthead is in progress';
    }
    setToastMsg(toastMessageValue);
    setToastBlock(true);
    timer.current = setTimeout(() => setToastBlock(false), 3 * 1000);
    await getMastheadLogs();
  };

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (MastheadLogs) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mappedLogs = MastheadLogs?.data.map((item: MastheadResponse) => ({
        ...item,
        createdAt: item.createdAt,
      }));
      setLogsData(mappedLogs);
    }
  }, [MastheadLogs]);

  const refreshLogs = async () => {
    if (loading) return;
    setToastBlock(false);
    setLoading(true);
    await getMastheadLogs();
    setLoading(false);
  };

  const TitleWrap = styled.div`
    display: inline-flex;
    width: 100%;
    @media (max-width: 600px) {
      display: block;
    }
  `;

  const TitleDiv = styled.div`
    float: left;
    width: 50%;
    @media (max-width: 600px) {
      width: 100%;
    }
  `;

  const TitleStyle = styled(Title)`
    margin-top: 8px;
    margin: unset;
  `;

  const UpdateButton = styled(Button)`
    background: #3d5afe;
    font-size: 16px;
    color: #fff;
    padding: 8px 16px;
    display: inline-block;
    float: right;
    &:hover {
      background: #3246b9;
    }
    &:active {
      background: #3246b9;
    }
    @media (max-width: 600px) {
      float: left;
      width: 100%;
      margin: 24px 0;
    }
  `;

  const BtnDiv = styled.div`
    width: 50%;
    @media (max-width: 600px) {
      width: 100%;
    }
  `;

  const CardBlock = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    margin-top: 20px;
    padding: 8px 8px 8px 24px;
    border-bottom: 1px solid #dcdcdc;
  `;

  const HeadBlock = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    align: center;
  `;

  const CardStyle = {
    CardShadow: '0px 2px 4px 0px rgba(23, 23, 23, 0.25)',
    CornerRadius: '4px 4px 0 0',
  };

  const Table = styled.table`
    box-shadow: 0px 2px 4px 0px rgba(23, 23, 23, 0.25);
  `;
  const TableRow = styled.tr`
    background: #fff;
    color: #171717;
    border-bottom: 1px solid #dcdcdc !important;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  `;

  const TableHeader = styled.th`
    font-size: 14px;
    font-weight: 700;
    padding: 20px 0 20px 24px;
    @media (max-width: 600px) {
      display: none;
    }
  `;

  const TableCell = styled.td`
    text-align: left;
    padding: 20px 20px 20px 24px;
    font-size: 16px;
    color: #171717;
    @media (max-width: 600px) {
      padding: 8px 8px 8px 24px;
      &:nth-child(1) {
        order: 3;
      }
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 2;
      }
    }
  `;

  const TableCellAuthor = styled(TableCell)`
    @media (max-width: 600px) {
      color: #565656;
    }
  `;

  const MastheadWrap = styled.div`
    max-width: 100%;
    @media (max-width: 600px) {
      width: 568px;
    }
    @media (max-width: 375px) {
      width: 342px;
    }
  `;

  const getStatus = (status: string) => {
    const color =
      status === 'success'
        ? 'background: #297C40; color: #fff'
        : status === 'processing'
        ? 'background: #FDD13A; color: #000'
        : 'background: #A01C23; color: #fff';
    return color;
  };

  const StatusBlock = styled.div`
    ${(props) => props.status};
    font-weight: 500;
    font-size: 14px;
    border-radius: 24px;
    padding: 4px 8px;
    min-height: unset;
    display: inline;
  `;

  const getCamelCase = (value: string) => {
    const renderString = value === 'processing' ? 'In Progress' : value.charAt(0).toUpperCase() + value.slice(1);
    return renderString;
  };

  return (
    <MastheadWrap>
      <TitleWrap>
        <TitleDiv>
          <TitleStyle>Update Masthead</TitleStyle>
        </TitleDiv>
        <BtnDiv>
          <UpdateButton treatment="primary" size="regular" type="button" onClick={refreshFn}>
            Update Author List
          </UpdateButton>
        </BtnDiv>
      </TitleWrap>
      <SyndicationToast title={useToastMsg} showToast={showToastBlock} close={closeToast} toastData={dataType} />
      <CardBlock theme={CardStyle}>
        <HeadBlock>Masthead logs</HeadBlock>
        <Link href="#" onClick={refreshLogs}>
          {loading ? <Spinner size="x-small" /> : <ReloadIcon size="regular" />}
        </Link>
      </CardBlock>
      <Table>
        <TableRow>
          <TableHeader>Created At</TableHeader>
          <TableHeader>User Email</TableHeader>
          <TableHeader>Status</TableHeader>
        </TableRow>
        {logsData.map((data) => (
          <TableRow key={data.createdAt}>
            <TableCellAuthor>
              <FormattedDate
                value={data.createdAt}
                day="numeric"
                month="long"
                year="numeric"
                hour="numeric"
                minute="numeric"
              />
            </TableCellAuthor>
            <TableCell>{getCamelCase(data.userEmail)}</TableCell>
            <TableCell>
              <StatusBlock status={getStatus(data.status)}>{getCamelCase(data.status)}</StatusBlock>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </MastheadWrap>
  );
};

export default withUser(RefreshButton);
