import React from 'react';
import Close from '@condenast/formation-styleguide/src/assets/icons/close.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useToggleClassOnMount from '../../hooks/useToggleClassOnMount';

export const Modal = ({ title, children, onDismiss, customClassName }) => {
  const isOpen = useToggleClassOnMount();

  return (
    <div className={classNames('modal', customClassName)} data-cy="modal" data-testid="modal">
      <div className={`modal--open ${isOpen ? 'opened' : ''}`} />
      <div className="modal--wrap" aria-hidden="true">
        <div className="modal--overlay" aria-hidden onClick={() => onDismiss()} />
        <div className="modal--dialog" data-cy="modal-dialog">
          <div className="modal--header">
            <h2>{title}</h2>
            <button type="button" className="button button--close" aria-hidden="true" onClick={() => onDismiss()}>
              <img alt="Close" src={Close} className="icon icon-large" />
            </button>
          </div>
          <div className="modal--body">{children}</div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired,
  customClassName: PropTypes.string,
};

Modal.defaultProps = {
  customClassName: '',
};

export default Modal;
