import { useEffect, useState } from 'react';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { plainTextRenderer } from '../../../helpers/textRenderer';

/* eslint-disable react-hooks/exhaustive-deps */
const useTextToCopy = (): string => {
  const {
    state: {
      draftView: { l10n, targetLang, sourceLang },
    },
  } = useGlobalStateValue();
  const [textToCopy, setTextToCopy] = useState<string>('');
  const { hed, dek: description, body } = l10n[targetLang] || l10n[sourceLang] || {};
  useEffect(() => {
    setTextToCopy(hed + '\n' + description + '\n' + plainTextRenderer(body));
  }, [l10n, targetLang]);
  return textToCopy;
};

export default useTextToCopy;
