import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useApi from '../../../hooks/useApi';
import { contentSlatType } from '../../ContentSlat/util/helper';
import { relsType } from '../types';
import SyndicationItem from './SyndicationItem';
import SyndicationItemsModal from './SyndicationItemsModal';
import { ReactComponent as PlusIcon } from '../../../styles/images/plus-icon.svg';

const SyndicationHistoryWrapper = styled.div`
  width: 100%;
`;

const SyndicationItems = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    margin-top: 8px;
  }
`;

const Label = styled.div`
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 8px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #171717;
`;

const MoreButton = styled.button`
  min-height: inherit;
  display: flex;
  align-items: center;
  min-width: 78px;
  height: 24px;
  padding: 4px 8px 4px 4px;
  border-radius: 4px;
  background: none;
  font-weight: 600;
  color: #3953e7;
  text-decoration: none;
  margin: 8px 0px;
  :hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  :focus {
    box-shadow: none;
    background: #ecefff;
  }
  #plus-icon {
    min-width: 14px;
    margin-right: 5px;
  }
`;
const MoreSyndications = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const NotPublished = styled.div`
  margin: 8px 0px;
`;
const FetchingSyndications = styled.div`
  position: relative;
  right: 90px;
`;

type contentSlatprops = {
  slatData: contentSlatType;
};

const SyndicationHistory = ({ slatData }: contentSlatprops): JSX.Element => {
  const [showPublishHistoryModal, setShowPublishHistoryModal] = useState(false);
  const { id, type } = slatData;
  const relsUrl = `/api/v1/contents/${id}/rels?publishStatus=published`;
  const { data, loading: apiLoader }: { data: relsType | null; loading: boolean } = useApi('get', relsUrl);
  const relsData = data;
  const { rels: unfilteredRels = [], sourceId } = relsData || {};
  // The endpoint returns the source content which has to be filtered
  const relatedContents = unfilteredRels?.filter(({ id }) => id !== sourceId);
  const relatedContentsCount = relatedContents?.length;
  const sortedRelatedContents = relatedContents?.sort(
    (firstContent, secondContent) =>
      Number(new Date(secondContent.publishDate)) - Number(new Date(firstContent.publishDate))
  );
  {
    /* We will show max of 2 published syndication at first and the rest in a modal if there are any*/
  }
  const morePublishedSyndications = sortedRelatedContents?.slice(2);
  const publishedLabel = `Published Syndications (${relatedContentsCount})`;
  const notYetPublishedWithSyndication = `This ${type} has not been syndicated and published.`;
  const fetchingPublishedSyndications = 'Fetching published syndications';

  useEffect(() => {
    if (showPublishHistoryModal) {
      // eslint-disable-next-line
      window.addEventListener('click', (e: any) => {
        if (e?.target?.id !== 'more-button') {
          setShowPublishHistoryModal(false);
        }
      });
      return () => {
        // eslint-disable-next-line
        window.removeEventListener('click', () => {});
      };
    }
  }, [showPublishHistoryModal]);

  return !apiLoader ? (
    <SyndicationHistoryWrapper>
      <Label>
        <FormattedMessage id="modal.published.syndications" description="Syndicated" defaultMessage={publishedLabel} />
      </Label>
      <SyndicationItems>
        {/* Rendering max of 2 latest published syndication by dafault*/}
        {sortedRelatedContents?.slice(0, 2)?.map((relatedContent) => (
          <SyndicationItem key={relatedContent.marketBrandCode} {...relatedContent} />
        ))}
        {/* We will show the rest in a modal on click of more button*/}
        {relatedContentsCount > 2 && (
          <MoreSyndications>
            <MoreButton
              id="more-button"
              data-testid="more-button"
              onClick={() => setShowPublishHistoryModal(!showPublishHistoryModal)}
            >
              <PlusIcon id="plus-icon" data-testid="plus-svg-icon" />
              <FormattedMessage
                id="modal.more.syndications"
                description="more published syndications"
                defaultMessage={`${morePublishedSyndications.length} more`}
              />
            </MoreButton>
            {showPublishHistoryModal && <SyndicationItemsModal publishedSyndicaions={morePublishedSyndications} />}
          </MoreSyndications>
        )}
      </SyndicationItems>
      {!relatedContentsCount && (
        <NotPublished>
          <FormattedMessage
            id="modal.not.syndicated"
            description="Not Published"
            defaultMessage={notYetPublishedWithSyndication}
          />
        </NotPublished>
      )}
    </SyndicationHistoryWrapper>
  ) : (
    <FetchingSyndications>
      <FormattedMessage
        id="modal.fetch.published.syndications"
        description="Fetching the published syndications"
        defaultMessage={fetchingPublishedSyndications}
      />
    </FetchingSyndications>
  );
};

export default SyndicationHistory;
