import React, { useState, useReducer, useEffect, KeyboardEvent } from 'react';
import without from 'lodash/without';
import difference from 'lodash/difference';
import DestructibleTag from './DestructibleTag';
import DiscoveryList from '../DiscoveryList/DiscoveryList';
import ToggleSwitchCheckbox from './ToggleSwitchCheckbox';
import { trackEvent } from '../../helpers/googleAnalytics';
import SearchInput from '../Search/SearchInput';
import configure from '../../config';
import { discoveryMarketsLanguages } from '../../languagePack';
import DiscoveryMultiSelectFilters from './DiscoveryMultiSelectFilters';
import Select from '../Select/Select';
import useApi from '../../hooks/useApi';
import { addRemoveItemFromArray, covertToCapitalize } from '../../utils/commonMethods';
import ErrorBoundary from '../ErrorBoundary';
import { setMarketBrands } from '../../actions/draftView';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import { langFamily } from '../../languagePack';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

import {
  DiscoveryFiltersCard,
  DiscoveryFiltersAvailable,
  DiscoveryFiltersActive,
  DiscoverySyndicatableToggle,
  DiscoveryActiveFilter,
} from './styles';
import { brand, market } from './types';
const { filters: FILTERS, dateRange } = configure;

const initialState = {
  [FILTERS.BRANDS]: [],
  [FILTERS.MARKETS]: [],
  [FILTERS.LANGUAGES]: discoveryMarketsLanguages,
  [FILTERS.SEARCH]: [],
  [FILTERS.DATE_RANGE]: dateRange,
  selectedBrands: [],
  selectedMarkets: [],
  selectedLanguages: [],
  multiSelectFilters: [FILTERS.BRANDS, FILTERS.MARKETS, FILTERS.LANGUAGES],
};

const getParam = (url: string, param: string): Array<string> | [] => {
  const urlParams = new URLSearchParams(url);
  const paramString = urlParams.get(param);
  return paramString?.split(',') || [];
};

const getAllParamObj = (url: string) => {
  // eslint-disable-next-line
  const paramsAsObj: any = {};
  Object.values(FILTERS).forEach((name) => {
    paramsAsObj[name] = getParam(url, name);
  });
  return paramsAsObj;
};

const discoveryMarketsReducer = (
  // eslint-disable-next-line
  state: any,
  // eslint-disable-next-line
  { type, payload }: { type: string; payload: Array<object> | Array<string> }
) => {
  switch (type) {
    case type:
      return { ...state, [type]: payload };
    default:
      return { ...state };
  }
};

const DiscoveryMarkets = (): JSX.Element => {
  const [discoveryMarketsState, dispatch] = useReducer(discoveryMarketsReducer, initialState);
  const { data: brands }: { data: brand | null } = useApi('get', '/api/v1/brands');
  const { data: markets }: { data: market | null } = useApi('get', '/api/v1/markets');
  // eslint-disable-next-line
  const { data: marketBrands }: { data: any | null } = useApi('get', '/api/v1/market-brands');

  const { dispatch: dispatchMarketBrands } = useGlobalStateValue();
  const [urlParams, setUrlParams] = useState<URLSearchParams>();
  const [parsedParams, setParsedParams] = useState(getAllParamObj(window.location.search));

  useEffect(() => {
    dispatch({ type: 'brands', payload: brands ? brands.data : [] });
  }, [brands]);

  useEffect(() => {
    dispatch({ type: 'markets', payload: markets ? markets.data : [] });
  }, [markets]);

  useEffect(() => {
    marketBrands && dispatchMarketBrands && dispatchMarketBrands(setMarketBrands(marketBrands));
    // eslint-disable-next-line
  }, [marketBrands]);

  const getAllParam = (url: string): void => {
    const paramsAsObj = getAllParamObj(url);
    setParsedParams(paramsAsObj);
  };

  const getSelectedFilter = (key: string, value: string) => {
    const { selectedBrands, selectedMarkets, selectedLanguages } = discoveryMarketsState;
    let result: Array<string> = [];
    switch (key) {
      case 'brands':
        result = addRemoveItemFromArray(selectedBrands, value);
        break;
      case 'markets':
        result = addRemoveItemFromArray(selectedMarkets, value);
        break;
      case 'languages':
        result = addRemoveItemFromArray([...selectedLanguages?.flat()], value);
        break;
      default:
        break;
    }
    return result;
  };

  const getLangFamilyValues = (values: Array<string> | string) => {
    let result: Array<string> = [];
    const enIndex = values?.indexOf('en');
    if (enIndex !== -1) result = [...result, ...langFamily['en']];

    const zhIndex = values?.indexOf('zh');
    if (zhIndex !== -1) result = [...result, ...langFamily['zh']];

    const esIndex = values?.indexOf('es');
    if (esIndex !== -1) result = [...result, ...langFamily['es']];

    return [...new Set([...values, ...result])];
  };

  const setValues = (key: string, values: Array<string> | string): void => {
    const newValues: Array<string> | string = key === 'languages' ? getLangFamilyValues(values) : values;
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    if (newValues.length) {
      urlParams.set(key, `${newValues}`);
    } else {
      urlParams.delete(key);
    }
    setUrlParams(urlParams);
    getAllParam(decodeURIComponent(`${urlParams}`));
    const decodedUri = `${decodeURIComponent(`${urlParams}`)}`;
    const pathName = window.location.pathname;
    window.history.replaceState(null, '', `${decodedUri ? `${pathName}?${decodedUri}` : pathName}`);
  };

  // eslint-disable-next-line
  const remove = (key: string, value: string, event: any | string = 'mouseClick'): void => {
    const eventType = event === 'mouseClick' ? event : event.key;
    const eventsToRespondTo = ['mouseClick', ' ', 'Enter'];
    if (eventsToRespondTo.includes(eventType)) {
      const values = getParam(window.location.search, key);
      /** Remove en related values from final values e.g 'en-US', 'en-GB', 'en-IN' */
      const filteredValues =
        // eslint-disable-next-line
        key === 'languages' && !!(langFamily as any)[value]
          ? // eslint-disable-next-line
            difference(values, [...(langFamily as any)[value]])
          : without(values, value);
      dispatch({ type: `selected${covertToCapitalize(key)}`, payload: filteredValues });
      setValues(key, filteredValues);
      trackEvent('Filters', `${key} filter removed`, { label: value });
    }
    trackCustomSnowplowActions(ActionType.EncoreSearchFilterEvent, {
      subject: 'filter',
      label: key === 'display' ? 'syndication_toggle' : key,
      type: 'removed',
      platform: 'encore',
      criteria: {
        search_keywords: null,
        filter: {
          name: key === 'display' ? `remove_syndication_toggle` : `remove_${key}_filter`,
          label: key === 'display' ? 'inactive' : value,
        },
      },
    });
  };

  // eslint-disable-next-line
  const handleSyndicatableToggleChange = (event: any): void => {
    const isCheckedNow = event?.target?.checked;
    if (isCheckedNow) {
      setValues(FILTERS.DISPLAY, 'all');
      trackEvent('Filters', `Syndication toggle clicked`, { label: 'Inactive' });
      trackCustomSnowplowActions(ActionType.EncoreSearchFilterEvent, {
        subject: 'filter',
        label: 'syndication_toggle',
        type: 'applied',
        platform: 'encore',
        criteria: {
          search_keywords: null,
          filter: {
            name: 'apply_syndication_toggle',
            label: 'active',
          },
        },
      });
    } else {
      remove(FILTERS.DISPLAY, 'all');
      trackEvent('Filters', `Syndication toggle clicked`, { label: 'Active' });
    }
  };

  const handleOnSubmit = (searchTerm: string): void => {
    trackEvent('Search', 'Syndication', { label: searchTerm });
    setValues('q', searchTerm);
  };

  const handleOnChange = (key: string, { label, value }: { label: string; value: string }): void => {
    const filters = getSelectedFilter(key, value);
    dispatch({ type: `selected${covertToCapitalize(key)}`, payload: filters });
    trackEvent('Filters', `${key} filter applied`, { label });
    setValues(key, filters);
    trackCustomSnowplowActions(ActionType.EncoreSearchFilterEvent, {
      subject: 'filter',
      label: key,
      type: 'selected',
      platform: 'encore',
      criteria: {
        search_keywords: null,
        filter: {
          name: `apply_${key}_filter`,
          label: value,
        },
      },
    });
  };
  const handleDateRangeSelect = ({ label, value }: { label: string; value: string }): void => {
    trackEvent('Filters', `Publish Dates filter applied`, { label });
    setValues(FILTERS.DATE_RANGE, value);
    trackCustomSnowplowActions(ActionType.EncoreSearchFilterEvent, {
      subject: 'filter',
      label: 'dateRange',
      type: 'selected',
      platform: 'encore',
      criteria: {
        search_keywords: null,
        filter: {
          name: 'apply_dateRange_filter',
          label: value,
        },
      },
    });
  };

  useEffect(() => {
    /* eslint-disable */
    const { multiSelectFilters } = discoveryMarketsState;
    const params = new URLSearchParams(window.location.search);
    setUrlParams(params);
    getAllParam(window.location.search);

    multiSelectFilters.forEach((filter: string) => {
      const result = getParam(window.location.search, filter);
      dispatch({ type: `selected${covertToCapitalize(filter)}`, payload: result });
    });
  }, []);

  /*eslint-enable */

  // eslint-disable-next-line
  const handleBeforeCapture = (): any => {
    const { brands, dateRange, display, languages, markets, q } = parsedParams;
    return {
      tags: {
        'Encore-event': 'Discovery Markets',
      },
      extraData: {
        brands,
        dateRange,
        display,
        languages,
        markets,
        q,
      },
      exceptionMessage: {
        level: 'error',
        message: 'Encore-Error: Discovery Markets',
      },
    };
  };

  const renderTags = (urlParams: URLSearchParams, filter: string, name = filter) => {
    return (
      urlParams &&
      urlParams.has(filter) &&
      discoveryMarketsState[filter].length > 0 && (
        <DiscoveryActiveFilter key={filter}>
          <h5>{name}</h5>

          {getParam(urlParams?.toString(), filter).map((code) => {
            const name = discoveryMarketsState[filter]?.find((item: { code: string }) => item.code === code)?.name;
            if (!name) return null;
            return (
              <DestructibleTag
                key={code}
                onKeyPress={(event: KeyboardEvent) => remove(filter, code, event)}
                onClick={() => remove(filter, code)}
              >
                {name}
              </DestructibleTag>
            );
          })}
        </DiscoveryActiveFilter>
      )
    );
  };

  return (
    <ErrorBoundary beforeCapture={handleBeforeCapture} isFallback={false}>
      <SearchInput onSubmit={handleOnSubmit} searchTerm={parsedParams.q} />
      <DiscoveryFiltersCard>
        <DiscoveryFiltersAvailable>
          <DiscoveryMultiSelectFilters filters={discoveryMarketsState} onChange={handleOnChange} />
          <Select
            value=""
            options={discoveryMarketsState[FILTERS.DATE_RANGE].map((item: { code: string; name: string }) => ({
              value: item.code,
              label: item.name,
            }))}
            onChange={handleDateRangeSelect}
          >
            <Select.Title>{'Publish Dates'}</Select.Title>
          </Select>
          <DiscoverySyndicatableToggle data-cy="contents-syndicatable-toggle">
            <ToggleSwitchCheckbox
              labelText="Show restricted content"
              isChecked={!!getParam(window.location.search, FILTERS.DISPLAY).length}
              handleChange={handleSyndicatableToggleChange}
            />
          </DiscoverySyndicatableToggle>
        </DiscoveryFiltersAvailable>
        <DiscoveryFiltersActive>
          {discoveryMarketsState.multiSelectFilters.map((filter: string) =>
            renderTags(urlParams as URLSearchParams, filter)
          )}
          {renderTags(urlParams as URLSearchParams, FILTERS.DATE_RANGE, 'Publish Dates')}
        </DiscoveryFiltersActive>
      </DiscoveryFiltersCard>
      <DiscoveryList
        {...parsedParams}
        key={`${parsedParams[FILTERS.BRANDS]}-${parsedParams[FILTERS.MARKETS]}-${parsedParams[FILTERS.LANGUAGES]}-${
          parsedParams[FILTERS.DISPLAY]
        }-${parsedParams[FILTERS.SEARCH]}
        -${parsedParams[FILTERS.DATE_RANGE]}`}
      />
    </ErrorBoundary>
  );
};

export default React.memo(DiscoveryMarkets);
