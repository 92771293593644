import styled from 'styled-components';

const ChromeLink = styled.a`
  display: flex;
  justify-content: flex-start;
  background-image: linear-gradient(0deg, transparent 0, #8b8b8b 1px, transparent 0);
  height: 55px;
  justify-content: flex-end;
  border-bottom: unset;
`;

const HelpButton = styled.button`
  background: 0 0;
  padding: 5px 8px;
  background-color: get-color(white, light);
  &:active,
  &:hover {
    background-color: #f3f3f3;
  }
  &:focus {
    background-color: #dcdcdc;
  }
  &:hover::before {
    position: absolute;
    z-index: 2;
    margin-left: 0.2rem;
    border-width: 7px 7px 0;
    border-style: solid;
    border-color: #171717 transparent transparent;
    width: 0;
    height: 0;
    content: '';
    top: calc(100% - 14px);
    bottom: auto !important;
    border-width: 7px !important;
    border-color: transparent transparent #171717;
  }
  &:hover::after {
    padding: 0.25rem 0.75rem;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    background-color: #171717;
    color: #fff;
    position: absolute;
    bottom: calc(100% + 10px);
    z-index: 2;
    border-radius: 4px;
    content: 'Help';
    top: calc(100% - 0px);
    bottom: auto !important;
    margin-left: -2.6rem;
  }

  .help-button-close {
    background-color: get-color(white, light);
  }
`;

export { ChromeLink, HelpButton };
