import { useEffect } from 'react';

/* eslint-disable react-hooks/exhaustive-deps */
type useSetTargetBrandProps = {
  sourceBrand: string;
  setTargetBrand: (targetBrand: string) => void;
};
const useSetTargetBrand = ({ sourceBrand, setTargetBrand }: useSetTargetBrandProps): void => {
  useEffect(() => {
    const savedTargetBrand: string | null = localStorage.getItem('targetBrand');
    const nextTargetBrand: string = savedTargetBrand && savedTargetBrand !== sourceBrand ? savedTargetBrand : '';
    setTargetBrand && setTargetBrand(nextTargetBrand);
  }, [sourceBrand]);
};

export default useSetTargetBrand;
