import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const allowedGAHosts = ['stg-encore.conde.digital', 'encore.conde.digital'];
const isProd = allowedGAHosts.includes(window.location.host);

export function initGA(): void {
  ReactGA.initialize('UA-86122026-32', { testMode: process.env.NODE_ENV === 'test' });
}

export const pageView = (): void => {
  if (isProd) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};
// eslint-disable-next-line
export const withPageView = (Component: any) => {
  // eslint-disable-next-line
  const WithPageView = (props: any) => {
    useEffect(() => {
      pageView();
    }, []);
    return <Component {...props} />;
  };

  return WithPageView;
};

// For more event interactions, see: https://github.com/react-ga/react-ga#reactgaeventargs
// Only category and action are required
export const trackEvent = (category: string, action: string, extraArgs = {}): void => {
  if (isProd) {
    const baseEvents = { category, action };
    ReactGA.event(Object.assign(baseEvents, extraArgs));
  }
};
