import React, { useEffect } from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { withUser } from '../../App/AuthContext';
import DraftPreview from '../DraftPreview';
import DraftForm from '../DraftForm/index';
import Modal from '../../Modal';
import StoryImage from '../StoryImage';
import { ModalWrapper, Scroll, SyndicateModalBody, VerticalSepatatorLine } from '../styles';
import useToggle from '../../../hooks/useToggle';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { SentryOptions } from '../../../helpers/sentryTrack';
import { Severity } from '@sentry/browser';
import NotFound from '../../NotFound';
import SyndicationHistory from '../SyndicationHistory';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { SYNDICATION_COUNT } from '../../../helpers/flags';
import CloseModal from '../CloseModal';
import { trackCustomSnowplowActions, ActionType } from '../../../analytics/snowplow';
import { DraftviewSlatType, User } from '../types';
import { ContentContainer } from '../DraftViewWithRights/styles';
import styled from 'styled-components';

const SyndicationModalActions = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.5rem;
  /* Medium devices */
  @media (max-width: 992px) {
    width: 41%;
  }
`;

type draftviewProptypes = {
  slatData: DraftviewSlatType | Record<string, unknown>;
  marketBrand: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  toggle: typeof Function;
  notFound: boolean;
};

const DraftViewV1 = ({
  setShowModal,
  slatData = {},
  marketBrand,
  user,
  notFound,
}: draftviewProptypes): JSX.Element | null => {
  const [open, toggle] = useToggle(true);
  const {
    state: { draftView },
  } = useGlobalStateValue();
  const { id, type, sourceUri, language: sourceLang, marketBrandCode: sourceBrand } = slatData as DraftviewSlatType;
  const { targetBrand, targetLang } = draftView;
  const syndicationCountEnabled = useFeatureToggle(SYNDICATION_COUNT);
  const isGlobalDraftsPage = window.location.pathname.startsWith('/globalDrafts');

  useEffect(() => {
    if (id && window.location.pathname !== '/') {
      trackCustomSnowplowActions(ActionType.EncoreContent, {
        target_brand: targetBrand,
        source_brand: sourceBrand,
        source_id: id,
        target_id: null,
        content_type: type,
        source_lang: sourceLang,
        target_lang: targetLang,
      });
    }
  }, [targetBrand, sourceBrand, id, type, sourceLang, targetLang]);

  /**
   * A helper function to get targetLang, language
   * and translate status within syndicate modal
   */
  const getTargetTranslateLangauage = () => {
    const newTargetLang = targetLang ? targetLang : sourceLang;
    const effectiveLanguage = newTargetLang?.toLowerCase();
    const slatLanguage = sourceLang?.toLowerCase();
    const translate = slatLanguage !== effectiveLanguage;
    return {
      targetLang: newTargetLang,
      effectiveLanguage,
      translate,
    };
  };
  /**
   * A sentry custom events function to log
   * more info realted to article, user
   */
  const handleBeforeCapture = (): SentryOptions => {
    const { targetLang, translate } = getTargetTranslateLangauage();
    return {
      tags: {
        'Encore-event': 'Modal-syndicate',
      },
      extraData: {
        id,
        type,
        sourceUri,
        targetLang,
        translate,
        sourceBrand,
        targetBrand: targetBrand || '',
      },
      exceptionMessage: {
        level: {
          level: 'fatal' as Severity,
        },
        message: 'Encore-Error: Modal Syndicate',
      },
    };
  };

  const onModalClose = () => {
    trackCustomSnowplowActions(ActionType.EncoreContent, {
      target_brand: null,
      source_brand: null,
      source_id: null,
      target_id: null,
      content_type: null,
      source_lang: null,
      target_lang: null,
    });
    setShowModal(false);
  };

  if (notFound) {
    return (
      <Modal isOpen={open as boolean} toggle={toggle} onClose={() => onModalClose()} size="lg">
        <Modal.Body>
          <ModalWrapper>
            <SyndicateModalBody>
              <Scroll>
                <NotFound />
              </Scroll>
            </SyndicateModalBody>
            <VerticalSepatatorLine />
            <SyndicationModalActions>
              <CloseModal setShowModal={onModalClose} />
              <StoryImage />
            </SyndicationModalActions>
          </ModalWrapper>
        </Modal.Body>
      </Modal>
    );
  }

  if (!Object.keys(slatData).length) {
    return null;
  }

  return (
    <ErrorBoundary beforeCapture={handleBeforeCapture} isFallback={false}>
      <Modal isOpen={open as boolean} toggle={toggle} onClose={() => onModalClose()} size="lg">
        <Modal.Body>
          <ModalWrapper>
            <ContentContainer>
              <DraftPreview slatData={slatData as DraftviewSlatType} marketBrand={marketBrand} />
              <VerticalSepatatorLine />
              <SyndicationModalActions>
                <CloseModal setShowModal={onModalClose} />
                {!syndicationCountEnabled && <StoryImage slatData={slatData as DraftviewSlatType} />}
                {syndicationCountEnabled && !isGlobalDraftsPage && (
                  <SyndicationHistory slatData={slatData as DraftviewSlatType} />
                )}
                <DraftForm slatData={slatData as DraftviewSlatType} user={user} />
              </SyndicationModalActions>
            </ContentContainer>
          </ModalWrapper>
        </Modal.Body>
      </Modal>
    </ErrorBoundary>
  );
};

export default withUser(DraftViewV1);
