type modalSizeType = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
};
export const modalSize: modalSizeType = {
  sm: '30%',
  md: '50%',
  lg: '70%',
  xl: '90%',
};
