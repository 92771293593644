import React, { useMemo } from 'react';
import Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import SlatType from './SlatType';
import mdToReactUnwrapped from '../../helpers/mdToReactUnwrapped';
import { trackEvent } from '../../helpers/googleAnalytics';
import { contentSlatType, publicationDate } from './util/helper';
import ReIngest from './ReIngest';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

type ContentSlatProp = RouteComponentProps & {
  slatData: contentSlatType;
  title?: JSX.Element;
};

const Slat = ({ slatData, title, history }: ContentSlatProp): JSX.Element => {
  const timeSincePublication = publicationDate(slatData.isGlobalDraft ? slatData.updatedAt : slatData.publishDate);

  const { translatedHed, hed, id, isGlobalDraft } = slatData;
  const memorizedHed = useMemo(() => mdToReactUnwrapped(translatedHed || hed), [translatedHed, hed]);

  const trackSlatData = () => {
    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'encore_slat',
      label: 'syndication_story_view',
      type: 'click',
    });
  };

  const slatClickHandler = () => {
    history.push(`${isGlobalDraft ? '/globalDrafts' : ''}/story-view/${id}`, { from: window.location.pathname });
    trackSlatData();
  };

  return (
    <>
      <li className="slat" data-testid="slat" data-cy="content-slat" onClick={slatClickHandler}>
        <div className="slat--thumbnail thumbnail">
          <img
            alt="Preview"
            className="thumbnailImage"
            data-testid="thumbnail"
            src={slatData.promoImageUri ? slatData.promoImageUri : '/image-placeholder.png'}
          />
        </div>
        <div className="slat--content slatContent">
          {title}
          <div className={classNames('slat--title', { disabled: !slatData.canSyndicate })}>
            <Dotdotdot clamp={1} useNativeClamp={true}>
              {memorizedHed}
            </Dotdotdot>
          </div>
          <div>
            <SlatType type={slatData.type} />
            <span className="slat--details slatDetails">{timeSincePublication}</span>
            {slatData.authors.map((item) => (
              /*eslint-disable */
              <span
                className="smallMarginLeft"
                key={item}
                onClick={() => trackEvent('Syndicate slat', 'Clicked author name')}
              >
                {item}
              </span>
              /*eslint-enable */
            ))}
            {window.location.pathname.replace(/\/$/, '') === '/admin/reingest-content' && (
              <ReIngest slatData={slatData} />
            )}
          </div>
        </div>
        <div className="slat--actions">
          {/*eslint-disable */}
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></span>
          {/*eslint-enable */}
        </div>
      </li>
    </>
  );
};

export default withRouter(Slat);
