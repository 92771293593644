import styled from 'styled-components';

const RefreshBtn = styled.button`
  font-size: 15px;
  font-family: Arial;
  height: 50px;
  border-width: 1px;
  color: #fff;
  border-color: #84bbf3;
  font-weight: bold;
  border-radius: 6px;
  box-shadow: inset 0px 1px 0px 0px #dcecfb;
  text-shadow: inset 0px 1px 0px #528ecc;
  background: linear-gradient(#fc8d83, #e4685d);
  :hover {
    background: linear-gradient(#e4685d, #fc8d83);
  }
`;

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.75rem;
  h3 {
    margin: 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  border: 0;

  :visited {
    text-decoration: none;
  }

  :hover {
    text-decoration: underline;
  }

  :active {
    text-decoration: underline;
  }
`;

const Status = styled.span`
  text-transform: capitalize;
  ${({ type }) =>
    type === 'failed' &&
    `
    color: red;
  `}
  ${({ type }) =>
    type === 'success' &&
    `
    color: green;
  `}
  ${({ type }) =>
    type === 'processing' &&
    `
    color: blue;
    font-weight: bold;
  `}
`;

export { RefreshBtn, TableTitle, Link, Status };
