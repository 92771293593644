import { DRAFT_VIEW_CONSTANTS } from '../constants/reducer-constants';
import { DraftViewReducertype, Action } from '../types';

const {
  SET_DRAFTVIEW_TARGET_BRAND,
  SET_DRAFTVIEW_PENDING,
  SET_DRAFTVIEW_ERROR,
  SET_DRAFTVIEW_DUPLICATE,
  SET_DRAFTVIEW_SUCCESS,
  SET_DRAFTVIEW_INPROGRESS,
  SET_DRAFTVIEW_TIMEOUT,
  SET_DRAFTVIEW_TOAST,
  SET_DRAFTVIEW_ERROR_MESSAGE,
  SET_DRAFT_FORM_TARGET_LANGUAGE,
  SET_DRAFTVIEW_TRANSLATION_RESPONSE,
  RESET_DRAFTVIEW_REDUCER,
  SET_DRAFT_FORM_SOURCE_LANGUAGE,
  SET_DRAFTVIEW_TRANSLATION_STATUS,
  TOGGLE_DRAFTVIEW_TOAST,
  CLEAR_DRAFTVIEW_TOAST,
  SET_MARKET_BRANDS,
  SET_CONTENT_DATA,
  SET_IMAGES_WITHRIGHTS_STATUS,
  SET_RIGHTS_TOAST_STATUS,
  SET_TOTAL_IMAGE_RIGHTS_COUNT,
  MISSING_DESTINATION_CONTENT_TYPE,
} = DRAFT_VIEW_CONSTANTS;

export const initialState: DraftViewReducertype = {
  syndicationStatus: 'ready',
  contentData: {},
  l10n: {},
  sourceLang: '',
  targetLang: '',
  toast: { type: '', message: '' },
  errorMessage: { isVisible: false, title: '', message: '', code: null },
  translationStatus: 'ready',
  marketBrands: {},
  targetBrand: '',
  imagesWithRightsStatus: '',
  imagesRightsToastStatus: {
    showToast: false,
    toastType: 'warning',
  },
  totalImagesWithRightsCount: {
    availabeImages: 0,
    unAvailableImages: 0,
  },
};

type ReducerType = (state?: DraftViewReducertype, action?: Action) => DraftViewReducertype;

const draftViewReducer: ReducerType = (state = initialState, action) => {
  switch (action?.type) {
    case SET_DRAFTVIEW_TARGET_BRAND:
      return { ...state, syndicationStatus: 'ready', targetBrand: action?.payload };
    case SET_DRAFTVIEW_PENDING:
      return { ...state, syndicationStatus: 'pending' };
    case SET_DRAFTVIEW_ERROR:
      return { ...state, syndicationStatus: 'error', mesage: action?.payload };
    case SET_DRAFTVIEW_TIMEOUT:
      return { ...state, syndicationStatus: 'timeout', mesage: 'refresh to check status' };
    case SET_DRAFTVIEW_INPROGRESS:
      return { ...state, syndicationStatus: 'in_progress', mesage: 'Syndication in progress' };
    case SET_DRAFTVIEW_DUPLICATE:
      return { ...state, syndicationStatus: 'duplicate', targetCopilotUri: action?.payload };
    case MISSING_DESTINATION_CONTENT_TYPE:
      return { ...state, syndicationStatus: 'missingDestinationContentType' };
    case SET_DRAFTVIEW_SUCCESS:
      return { ...state, syndicationStatus: 'success', targetCopilotUri: action?.payload };
    case SET_DRAFTVIEW_TOAST:
      return { ...state, toast: action?.payload };
    case CLEAR_DRAFTVIEW_TOAST:
      return { ...state, toast: initialState?.toast };
    case SET_DRAFTVIEW_ERROR_MESSAGE:
      return { ...state, errorMessage: action?.payload };
    case SET_DRAFT_FORM_TARGET_LANGUAGE:
      return { ...state, targetLang: action?.payload };
    case SET_DRAFT_FORM_SOURCE_LANGUAGE:
      return { ...state, sourceLang: action?.payload };
    case SET_DRAFTVIEW_TRANSLATION_RESPONSE:
      return { ...state, l10n: { ...state.l10n, ...action?.payload } };
    case RESET_DRAFTVIEW_REDUCER:
      return { ...initialState, marketBrands: state.marketBrands };
    case SET_DRAFTVIEW_TRANSLATION_STATUS:
      return { ...state, translationStatus: action?.payload };
    case TOGGLE_DRAFTVIEW_TOAST:
      return { ...state, showToast: action?.payload };
    case SET_MARKET_BRANDS:
      return { ...state, marketBrands: action?.payload };
    case SET_CONTENT_DATA:
      return { ...state, contentData: action?.payload };
    case SET_IMAGES_WITHRIGHTS_STATUS:
      return { ...state, imagesWithRightsStatus: action?.payload };
    case SET_RIGHTS_TOAST_STATUS:
      return { ...state, imagesRightsToastStatus: action?.payload };
    case SET_TOTAL_IMAGE_RIGHTS_COUNT:
      return { ...state, totalImagesWithRightsCount: action?.payload };
    default:
      return state;
  }
};

export default draftViewReducer;
