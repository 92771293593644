import React, { useEffect, useState } from 'react';
import DraftView from '../DraftView';
import useApi from '../../hooks/useApi';
import { withUser } from '../App/AuthContext';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ContentsResponseType } from '../../types';
import { setContentData } from '../../actions/draftView';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';

type StoryViewPropTypes = RouteComponentProps & {
  history: { location: { state?: { from: string } } };
  match: {
    params: {
      id: string;
    };
  };
};
const StoryView = ({ match, history }: StoryViewPropTypes) => {
  const { dispatch } = useGlobalStateValue();
  const contentId = match.params.id;
  const isGlobalDraft = history.location.pathname.startsWith('/globalDrafts');
  const validContent = contentId?.length === 24;
  const [showModal, setShowModal] = useState(true);
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('q', contentId);
  urlSearchParams.set('display', 'all');
  urlSearchParams.set('excludeSyndicated', 'false');
  if (isGlobalDraft) {
    urlSearchParams.set('isGlobalDraft', 'true');
  }
  const { data } = useApi<ContentsResponseType>('get', `/api/v1/contents/markets?${urlSearchParams}`);
  const slatData = data?.data?.[0];

  const notFound = data?.data?.length === 0 || !validContent;

  useEffect(() => {
    dispatch?.(setContentData(slatData || {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slatData]);

  useEffect(() => {
    if (showModal === false) {
      if (notFound) {
        // if content is not found, push to home page
        history.push('/');
      } else if (history.location.state?.from) {
        // history.location.state.from is added while navigating from a slat
        // On exiting the modal, just go back to the previous page
        history.goBack();
      } else if (isGlobalDraft) {
        // If there is no history.location.state.from, i.e., when user lands directly on the story view page
        // and the content is a shared/global draft
        const pushTo = '/globalDrafts';
        if (slatData?.marketBrandCode) {
          pushTo.concat(`?brands=${slatData.marketBrandCode}`);
        }
        history.push(pushTo);
      } else {
        // If there is no history.location.state.from, i.e., when user lands directly on the story view page
        // and the content is not a shared draft
        history.push('/');
      }
    }
  }, [showModal, history, isGlobalDraft, slatData?.marketBrandCode, notFound]);

  return (
    <DraftView
      slatData={slatData}
      marketBrand={slatData?.marketBrand.name || ''}
      setShowModal={setShowModal}
      notFound={notFound}
    />
  );
};

export default withRouter(withUser(StoryView));
