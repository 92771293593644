import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from './chevron-down.svg';

const Svg = styled(ChevronDown)``;

const ChevronDownIcon = ({ ...props }: React.SVGProps<SVGSVGElement> & { children?: React.ReactNode }): JSX.Element => (
  <Svg {...props}></Svg>
);

export default ChevronDownIcon;
