import React, { Fragment, ReactNode } from 'react';

type pageContentWrapperType = {
  children: ReactNode;
  slotKey?: string;
};

function PageContentWrap(props: pageContentWrapperType): JSX.Element {
  return <Fragment>{props.children}</Fragment>;
}
export default PageContentWrap;
