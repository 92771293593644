import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { setDraftViewToast, toggleDraftViewToast } from '../../../actions/draftView';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';

/* eslint-disable react-hooks/exhaustive-deps */
const useSetTranslationToastMessage = (): void => {
  const {
    state: {
      draftView: { translationStatus },
    },
    dispatch,
  } = useGlobalStateValue();
  const intl = useIntl();
  useEffect(() => {
    if (translationStatus === 'error') {
      dispatch &&
        dispatch(
          setDraftViewToast({
            type: 'danger',
            message: intl.formatMessage({
              id: 'create.modal.errorTranslationMessage',
              defaultMessage: 'Oops, encountered an error while translating',
              description: 'Error syndication message',
            }),
          })
        );
      toggleDraftViewToast(true);
    }
  }, [translationStatus]);
};

export default useSetTranslationToastMessage;
