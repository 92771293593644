import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SyndicationModal from '../SyndicationModal/SyndicationModal';
import { trackEvent } from '../../helpers/googleAnalytics';

const SyndicateButton = ({ content, trackingCategory, disabled = false, type }) => {
  const [modalState, setModalState] = useState(false);

  return (
    <>
      {modalState && (
        <SyndicationModal
          content={content}
          onDismiss={(targetCopilotUrl) => {
            setModalState(false);

            if (targetCopilotUrl) {
              window.open(targetCopilotUrl, '_blank');
            }
          }}
        />
      )}
      <button
        id="create-draft"
        type="button"
        className={`button color-white button--${type ? type : 'secondary'}`}
        disabled={disabled}
        onClick={() => {
          setModalState(!modalState);
          trackEvent(trackingCategory, 'Clicked Syndicate button', { label: 'Button' });
        }}
        data-cy="btn-syndicate"
        data-testid="btn-syndicate"
      >
        {content ? 'Create Draft' : 'Loading...'}
      </button>
    </>
  );
};

SyndicateButton.propTypes = {
  content: PropTypes.object,
  trackingCategory: PropTypes.string.isRequired,
  cta: PropTypes.string,
  disable: PropTypes.bool,
  type: PropTypes.oneOf(['info', 'secondary']),
};

export default SyndicateButton;
