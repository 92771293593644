import React, { useCallback, useEffect, useState } from 'react';
import { TabList } from '../../../TabList';
import StoryText from './DraftPreviewTablist.StoryText';
import Images from './DraftPreviewTablist.Images';
import PublishedSyndications from './DraftPreviewTablist.PublishedSyndications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import { relType } from '../../types';

const DraftPreviewTablist = ({ history, location }: RouteComponentProps): JSX.Element | null => {
  const [relatedContentsCount, setRelatedContentCount] = useState(0);

  const updateRelatedContents = (data: Array<relType>) => {
    setRelatedContentCount(data?.length || 0);
  };

  const {
    state: {
      draftView: { totalImagesWithRightsCount },
    },
  } = useGlobalStateValue();
  const totalImagesWithRights =
    totalImagesWithRightsCount.availabeImages + totalImagesWithRightsCount.unAvailableImages;
  const imageTabLabel = totalImagesWithRights > 0 ? `Images (${totalImagesWithRights})` : 'Images';
  const PublishedLabel =
    relatedContentsCount > 0 ? `Published Syndications (${relatedContentsCount})` : 'Published Syndications';

  const tabs = [
    { id: 'text', Label: 'Story Text', PanelContent: <StoryText /> },
    { id: 'images', Label: imageTabLabel, PanelContent: <Images /> },
    {
      id: 'published-syndications',
      Label: PublishedLabel,
      PanelContent: <PublishedSyndications onDataUpdate={updateRelatedContents} />,
    },
  ];
  const [activeTab, setActiveTab] = useState(new URLSearchParams(location.search).get('section') || tabs[0].id);
  const updateSearchParams = useCallback(
    (id: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('section', id);
      history.replace({ pathname: location.pathname, search: searchParams.toString() });
    },
    [history, location.search, location.pathname]
  );
  const onTabClick = (id: string) => {
    setActiveTab(id);
    updateSearchParams(id);
  };
  const hydratedTabs = tabs.map((tab) => ({ ...tab, onClick: () => onTabClick(tab.id) }));
  useEffect(() => {
    updateSearchParams(activeTab);
  }, [activeTab, updateSearchParams]);
  return (
    <TabList
      tabListStyle={{ paddingInlineStart: 'unset', position: 'sticky', top: '-1rem', background: '#ffffff' }}
      tabs={hydratedTabs}
      activeTab={activeTab}
    />
  );
};

export default withRouter(DraftPreviewTablist);
