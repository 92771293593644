import React, { useState } from 'react';
import { Toast } from '@condenast/formation-ui-components';

const SyndicationToast: React.FC = () => {
  const [visible, setVisible] = useState(true);
  return (
    <Toast
      customClassName="preview-toast"
      message="Have you checked image rights? Images must be checked before publishing on Copilot. All Getty images can be reused in context."
      onClose={() => setVisible(!visible)}
      show={visible}
      type="warning"
    />
  );
};

export default SyndicationToast;
