import React, { ReactNode } from 'react';
import { ConfigureFlopFlip } from '@flopflip/react-broadcast';
import adapter from '@flopflip/memory-adapter';
import { overrideDefaultFlagsWithSearchParams } from '../../helpers/flags';

type FeatureFlagsProp = {
  children?: ReactNode;
};

const FeatureFlags = ({ children }: FeatureFlagsProp): JSX.Element => (
  // adapterArgs with user property on it is require, but we never use it because our Feature flags based on url not user
  <ConfigureFlopFlip adapter={adapter} defaultFlags={overrideDefaultFlagsWithSearchParams()} adapterArgs={{ user: {} }}>
    {children}
  </ConfigureFlopFlip>
);

export default FeatureFlags;
