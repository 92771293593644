import React from 'react';
import { RedirectToPreviewLink } from '../../Preview';
import { ContentTypeIcon } from '@condenast/gemini/icons';
import styled from 'styled-components';
import CloseModal from '../CloseModal';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { ContentItem } from '../../../types';
import { trackEvent } from '../../../helpers/googleAnalytics';

type headerProps = {
  onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  border: 0px 0px 1px 0px;
  border-bottom-style: solid;
  border-color: #dcdcdc;
`;

const Separator = styled.span`
  width: 0.0625rem;
  background: #dcdcdc;
  height: 1.5rem;
`;

const IconLinkWrapper = styled.div`
  display: flex;
  padding: 0.25rem 0rem;
  align-items: center;
  gap: 0.5rem;
`;

const ContentTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const ContentTypeText = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  text-transform: capitalize;
`;
const navigationClickHandler = (contentData: ContentItem | Record<string, never>) => {
  window.open(contentData?.publishUri, '_blank', 'noopener,noreferrer');
  trackEvent('Syndicate slat', 'Clicked entity');
};

const Header = ({ onModalClose }: headerProps): JSX.Element => {
  const {
    state: {
      draftView: { contentData },
    },
  } = useGlobalStateValue();

  return (
    <HeaderWrapper>
      <IconLinkWrapper>
        <RedirectToPreviewLink
          name={contentData?.marketBrand?.name}
          onClick={() => navigationClickHandler(contentData)}
          isNameDisabled={false}
        />
        <Separator />
        <ContentTypeWrapper>
          <ContentTypeIcon contentType={contentData?.type || ''} size="small" />
          <ContentTypeText>{contentData?.type}</ContentTypeText>
        </ContentTypeWrapper>
      </IconLinkWrapper>
      <CloseModal setShowModal={onModalClose} />
    </HeaderWrapper>
  );
};

export default Header;
