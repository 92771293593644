import React from 'react';
import { Toast } from '@condenast/formation-ui-components';

type syndicationToastProps = {
  close(): void;
  toastData: {
    type: string;
  };
  showToast: boolean;
  targetCopilotUri?: string;
  title: string;
  children?: string | JSX.Element;
  action?: () => void;
};

const SyndicationToast = ({
  close,
  toastData,
  showToast,
  title,
  children,
  targetCopilotUri,
  action,
}: syndicationToastProps): JSX.Element => {
  let toastType = toastData.type;

  if (toastData.type === 'duplicate') {
    toastType = 'warning';
  } else if (
    toastData.type === 'timeout' ||
    toastData.type === 'in_progress' ||
    toastData.type === 'missingDestinationContentType'
  ) {
    toastType = 'info';
  }

  return (
    <Toast
      title={title}
      onClose={close}
      show={showToast}
      type={toastType}
      onButtonClick={action}
      buttonText={toastData.type === 'timeout' ? 'Refresh to check status' : undefined}
    >
      {toastData.type !== 'danger' &&
        toastData.type !== 'timeout' &&
        toastData.type !== 'missingDestinationContentType' &&
        toastData.type !== 'in_progress' && (
          <a data-testid="toastLink" href={targetCopilotUri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )}
    </Toast>
  );
};

export default SyndicationToast;
