import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.main`
  text-align: center;
  padding-top: 4em;
`;

const Hed = styled.h1`
  font-size: 6em;
  font-weight: bold;
`;

const Message = styled.p`
  margin-bottom: 5em;
`;
// No found component
const NotFound = (): JSX.Element => {
  return (
    <Wrapper>
      <Hed>Oh no!</Hed>
      <Message>
        The page you&apos;re looking for has moved or does not exist. You can go back and look for similar content.
      </Message>
      <Link to="/" className="button button--primary link-white">
        Back to Encore
      </Link>
    </Wrapper>
  );
};

export default NotFound;
