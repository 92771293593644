import styled from 'styled-components';

const DiscoveryWrapper = styled.div`
  max-width: 880px;
  margin: 1.953rem auto;
`;

const NavigationWrapper = styled.div`
  display: flex;
  margin-bottom: 1.953rem;
  ul {
    li {
      display: flex;
      align-items: flex-end;
    }
  }
`;

export { DiscoveryWrapper, NavigationWrapper };
