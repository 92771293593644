import React, { useRef, FormEvent } from 'react';
import { ReactComponent as SearchIcon } from '@condenast/formation-styleguide/src/assets/icons/search.svg';
import { trackEvent } from '../../helpers/googleAnalytics';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

type SearchInputType = {
  onSubmit: (value: string) => void;
  searchTerm?: string;
};
const SearchInput = ({ onSubmit, searchTerm }: SearchInputType): JSX.Element => {
  const searchRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const validBaseURL = (str: string) => {
    const pattern = new RegExp(
      '^((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(str);
  };
  const isValidHttpUrl = (string: string) => {
    let url;
    try {
      url = new URL(string);
    } catch (error) {
      return false;
    }
    const isValidProtocol = url.protocol === 'http:' || url.protocol === 'https:';
    return isValidProtocol && validBaseURL(url.host);
  };
  if (searchTerm && typeof searchTerm[0] === 'string' && isValidHttpUrl(searchTerm[0])) {
    trackEvent('Search - Publish URL', 'clicked', { publishUrl: searchTerm[0] });
    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'search_input',
      label: 'search_input',
      type: 'change',
    });
  }

  let label = 'search_button';
  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.type === 'keydown') label = '';
  };

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit && searchRef.current && onSubmit(searchRef.current.value);
    trackCustomSnowplowActions(ActionType.EncoreSearchFilterEvent, {
      subject: 'search',
      label: label ? label : null,
      type: 'search',
      platform: 'encore',
      criteria: { search_keywords: searchRef.current.value ? searchRef.current.value : null, filter: null },
    });
  };

  return (
    <form className="input-button-wrapper color-white search" onSubmit={submitForm}>
      <div className="search-input--wrapper icon-input">
        <SearchIcon data-testid="search-icon" className="SVGInline icon" />
        <input
          data-testid="search-input"
          type="search"
          aria-label="Search by headline, URL, author, or Copilot ID"
          placeholder="Search by headline, URL, author, or Copilot ID"
          ref={searchRef}
          defaultValue={searchTerm}
          onKeyDown={(e) => handleEnterPress(e)}
        />
      </div>
      <button className="button color-white color-info-text">Search</button>
    </form>
  );
};

export default SearchInput;
