import styled from 'styled-components';
import React from 'react';
import { NavigateIcon } from '../Icons';

type RedirectToPreviewLinkType = {
  syndicationHistory?: boolean;
  name: string;
  isNameDisabled: boolean;
  helpSection?: boolean;
  onClick: (event: React.MouseEvent) => void;
};

const Name = styled.a`
  color: ${(props) => props.color || '#3953e7'} !important;
  padding-right: 0.25rem;
  font-weight: ${(props) => props.fontWeight || '600'};
  text-transform: ${(props) => props.textTransformation || 'uppercase'};
  font-size: 14px;
  border-bottom: none;
  &: hover {
    text-decoration: underline;
  }
`;

const PreviewNavIcon = styled(NavigateIcon)`
  width: 14px;
  height: 14px;
  path {
    fill: ${(props) => props.color || '#3953e7'};
  }
`;

const PreviewLink = styled.div`
  display: inline-flex;
  color: #3953e7;
  align-items: center;
  justify-content: ${(props) => (props.syndicationHistory ? 'space-between' : '')};
  &: hover {
    cursor: pointer;
  }
`;

const RedirectToPreviewLink = ({
  name,
  isNameDisabled,
  onClick,
  syndicationHistory,
  ...props
}: RedirectToPreviewLinkType): JSX.Element => {
  return (
    <>
      <PreviewLink data-testid="previewLink" onClick={onClick} syndicationHistory={syndicationHistory} {...props}>
        <Name disabled={isNameDisabled} {...props}>
          {name}
        </Name>
        <PreviewNavIcon {...props} />
      </PreviewLink>
    </>
  );
};

export default RedirectToPreviewLink;
