import styled from 'styled-components';

const ImageRightsNotice = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #171717;

  & > div {
    padding: 8px;
    border-radius: 4px;
    margin-top: 8px;

    div:first-child {
      float: left;
      margin-right: 4px;
    }
  }
`;
const WarningMessage = styled.div`
  background: #fffaeb;
`;

const SuccessMessage = styled.div`
  background: #ecf8ef;
`;

const ErrorMessage = styled.div`
  background: #fbe8e9;
`;

const FetchMessage = styled.div`
  background: #ecefff;
`;
export { ImageRightsNotice, WarningMessage, SuccessMessage, ErrorMessage, FetchMessage };
