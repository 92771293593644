import React from 'react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import DraftViewV1 from './DraftViewV1';
import DraftViewWithRights from './DraftViewWithRights';
import { RIGHTS_PHASE_ONE } from '../../helpers/flags';
import { DraftviewSlatType } from './types';

type draftviewProptypes = {
  slatData: DraftviewSlatType | Record<string, unknown> | undefined;
  marketBrand: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  notFound: boolean;
};

const DraftView = ({ setShowModal, slatData, marketBrand, notFound }: draftviewProptypes): JSX.Element => {
  const rightsPhaseOneEnabled = useFeatureToggle(RIGHTS_PHASE_ONE);

  if (rightsPhaseOneEnabled) {
    return (
      <DraftViewWithRights
        setShowModal={setShowModal}
        slatData={slatData}
        marketBrand={marketBrand}
        notFound={notFound}
      />
    );
  }
  return <DraftViewV1 setShowModal={setShowModal} slatData={slatData} marketBrand={marketBrand} notFound={notFound} />;
};

export default DraftView;
