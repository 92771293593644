import React, { ReactNode } from 'react';
import { ModalFooterWrapper } from './styles';

type ModalFooterropTypes = {
  children?: ReactNode;
};
const ModalFooter = ({ children }: ModalFooterropTypes): JSX.Element => (
  <>{!!children && <ModalFooterWrapper>{children}</ModalFooterWrapper>}</>
);

export default ModalFooter;
