import React, { useEffect } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import DiscoveryMarkets from '../DiscoveryMarkets/DiscoveryMarkets';
import Layout from '../App/Layout';
import { pageView } from '../../helpers/googleAnalytics';
import ChromeExtensionLink from '../../components/HeaderNavBar/ChromeExtensionLink';
import { DiscoveryWrapper, NavigationWrapper } from './styles';
import NavigationTabs from '../NavigationTabs';
import GlobalDrafts from './GlobalDrafts';
import StoryView from '../StoryView';

export const DiscoveryPageComp = ({ location }: RouteComponentProps): JSX.Element => {
  const isBrowserChrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
    window.navigator.vendor.toLowerCase().indexOf('google') !== -1;
  useEffect(() => {
    pageView();
  }, [location.pathname]);
  return (
    <Layout className="home-page">
      <DiscoveryWrapper data-cy="discovery">
        <NavigationWrapper data-cy="navigation-wrapper">
          <NavigationTabs />
          {isBrowserChrome ? <ChromeExtensionLink /> : null}
        </NavigationWrapper>
        <Switch>
          <Route
            exact
            path={['/globalDrafts/story-view/:id', '/globalDrafts']}
            render={() => (
              <>
                <GlobalDrafts />
                {location.pathname.startsWith('/globalDrafts/story-view/') ? <StoryView /> : null}
              </>
            )}
          ></Route>
          <Route
            exact
            path={['/story-view/:id', '/']}
            render={() => (
              <>
                <DiscoveryMarkets />
                {location.pathname.startsWith('/story-view/') ? <StoryView /> : null}
              </>
            )}
          ></Route>
        </Switch>
      </DiscoveryWrapper>
    </Layout>
  );
};

export default withRouter(DiscoveryPageComp);
