import React from 'react';
import { ImplicitCallback, SecureRoute } from '@okta/okta-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppSecurity from './components/App/Security';
import FeatureFlags from './components/App/FeatureFlags';
import { withPageView } from './helpers/googleAnalytics';
import DiscoveryPage from './components/pages/DiscoveryPage';
import AdminPage from './components/pages/AdminPage/index';
import PreviewPage from './components/pages/PreviewPage';
import { Helmet } from 'react-helmet';
import NotFoundPage from './components/pages/NotFoundPage';
import Localisation from './components/Localisation/Localisation';
import { SnowplowPageviewTracker } from './analytics/snowplow';

const App = (): JSX.Element => (
  <FeatureFlags>
    <Localisation>
      <Router>
        <AppSecurity>
          <Helmet>
            <title>Encore - Digital Syndication for Condé Nast</title>
          </Helmet>
          <SnowplowPageviewTracker>
            <Switch>
              {/* Order of routes is important! Order: more specific to less specific */}
              <Route path="/implicit/callback" component={ImplicitCallback} />
              <SecureRoute path="/preview/(.*)" component={withPageView(PreviewPage)} />
              <SecureRoute path="/admin" component={AdminPage} />
              <SecureRoute
                exact
                path={['/globalDrafts/story-view/:id', '/globalDrafts', '/story-view/:id', '/']}
                component={DiscoveryPage}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </SnowplowPageviewTracker>
        </AppSecurity>
      </Router>
    </Localisation>
  </FeatureFlags>
);

export default App;
