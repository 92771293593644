import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '../App/Layout';
import { withRouter } from 'react-router-dom';

import NotFound from '../NotFound';

import Preview, { previewType } from '../Preview/Preview';
import CopyToClipboardButton from '../HeaderNavBar/CopyToClipboard';
import SyndicateButton from '../SyndicateButton/SyndicateButton';
import useApi from '../../hooks/useApi';
import { withUser, UserType } from '../App/AuthContext';
import { plainTextRenderer } from '../../helpers/textRenderer';

type PreviewPageCompPropTypes = {
  // eslint-disable-next-line
  match: any;
  user: UserType;
};

type PropTypes = {
  data: previewType | null;
  loading: boolean;
  error: Error | null;
};
const PreviewPageComp = ({ match, user }: PreviewPageCompPropTypes): JSX.Element => {
  const query = match.params[0];
  const isLookup = query.includes('/');
  const { data, loading, error }: PropTypes = useApi(
    `get`,
    `/api/v1/preview?${isLookup ? 'publish_uri' : 'id'}=${query}`
  );
  let copyData = '';
  if (data && data.type === 'article') {
    const hed = data.hed || '';
    const description = data.description || '';
    const bodyDocument = data.body || '';
    const body = plainTextRenderer(bodyDocument);
    copyData = hed + '\n' + description + '\n' + body;
  }

  useEffect(() => {
    if (isLookup && data) {
      window.history.replaceState({}, '', `/preview/${data.id}`);
    }
  }, [isLookup, data]);

  return (
    <Layout
      className="preview-page"
      user={user}
      nav={() => (
        <>
          <p className="top-nav__page-title">Preview</p>
          {data && data.type === 'article' && (
            <CopyToClipboardButton data={copyData} canSyndicate={data.canSyndicate} />
          )}
          <SyndicateButton
            content={data}
            disabled={!data || !data.canSyndicate}
            trackingCategory="Preview"
            type="info"
          />
        </>
      )}
    >
      {data && <Preview data={data} />}
      {loading && <div>Loading...</div>}
      {error && <NotFound />}
    </Layout>
  );
};

export default withRouter(withUser(PreviewPageComp));
