import React, { useEffect, ReactElement, FunctionComponent } from 'react';
import {
  newTracker,
  enableActivityTracking,
  trackPageView,
  addGlobalContexts,
  enableLinkClickTracking,
  trackSelfDescribingEvent,
} from '@condenast/cn-snowplow-web';
import config from '../config';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { name, version } from '../../package.json';

export const initializeSnowplowTracker = (props: { collectorURL: string | undefined }): void => {
  // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/javascript-tracker-v3/tracker-setup/initialization-options/
  const appInfo = {
    appEnv: process.env.REACT_APP_NODE_SENTRY_ENV || 'development',
    appVersion: version,
    appName: name,
  };
  // allow anonymous config keys to override if targeting consent detected
  newTracker(props.collectorURL, config.snowplowConfig.newTracker, appInfo);
  enableActivityTracking(config.snowplowConfig.activityTracking);
  enableLinkClickTracking();
  const setupRefresh = () => {
    if (MutationObserver) {
      const observer = new MutationObserver(() => {
        window.snowplowCN('refreshLinkClickTracking');
      });
      const target = document.querySelector('body');
      if (!target) {
        // Delay if body is not ready.
        window.setTimeout(setupRefresh, 250);
        return;
      }
      observer.observe(target, {
        subtree: true,
        childList: true,
      });
    }
    window.snowplowCN('refreshLinkClickTracking');
  };
  // Delay setup until after page load.
  window.setTimeout(setupRefresh, 250);
};

export enum ActionType {
  EncoreComponentTrackingEvent = 'encore_component_tracking_event',
  EncoreContent = 'encore_content',
  EncoreSearchFilterEvent = 'search_filter_event',
}

const encoreContext = {
  schema: '',
  data: {},
};
export const trackCustomSnowplowActions = (action: ActionType, data: Record<string, unknown>): void => {
  const eventTypes: { [x: string]: string } = {
    encore_component_tracking_event: 'iglu:com.condenast/encore_component_tracking_event/jsonschema/1-0-0',
    encore_content: 'iglu:com.condenast/encore_content/jsonschema/1-0-0',
    search_filter_event: 'iglu:com.condenast/search_filter_event/jsonschema/3-0-0',
  };

  if (action === 'encore_content') {
    encoreContext.schema = eventTypes[action];
    encoreContext.data = data;
    window.snowplowCN('removeGlobalContexts', [encoreContext]);
    addGlobalContexts([encoreContext]);
  } else if (eventTypes[action]) {
    trackSelfDescribingEvent({
      event: {
        schema: eventTypes[action],
        data,
      },
    });
  }
};

type Props = { children: ReactElement } & RouteComponentProps;

const SnowplowTrackProvider: FunctionComponent<Props> = ({ children, history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      trackPageView();
    });

    // Call trackPageView() on initial page load
    trackPageView();

    return () => {
      unlisten(); // Clean up the listener when the component unmounts
    };
  }, [history]);

  return <>{children}</>;
};

export const SnowplowPageviewTracker = withRouter(SnowplowTrackProvider);
