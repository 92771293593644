import { useState, useEffect } from 'react';

const useToggleClassOnMount = (): boolean => {
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted;
};

export default useToggleClassOnMount;
