import * as md from '@condenast/copilot-markdown';
import { mdToReactUnwrapped } from '@condenast/copilot-markdown-react-renderer';

// Markdown chars that should not be parsed if they appear at the beginning
//  > or #
// copied from https://github.com/CondeNast/rocket/blob/46838e931acf95e65c720dfaff553c9cafda724f/src/server/graphql/utils/parseMarkdown/escapeUnwantedMarkdown.js
const RE_MD_UNWANTED_LEADING = /^[>#]/;

/**
 * Wrap up mdToReactUnwrapped with the copilot-markdown dependency
 *
 * @public
 * @param {string} content - the markdown string to parse
 * @returns {Object} - a React element array
 */
// eslint-disable-next-line
const markDownToReactUnwrapped = (content: string): Array<string> =>
  mdToReactUnwrapped(md)(content.replace(RE_MD_UNWANTED_LEADING, '\\$&'));

export default markDownToReactUnwrapped;
