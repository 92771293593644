import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Span = styled.span`
  margin-right: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
`;

function SlatType(props: { type: string | null | undefined }): JSX.Element | null {
  const { type } = props;

  if (type) {
    return <Span>{type}</Span>;
  }

  return null;
}

SlatType.propTypes = {
  type: PropTypes.string,
};

SlatType.defaultProps = {
  type: null,
};

export default SlatType;
