import moment from 'moment';

export interface contentSlatType {
  type?: string | null | undefined;
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  marketBrand: { name: string };
  canSyndicate: boolean;
  translatedHed?: string;
  isGlobalDraft?: boolean;
  updatedAt: string;
}

const olderThanADay = (publishDate: string) => new Date(Date.now() - 24 * 60 * 60 * 1000) > new Date(publishDate);

type PublicationDate = string | null;

export function publicationDate(date: string): PublicationDate {
  if (!date) return null;

  const timeSincePublication = olderThanADay(date)
    ? moment(date).format('MMM DD, YYYY, HH:MM')
    : moment(date).fromNow();

  return timeSincePublication;
}
