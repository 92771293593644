import React, { Fragment, ChangeEvent } from 'react';

type ToggleSwithCheckboxPropTypes = {
  labelText: string;
  isDisabled?: boolean;
  isChecked: boolean;
  handleChange(event: ChangeEvent): void;
  toggleId?: string;
};
const ToggleSwitchCheckbox = ({
  labelText,
  isDisabled = false,
  isChecked = false,
  handleChange,
  toggleId = 'toggle-checkbox',
}: ToggleSwithCheckboxPropTypes): JSX.Element => {
  return (
    <Fragment>
      <input
        type="checkbox"
        checked={isChecked}
        className="checkbox--switch"
        disabled={isDisabled}
        onChange={(event: ChangeEvent) => handleChange(event)}
        id={toggleId}
      />
      <label htmlFor={toggleId}>{labelText}</label>
    </Fragment>
  );
};

export default ToggleSwitchCheckbox;
