export const EXAMPLE_FLAG = 'example';
export const TARGET_DEMO_BRAND = 'targetDemoBrand';
export const FEATURE_TRANSLATIONS = 'translations';
export const TRANSLATED_SYNDICATION = 'translatedSyndication';
export const TRANSLATED_DISCOVERY = 'translatedDiscovery';
export const LEGACY_SYNDICATION = 'legacySyndication';
export const STYLED_SELECT = 'styledSelect';
export const GLOBAL_DRAFTS = 'globalDrafts';
export const SYNDICATION_COUNT = 'syndicationCount';
export const RIGHTS_PHASE_ONE = 'rightsPhaseOne';

const feature_Flags = [
  EXAMPLE_FLAG,
  TARGET_DEMO_BRAND,
  FEATURE_TRANSLATIONS,
  TRANSLATED_SYNDICATION,
  TRANSLATED_DISCOVERY,
  LEGACY_SYNDICATION,
  STYLED_SELECT,
  GLOBAL_DRAFTS,
  SYNDICATION_COUNT,
  RIGHTS_PHASE_ONE,
];

type FeatureFlagTypes = {
  example: boolean;
  targetDemoBrand: boolean;
  translations: boolean;
  translatedSyndication: boolean;
  translatedDiscovery: boolean;
  legacySyndication: boolean;
  styledSelect: boolean;
  globalDrafts: boolean;
  syndicationCount: boolean;
  rightsPhaseOne: boolean;
  [key: string]: boolean;
};
export const enabledFeatures = [
  TRANSLATED_SYNDICATION,
  FEATURE_TRANSLATIONS,
  TRANSLATED_DISCOVERY,
  GLOBAL_DRAFTS,
  SYNDICATION_COUNT,
  STYLED_SELECT,
  RIGHTS_PHASE_ONE,
];
// eslint-disable-next-line
export const defaultFlags: FeatureFlagTypes = feature_Flags.reduce((flags, flag) => {
  // eslint-disable-next-line no-param-reassign
  (flags as FeatureFlagTypes)[flag] = false || enabledFeatures.includes(flag);
  return flags;
}, {} as FeatureFlagTypes);

export function overrideDefaultFlagsWithSearchParams(
  defaults = defaultFlags,
  location = document.location.toString()
): FeatureFlagTypes {
  const params = new URL((location as unknown) as string).searchParams;
  return Object.keys(defaults).reduce((flags, flag) => {
    // eslint-disable-next-line no-param-reassign
    (flags as FeatureFlagTypes)[flag] = params.has(`flag_${flag}`) || enabledFeatures.includes(flag);
    return flags;
  }, {} as FeatureFlagTypes);
}
