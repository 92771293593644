import React from 'react';
import ImageTabContent from './DraftPreview.ImageContent';

const Images = (): JSX.Element => {
  return (
    <>
      <ImageTabContent />
    </>
  );
};

export default Images;
