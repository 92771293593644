export const en = {
  copyArticle: 'Copy the contents of this article',
  copiedArticle: 'Article content copied!',
};

export const languages = [
  { value: '', label: 'None Selected' },
  { value: 'de-DE', label: 'Deutsch (German)' },
  { value: 'en-GB', label: 'English - International (English - International)' },
  { value: 'en-US', label: 'English - USA (English - USA)' },
  { value: 'es-ES', label: 'Español (Spanish)' },
  { value: 'fr-FR', label: 'Français (French)' },
  { value: 'it-IT', label: 'Italiano (Italian)' },
  { value: 'ja-JP', label: '日本語 (Japanese)' },
  { value: 'ru-RU', label: 'Pусский (Russian)' },
  { value: 'zh-Hant-TW', label: '繁體字 (Chinese - Traditional)' },
];

export const discoveryMarketsLanguages = [
  { code: 'zh', name: 'Chinese' },
  { code: 'en', name: 'English' },
  { code: 'fr-FR', name: 'French' },
  { code: 'de-DE', name: 'German' },
  { code: 'it-IT', name: 'Italian' },
  { code: 'ja-JP', name: 'Japanese' },
  { code: 'es', name: 'Spanish' },
];

export const langFamily = {
  en: ['en', 'en-US', 'en-GB', 'en-IN'],
  es: ['es', 'es-ES', 'es-MX'],
  zh: ['zh', 'zh-Hant', 'zh-Hant-TW'],
};
