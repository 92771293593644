import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
`;

export const VerticalSepatatorLine = styled.div`
  border-right: 1px solid #dcdcdc;
`;

export const SyndicationModalActions = styled.div`
  display: flex;
  width: calc(1 / 3 * 100%);
  flex-direction: column;
  align-items: flex-end;
`;
