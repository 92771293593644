import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withUser } from '../../App/AuthContext';
import Layout from '../../App/Layout';
import Admin from './Admin';
import ReingestContentPage from './ReingestContentPage';
import UpdateMastheadPage from './UpdateMastheadPage';
import NotFound from '../../NotFound';

type adminProps = {
  user: {
    isAdmin: boolean;
    name: string;
    preferred_username: string;
    email: string;
  };
  match: {
    path: string;
  };
};

export const AdminPage = ({ user, match }: adminProps): JSX.Element => {
  const { path } = match;
  return (
    <Layout user={user} className="admin-page">
      {user.isAdmin ? (
        <>
          <Switch>
            <Route path={`${path}`} exact component={Admin}></Route>
            <Route path={`${path}/reingest-content`} exact component={ReingestContentPage}></Route>
            <Route path={`${path}/update-masthead`} exact component={UpdateMastheadPage}></Route>
            <Route component={NotFound} />
          </Switch>
        </>
      ) : (
        <h2>Unauthorised</h2>
      )}
    </Layout>
  );
};

export default withRouter(withUser(AdminPage));
