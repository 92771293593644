import React, { useEffect, useState } from 'react';
import { CautionIcon, CheckCircleIcon, DangerIcon, AnimatedEllipsisIcon, EllipsisIcon } from '@condenast/gemini/icons';
import { ImageRightsNotice, WarningMessage, SuccessMessage, ErrorMessage, FetchMessage } from './ImageRightsNotice';
import { FormattedMessage } from 'react-intl';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import { CSSVariables, IntlProvider, ThemeProvider } from '@condenast/gemini';

const DraftImageRightsNotice = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const {
    state: {
      draftView: { targetBrand, imagesWithRightsStatus, marketBrands, totalImagesWithRightsCount },
    },
  } = useGlobalStateValue();
  const targetBrandDetails = marketBrands.data && marketBrands.data.find((brand) => brand.code === targetBrand);

  useEffect(() => {
    imagesWithRightsStatus.startsWith('fetching') ? setLoading(true) : setLoading(false);
  }, [imagesWithRightsStatus]);
  return (
    <>
      <ThemeProvider theme="light">
        <IntlProvider>
          <CSSVariables />
          <ImageRightsNotice>
            {imagesWithRightsStatus === 'warning' && (
              <WarningMessage>
                <div>
                  <CautionIcon size="small" color="var(--color-yellow-30)" />
                </div>
                <div>
                  <FormattedMessage
                    id="create.imagerights.warning"
                    description="Image rights warning message"
                    defaultMessage={`${totalImagesWithRightsCount.unAvailableImages} images have undetermined reuse rights for ${targetBrandDetails?.name}`}
                  />
                </div>
              </WarningMessage>
            )}
            {imagesWithRightsStatus === 'success' && (
              <SuccessMessage>
                <div>
                  <CheckCircleIcon size="small" color="var(--color-green-30)" />
                </div>
                <div>
                  <FormattedMessage
                    id="create.imagerights.success"
                    description="Image rights success message"
                    defaultMessage={`All images cleared for reuse.`}
                  />
                </div>
              </SuccessMessage>
            )}
            {imagesWithRightsStatus === 'error' && (
              <ErrorMessage>
                <div>
                  <DangerIcon size="small" color="var(--color-red-30)" />
                </div>
                <div>
                  <FormattedMessage
                    id="create.imagerights.error"
                    description="Image rights error message"
                    defaultMessage={`Failed to load image rights`}
                  />
                </div>
              </ErrorMessage>
            )}
            {imagesWithRightsStatus.startsWith('fetching') && (
              <FetchMessage>
                <div>
                  {loading ? (
                    <AnimatedEllipsisIcon size="small" className="loading" />
                  ) : (
                    <EllipsisIcon size={'small'}></EllipsisIcon>
                  )}
                </div>
                <div>
                  <FormattedMessage
                    id="create.imagerights.fetch"
                    description="Image rights fetched message"
                    defaultMessage={`Fetching image reuse rights for ${targetBrandDetails?.name}`}
                  />
                  {imagesWithRightsStatus === 'fetching-timeout' && (
                    <FormattedMessage
                      id="create.imagerights.fetchtoolong"
                      description="Image rights fetched message"
                      defaultMessage="This is taking longer than expected. Please wait for few minutes."
                    />
                  )}
                </div>
              </FetchMessage>
            )}
          </ImageRightsNotice>
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};

export default DraftImageRightsNotice;
