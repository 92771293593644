import React, { createContext, ReactNode } from 'react';
import { withAuth } from '@okta/okta-react';
import axios from 'axios';

type Auth = {
  isAuthenticated: () => Promise<boolean>;
  getAccessToken: () => Promise<string>;
  getUser: () => Promise<UserType>;
};
export type UserType = {
  userId?: string;
  email: string;
  name: string;
  preferred_username?: string;
};
type Props = {
  auth: Auth;
  children: ReactNode;
};
type State = {
  isAuthenticated?: boolean;
  error?: string | null;
  user?: UserType | null;
  isAdmin?: boolean;
};
const initialState: State = {
  isAuthenticated: false,
  error: null,
  user: null,
  isAdmin: false,
};
export const AuthContext = createContext<State>(initialState);
export class AuthController extends React.Component<Props, State> {
  isComponentMounted = false;
  state: State = {
    isAuthenticated: false,
    error: null,
    user: null,
    isAdmin: false,
  };
  async componentDidMount(): Promise<void> {
    this.isComponentMounted = true;
    await this.checkAuthentication();
  }
  componentDidUpdate(): void {
    const { error } = this.state;
    if (!error) {
      this.checkAuthentication();
    }
  }
  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }
  async validateUser(): Promise<void> {
    const { auth } = this.props;
    const { user } = this.state;
    try {
      const accessToken = await auth.getAccessToken();
      const config = {
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          email: user?.email,
        },
      };
      const { status } = await axios({ method: 'post', url: 'api/v1/admin-users', ...config });
      const isAdmin = status === 200;
      this.setState({ isAdmin });
    } catch (e) {
      if (this.isComponentMounted) {
        this.setState({
          error: (e as Error)?.message,
        });
      }
    }
  }
  async checkAuthentication(): Promise<void> {
    const { auth } = this.props;
    const isAuthenticated = await auth.isAuthenticated();
    if (isAuthenticated && isAuthenticated !== this.state.isAuthenticated) {
      try {
        const user = await auth.getUser();
        this.setState({
          isAuthenticated,
          user,
        });
        /** Valid admin users only after getting user info*/
        this.validateUser();
      } catch (e) {
        this.setState({
          error: (e as Error)?.message,
        });
      }
    }
  }
  render(): JSX.Element {
    const { user, isAdmin } = this.state;
    return <AuthContext.Provider value={{ user, isAdmin }}>{this.props.children}</AuthContext.Provider>;
  }
}

export const AuthProvider = withAuth(AuthController);

// eslint-disable-next-line
export const withUser = (Component: any) => {
  // eslint-disable-next-line
  const WrappedComponent = (props: any) => (
    <AuthContext.Consumer>
      {({ user, isAdmin }) => user && <Component {...props} user={{ ...user, isAdmin }} />}
    </AuthContext.Consumer>
  );
  WrappedComponent.displayName = `withUser(${Component.displayName || Component.name})`;
  return WrappedComponent;
};
