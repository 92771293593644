import React from 'react';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import Skeleton from 'react-loading-skeleton';
import { Body } from '../../styles';
import BodyRenderer from '../../../../helpers/ReactRenderer';
import { versoText } from '../../../../helpers/textRenderer';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { RIGHTS_PHASE_ONE } from '../../../../helpers/flags';

const StoryText = (): JSX.Element => {
  const {
    state: { draftView },
  } = useGlobalStateValue();
  const rightsPhaseOneEnabled = useFeatureToggle(RIGHTS_PHASE_ONE);
  const { l10n, targetLang, translationStatus, sourceLang } = draftView;
  const previewData = l10n[targetLang] || l10n[sourceLang];
  const bodyText = versoText(previewData?.body || '');
  const translating = translationStatus === 'pending';
  return (
    <Body rightsPhaseOneEnabled={rightsPhaseOneEnabled}>
      {translating ? <Skeleton count={10} /> : <BodyRenderer body={bodyText} />}
    </Body>
  );
};

export default StoryText;
