import styled, { css } from 'styled-components';
import Button from '../StyledComponents/Button';

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectDropdownButton = styled(Button)`
  align-items: center;
  position: relative;
  margin: 0;
  text-transform: capitalize;
  border: 1px solid #dcdcdc;
  font-weight: 400;
  padding: 0.512rem 2rem 0.512rem 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #f3f3f3;
  color: #171717;
  &:hover {
    background-color: #dcdcdc;
  }
  &:hover::before {
    display: none;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid #3d5afe;
  }
  &[aria-expanded='true'] {
    box-shadow: 0 0 0 1px #3d5afe;
  }
  svg {
    transition-property: transform, -webkit-transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    position: absolute;
    right: 8px;
    top: 11px;
    fill: currentColor;
  }
  &[aria-expanded='true'] svg {
    transform: rotate(180deg);
  }
`;

const SelectDropdownWrapper = styled.div`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  width: auto;
  left: 0;
  box-shadow: 0 0 2px rgba(23 23 23 / 30%);
  border-radius: 4px;
  background-color: #fff;
  z-index: 150;
  margin-top: 8px;
  min-width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 37vh;
  will-change: transform;
  transition: transform 0.3s;
  ${({ isOutBottom }) =>
    isOutBottom &&
    css`
      transform: translate3d(0px, -100%, 0px);
      margin-top: -60px;
    `}
`;

const SelectUlWrapper = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin: 0.4rem 0;
  max-width: 100%;
`;

const SelectLiWrapper = styled.li`
  color: #171717;
  margin: 0;
  padding: 0.3rem 1rem;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  position: relative;
  min-height: 1.625em;
  cursor: pointer;
  white-space: pre;
  &:hover {
    background: #f3f3f3;
  }
`;

export { SelectWrapper, SelectDropdownWrapper, SelectUlWrapper, SelectLiWrapper, SelectDropdownButton };
