const env = {
  test: {
    issuer: 'https://fake.com/oauth2/default',
    redirect_uri: `${window.location.origin}/implicit/callback`,
    client_id: '3',
  },
  development: {
    issuer: 'https://condenast-hub.okta-emea.com/oauth2/default',
    redirect_uri: `${window.location.origin}/implicit/callback`,
    client_id: '0oa33xyhp0v4FQKCO0i7',
  },
  production: {
    issuer: 'https://condenast-hub.okta-emea.com/oauth2/default',
    redirect_uri: `${window.location.origin}/implicit/callback`,
    client_id: '0oa33ptcms9QHgF6G0i7',
  },
  filters: {
    DISPLAY: 'display',
    BRANDS: 'brands',
    MARKETS: 'markets',
    LANGUAGES: 'languages',
    SEARCH: 'q',
    DATE_RANGE: 'dateRange',
  },
  dateRange: [
    { code: 'past-hour', name: 'Past Hour' },
    { code: 'past-24-hours', name: 'Past 24 Hours' },
    { code: 'past-week', name: 'Past Week' },
    { code: 'past-month', name: 'Past Month' },
    { code: 'past-year', name: 'Past Year' },
  ],
  snowplowConfig: {
    newTracker: {
      appId: 'encore',
      contexts: {
        performanceTiming: true,
        clientHints: true,
      },
      anonymousTracking: false,
      stateStorageStrategy: 'none',
      discoverRootDomain: true,
      cookieSameSite: 'None',
      cookieSecure: true,
      eventMethod: 'post',
      postPath: '/com.condenast/yv8',
    },
    activityTracking: {
      minimumVisitLength: 5,
      heartbeatDelay: 10,
    },
  },
  inputSizeLimit: 30000,
};
export default env;
