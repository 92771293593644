import React from 'react';
import styled from 'styled-components';
import { ReactComponent as RedirectIcon } from './redirect.svg';

const Svg = styled(RedirectIcon)``;

const NavigateIcon = ({ ...props }: React.SVGProps<SVGSVGElement> & { children?: React.ReactNode }): JSX.Element => (
  <Svg {...props}></Svg>
);

export default NavigateIcon;
