const DRAFT_VIEW_CONSTANTS = {
  SET_DRAFTVIEW_TOAST: 'setDraftViewToast',
  CLEAR_DRAFTVIEW_TOAST: 'clearDraftViewToast',
  TOGGLE_DRAFTVIEW_TOAST: 'toggleDraftViewToast',
  SET_DRAFTVIEW_ERROR_MESSAGE: 'setDraftViewErrorMessage',
  SET_DRAFTVIEW_TARGET_BRAND: 'setDraftViewTargetBrand',
  SET_DRAFTVIEW_PENDING: 'draftViewPending',
  SET_DRAFTVIEW_ERROR: 'draftViewError',
  SET_DRAFTVIEW_SUCCESS: 'draftViewSuccess',
  SET_DRAFTVIEW_DUPLICATE: 'draftViewDuplicate',
  SET_DRAFTVIEW_INPROGRESS: 'draftViewInProgress',
  SET_DRAFTVIEW_TIMEOUT: 'draftViewTimeout',
  SET_DRAFT_FORM_TARGET_LANGUAGE: 'draftFormTargetLanguage',
  SET_DRAFT_FORM_SOURCE_LANGUAGE: 'draftFormSourceLanguage',
  RESET_DRAFTVIEW_REDUCER: 'resetDraftViewReducer',
  SET_DRAFTVIEW_TRANSLATION_RESPONSE: 'draftViewTranslationResponse',
  SET_DRAFTVIEW_TRANSLATION_STATUS: 'draftViewTranslationStatus',
  SET_MARKET_BRANDS: 'setMarketBrands',
  SET_CONTENT_DATA: 'setContentData',
  SET_IMAGES_WITHRIGHTS_STATUS: 'setImagesWithRightsStatus',
  SET_RIGHTS_TOAST_STATUS: 'setRightsToastStatus',
  SET_TOTAL_IMAGE_RIGHTS_COUNT: 'setImageRightsCount',
  MISSING_DESTINATION_CONTENT_TYPE: 'missingDestinationContentType',
};

export default DRAFT_VIEW_CONSTANTS;
