import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import Select from '../../Select/Select';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';
import useSetSelectedDestination from '../hooks/useSetSelectedDestination';
import { setDraftViewErrorMessage, toggleDraftViewToast, setDraftViewTargetBrand } from '../../../actions/draftView';
import { trackEvent } from '../../../helpers/googleAnalytics';
import { sortBrandMarkets } from '../utils';
import { Destination, ErrorMessage, NativeSelect, DestinationLabel } from '../styles';
import { DraftViewReducertype, MarketBrandsData } from '../../../types';
import { setGlobalDraft } from '../../../actions/globalDraft';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type SlatDataType = {
  id: string;
  type: string;
  sourceUri: string;
  language: string;
  canSyndicate: boolean;
  marketBrandCode: string;
  isGlobalDraft: boolean;
  syndicationStatus: string;
};

type TargetValueType = {
  value: string;
  label: string;
};

type TargetValue = { target: TargetValueType };

const DraftFormDestinaltion = ({
  slatData,
  history,
  location,
}: { slatData: SlatDataType } & RouteComponentProps): JSX.Element => {
  const isTargetDemoBrandEnabled = useFeatureToggle('targetDemoBrand');
  const styledSelect = useFeatureToggle('styledSelect');
  const {
    state: {
      globalDraft: { selectedGlobalDraft },
      draftView,
      globalDraft,
    },
    dispatch,
  } = useGlobalStateValue();
  const { globalDraftsTargetMarketBrands } = globalDraft;
  const {
    syndicationStatus,
    targetBrand,
    errorMessage,
    marketBrands: { data },
  }: DraftViewReducertype = draftView;
  const syndicating = syndicationStatus === 'pending';
  const [selectedDestination, setSelectedDestination] = useSetSelectedDestination();
  const setTargetBrand = (targetBrand: string) => {
    if (!targetBrand) return;
    trackEvent('Modal - Syndicate', 'Target brand change', { label: targetBrand });
    localStorage.setItem('targetBrand', targetBrand);
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('destination') !== targetBrand) {
      searchParams.set('destination', targetBrand);
      history.replace({ pathname: location.pathname, search: searchParams.toString() });
    }
    dispatch && dispatch(setDraftViewTargetBrand(targetBrand));
  };

  let targetBrands: MarketBrandsData[] | undefined = location.pathname.startsWith('/globalDrafts')
    ? globalDraftsTargetMarketBrands
    : data;
  let sortedTargetBrands: undefined | TargetValueType[] = [];
  if (Array.isArray(targetBrands)) {
    targetBrands = sortBrandMarkets(targetBrands);
    sortedTargetBrands =
      targetBrands && targetBrands.map(({ name, code }): TargetValueType => ({ label: name, value: code }));
  } else {
    sortedTargetBrands = [];
  }
  const getTargetBrands = (isTargetDemoFeatureEnabled: boolean) => {
    const { marketBrandCode } = slatData;
    const DEMO_BRANDS = [
      { label: 'Demo', value: 'dmo' },
      { label: 'Demo EU', value: 'demoeu' },
      { label: 'Demo AP', value: 'demoap' },
    ];
    let allBrands: TargetValueType[] | undefined;
    if (!isTargetDemoFeatureEnabled) allBrands = sortedTargetBrands;
    else if (sortedTargetBrands?.length) allBrands = [...DEMO_BRANDS, ...sortedTargetBrands];
    return allBrands?.filter(({ value }: TargetValueType) => value !== marketBrandCode);
  };
  const destinationChangeHandler = ({ value, label }: TargetValueType) => {
    setSelectedDestination(label);
    setTargetBrand(value);
    dispatch && dispatch(setDraftViewErrorMessage({ isVisible: false, title: '', message: '', code: null }));
    dispatch && dispatch(toggleDraftViewToast(false));
  };
  const nativeDestinationChangeHandler = ({ target: { value } }: TargetValue): void => {
    const targetBrand: TargetValueType | undefined = getTargetBrands(isTargetDemoBrandEnabled)?.find(
      (brand: { value: string }) => value === brand.value
    );
    targetBrand?.label && setSelectedDestination(targetBrand.label);
    setTargetBrand(value);
    dispatch && dispatch(setDraftViewErrorMessage({ isVisible: false, title: '', message: '', code: null }));
    dispatch && dispatch(toggleDraftViewToast(false));
  };

  useEffect(() => {
    const targetBrands = getTargetBrands(isTargetDemoBrandEnabled);
    const filteredTargetBrands = targetBrands?.map((obj) => obj.value);

    let targetBrand =
      new URLSearchParams(location.search).get('destination') || localStorage.getItem('targetBrand') || '';
    if (!targetBrand || (targetBrand && !filteredTargetBrands?.includes(targetBrand))) {
      targetBrand = targetBrands?.[0]?.value || targetBrand;
    }
    setTargetBrand(targetBrand || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDraftsTargetMarketBrands]);
  useEffect(() => {
    if (slatData?.marketBrandCode && !selectedGlobalDraft.value && Array.isArray(data)) {
      const marketBrand = data.find(({ code }) => code === slatData.marketBrandCode);
      if (marketBrand?.brandCode && dispatch) {
        dispatch(setGlobalDraft({ value: marketBrand.brandCode, label: '' }));
      }
    }
  }, [slatData, data, selectedGlobalDraft.value, dispatch]);

  return (
    <Destination errorCode={errorMessage.code} syndicating={syndicating}>
      <DestinationLabel>
        <FormattedMessage id="create.modal.destination" description="Destinaltion label" defaultMessage="Destination" />
      </DestinationLabel>
      {styledSelect ? (
        <Select
          type="destination"
          options={getTargetBrands(isTargetDemoBrandEnabled)}
          value={targetBrand}
          onChange={destinationChangeHandler}
        >
          <Select.Title>{selectedDestination}</Select.Title>
        </Select>
      ) : (
        <div>
          <NativeSelect
            className="select-standard"
            data-testid="select-from-copilot"
            value={targetBrand}
            onChange={nativeDestinationChangeHandler}
          >
            <option data-testid="opt-list" value="" disabled>
              Create a draft in Copilot for...
            </option>
            {getTargetBrands(isTargetDemoBrandEnabled)?.map((brand: TargetValueType) => (
              <option key={brand.value} value={brand.value}>
                {brand.label}
              </option>
            ))}
          </NativeSelect>
        </div>
      )}
      {!syndicating && errorMessage.code === 409 && <ErrorMessage>{errorMessage.title}</ErrorMessage>}
    </Destination>
  );
};

export default withRouter(DraftFormDestinaltion);
