import React from 'react';
import { ReactComponent as ChromeExtensionIcon } from '../../styles/images/chrome-extension-icon.svg';
import { ChromeLink } from './styles';

const ChromeExtensionLink = (): JSX.Element => {
  return (
    <ChromeLink
      target="_blank"
      rel="noopener noreferrer"
      href="https://chrome.google.com/webstore/detail/encore-chrome-extension/iclppkipaedladlafogaefcfjaokdmgp"
    >
      <ChromeExtensionIcon />
    </ChromeLink>
  );
};

export default ChromeExtensionLink;
