import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import { Toast, ThemeProvider, CSSVariables, IntlProvider } from '@condenast/gemini';
import { FormattedMessage } from 'react-intl';
import { setRightsToastStatus } from '../../../../actions/draftView';

const ToastWrapper = styled.div`
  width: 25rem;
  position: fixed;
  bottom: 6rem;
  left: 7rem;
  .toast {
    border-width: 1px 1px 1px 4px;
  }

  .image-rights-toast button {
    min-height: 0;
  }
`;

const DraftPreviewToast = (): JSX.Element => {
  const {
    dispatch,
    state: { draftView },
  } = useGlobalStateValue();
  const {
    imagesRightsToastStatus: { showToast, toastType },
  } = draftView;

  const [toastVisible, setToastVisible] = useState(showToast);

  useEffect(() => {
    setToastVisible(showToast);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setToastVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showToast]);

  return (
    <>
      {toastVisible && (
        <ThemeProvider theme="light">
          <IntlProvider>
            <CSSVariables />

            <ToastWrapper data-testid="toast">
              <Toast
                type={toastType}
                onClose={() => {
                  dispatch &&
                    dispatch(
                      setRightsToastStatus({
                        showToast: false,
                        toastType: 'success',
                      })
                    );
                }}
                className="image-rights-toast"
              >
                <FormattedMessage
                  id="create.modal.imageRightsStatus"
                  description="View image rights call status"
                  defaultMessage={
                    toastType === 'success'
                      ? 'Image usage rights have been loaded'
                      : toastType === 'error'
                      ? 'Failed to load image usage rights'
                      : 'none'
                  }
                />
              </Toast>
            </ToastWrapper>
          </IntlProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default DraftPreviewToast;
