import React from 'react';
import ReactDOM from 'react-dom';
import es_config from './helpers/getEscapedConfig';
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import { sentryInit } from './utils/sentryInit';
import { GlobalStateProvider } from './hooks/useGlobalContext';
import './index.scss';
import { initGA } from './helpers/googleAnalytics';
import { IntercomProvider } from 'react-use-intercom';
import { SentryOptions } from './helpers/sentryTrack';
import snowplow from '@condenast/cn-snowplow-web';
import { initializeSnowplowTracker } from './analytics/snowplow';

typeof window !== 'undefined' && window.location.search.indexOf('no_snowplow') < 0 && snowplow.injectScript();
const collectorURL = es_config.snowplowURL ? String(es_config.snowplowURL) : process.env.REACT_APP_SNOWPLOW_HOST;
initializeSnowplowTracker({ collectorURL });

process.env.NODE_ENV !== 'development' && sentryInit();
initGA();

const appId = 'i38zvy17';

ReactDOM.render(
  <GlobalStateProvider>
    <IntercomProvider appId={appId}>
      <ErrorBoundary
        beforeCapture={function (): SentryOptions {
          throw new Error('Function not implemented.');
        }}
      >
        <App />
      </ErrorBoundary>
    </IntercomProvider>
  </GlobalStateProvider>,
  document.getElementById('root')
);
