import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Body = styled.p`
  margin: 0.5rem 0;
  font-size: 16px;
  color: #171717;
`;
type alertBodyPropTypes = {
  children?: ReactNode;
};

const AlertBody = ({ children }: alertBodyPropTypes): JSX.Element => <>{!!children && <Body>{children}</Body>}</>;

export default AlertBody;
