import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withPageView } from '../../../helpers/googleAnalytics';
import { Title } from '@condenast/gemini';

type AdminWrapperProps = {
  title: string;
  children: JSX.Element;
};

const AdminPage = styled.div`
  width: 880px;
  margin: auto;
  margin-top: 30px;
  max-width: 100%;
  @media (max-width: 600px) {
    width: 568px;
  }
  @media (max-width: 375px) {
    width: 343px;
  }
`;

const AdminTitle = styled.div`
  div {
    display: block;
    float: left;
    max-height: 24px;
    margin-bottom: 4px;
    ul {
      padding-left: 0;
      margin: 0;

      a {
        border-bottom: none;
        font-weight: 700;
      }
    }
  }
`;

const ChildrenDiv = styled.div`
  ${(props) => (props.title === 'Reingest Content' ? 'float: left' : '')};
  h1 {
    margin-top: 0;
  }
`;

export const AdminWrapper = ({ title, children }: AdminWrapperProps): JSX.Element => {
  return (
    <AdminPage>
      <AdminTitle>
        <Title
          breadcrumbs={[
            <Link to={'/admin'} key="admin">
              Admin Tools
            </Link>,
          ]}
        ></Title>
      </AdminTitle>
      <ChildrenDiv title={title}>{children}</ChildrenDiv>
    </AdminPage>
  );
};

export default withRouter(withPageView(AdminWrapper));
