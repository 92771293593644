import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, TopActions, WarningMessage, StoryText } from '../styles';

interface ImageSlatType {
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  marketBrand: { name: string };
  canSyndicate: boolean;
  language: string;
  type: string;
  sourceUri: string;
}

type StoryImageSlatpropType = {
  slatData?: ImageSlatType;
};
const StoryImage = ({ slatData }: StoryImageSlatpropType): JSX.Element => {
  const intl = useIntl();
  return (
    <TopActions>
      <StoryText>
        <FormattedMessage id="create.modal.storyImage" description="Story image label" defaultMessage="Story Image" />
      </StoryText>
      <Image>
        <img
          alt={intl.formatMessage({
            id: 'image.alt',
            defaultMessage: 'Preview',
            description: 'Text to display if image not loaded',
          })}
          className="thumbnailImage"
          src={slatData?.promoImageUri || '/image-placeholder.png'}
        />
      </Image>
      <WarningMessage>
        <FormattedMessage
          id="create.modal.imageRights"
          description="Rights for the image"
          defaultMessage="Have you checked image rights? Images must be checked before publishing on Copilot."
        />
      </WarningMessage>
    </TopActions>
  );
};

export default StoryImage;
