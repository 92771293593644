import styled, { css } from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      flex-direction: column;
    `}
`;

const ModalBody = styled.div`
  padding: 0.5rem 1.5rem;
`;

const HorizontalSeparatorLine = styled.hr`
  border-top: 1px solid #000000;
  margin: 1.4rem 0 2rem;
`;

const VerticalSepatatorLine = styled.div`
  border-right: 1px solid #dcdcdc;
  margin: 2rem 1.5rem 0 0;
`;

const SyndicationDraftForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.5rem;
  justify-content: space-between;
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      padding: 1rem;
      margin: unset;
    `}
`;

const Image = styled.div`
  font-weight: 700;
  width: 100%;
  height: ${({ height }) => height || 11.2}rem;
  margin: 0.5rem 0 1.5rem 0;
  img {
    object-fit: scale-down;
    background: #f6f6f6;
    margin-top: 0.5rem;
  }
`;

const SyndicateModalBody = styled.div`
  width: 85%;
`;

const AuthorDetails = styled.div`
  color: #565656;
  span {
    color: #171717;
    margin-right: 0.5rem;
  }
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      line-height: 1.25rem;
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #3953e7;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const Destination = styled.div`
  button {
    border: ${({ errorCode, syndicating }) =>
      errorCode === 409 && !syndicating ? '1px solid #DA1E28' : '1px solid #DCDCDC'} !important;
    margin-top: 0.5rem;
    font-weight: 400;
    background: #f3f3f3;
    text-wrap: wrap;
    word-break: break-all;
    &:focus {
      box-shadow: 0 0 0 1px #3d5afe;
    }
    &[aria-expanded='true'] {
      box-shadow: 0 0 0 2px #3d5afe;
    }
  }
  li {
    font-weight: 400;
  }
`;

const DestinationLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const ToastWrapper = styled.div`
  width: 25rem;
  position: absolute;
  bottom: 0;
  left: -11rem;
  .toast-title {
    font-weight: 400;
  }
  a {
    border-bottom: none;
    font-weight: 700;
  }
  .toast {
    border-width: 1px 1px 1px 4px;
  }
  .toast-action > button {
  &:active:not(:disabled), &:active, &:focus, &:focus:not(:disabled), &.button--secondary {
    background: none;
    border: none;
    box-shadow: none;
    padding-left:0;
  }
`;
const Body = styled.div`
  size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      line-height: 1.25rem;
      padding-top: 16px;
      div {
        p:first-child {
          margin-block-start: unset;
        }
      }
    `}
`;

const Hed = styled.div`
  font-weight: 700;
  font-size: 32px;
  margin: 0.8rem 0;
  line-height: 40px;
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin: 0.5rem 0;
    `}
`;

const TopActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const CloseModalWrapper = styled.div``;

const StoryText = styled.h4`
  margin-bottom: 0;
  margin-top: 1.1rem;
  font-weight: 700;
  @media (max-height: 600px) {
    display: none;
  }
`;

const BottomActions = styled.div`
  width: 100%;
  color: #171717;
  font-weight: 600;
  padding-top: ${(props) => (props.syndicationCountEnabled ? '' : '1.5rem')};
  button {
    width: 100%;
    height: 2.2rem;
  }
`;

const WarningMessage = styled.div`
  color: #565656;
  @media (max-height: 600px) {
    display: none;
  }
`;

const Translation = styled.div`
  margin-top: ${(props) => (props.syndicationCountEnabled ? '' : '1rem')};
  button {
    border: 1px solid #dcdcdc;
    margin: 0.5rem 0 1rem;
    font-weight: 400;
    background: #f3f3f3;
  }
  li {
    font-weight: 400;
  }
`;

const TranslationLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const CreateDraft = styled.div`
  button {
    padding: 8px 94px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.button--primary.pending,
    &.button--primary.pending:hover {
      background-color: #778bfe;
    }
  }
  button:focus {
    box-shadow: none;
  }
  margin-top: 1rem;
  svg {
    g {
      fill: #fff;
    }
    margin-right: 4px;
  }
  @keyframes hop {
    0% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(3px);
    }

    40% {
      transform: translateY(0);
    }
  }

  .hellip {
    display: block;
    margin: auto;

    &.animated {
      circle {
        animation: hop 1s ease infinite;

        &:nth-of-type(1) {
          animation-delay: 0.1s;
        }

        &:nth-of-type(2) {
          animation-delay: 0.2s;
        }
      }
    }
  }
`;

const ErrorMessage = styled.div`
  margin-bottom: 1rem;
  color: #a01c23;
  font-weight: 400;
`;

const LanguageLabel = styled.div`
  font-weight: 400;
`;

const Scroll = styled.div`
  padding-right: 1.5rem;
  height: 85vh;
  box-sizing: border-box;
  overflow-y: auto;
  ${({ rightsPhaseOneEnabled }) =>
    rightsPhaseOneEnabled &&
    css`
      padding: 1rem 1rem 0 1rem;
    `}
`;

const NativeSelect = styled.select`
  width: 100%;
  background: rgb(243, 243, 243);
  height: 2.4rem;
  border: 1px solid rgb(220, 220, 220);
  padding: 0 0.8rem;
  font-weight: 400;
  border-radius: 3px;
`;

export {
  ModalWrapper,
  ModalBody,
  VerticalSepatatorLine,
  SyndicationDraftForm,
  SyndicateModalBody,
  HorizontalSeparatorLine,
  Body,
  Hed,
  Image,
  Header,
  TopActions,
  BottomActions,
  Destination,
  ToastWrapper,
  WarningMessage,
  Translation,
  CreateDraft,
  ErrorMessage,
  LanguageLabel,
  AuthorDetails,
  Scroll,
  StoryText,
  CloseModalWrapper,
  NativeSelect,
  DestinationLabel,
  TranslationLabel,
};
