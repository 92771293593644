import { GLOBAL_DRAFT_CONSTANTS } from '../constants/reducer-constants';
const { SET_GLOBAL_DRAFT, SET_GLOBAL_DRAFT_TARGET_BRANDS } = GLOBAL_DRAFT_CONSTANTS;
import { GlobalDraftType, Action } from '../types';

const initialState: GlobalDraftType = {
  selectedGlobalDraft: {
    value: '',
    label: '',
  },
  globalDraftsTargetMarketBrands: [],
};

type ReducerType = (state?: GlobalDraftType, action?: Action) => GlobalDraftType;

const globalDraftsReducer: ReducerType = (state = initialState, action) => {
  switch (action?.type) {
    case SET_GLOBAL_DRAFT:
      return {
        ...state,
        selectedGlobalDraft: action?.payload,
      };
    case SET_GLOBAL_DRAFT_TARGET_BRANDS:
      return {
        ...state,
        globalDraftsTargetMarketBrands: action?.payload,
      };
    default:
      return state;
  }
};

export default globalDraftsReducer;
