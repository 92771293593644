import React, { useEffect, useState } from 'react';
import { ReactComponent as CopyIcon } from '@condenast/formation-styleguide/src/assets/icons/copy.svg';
import { ReactComponent as SimpleCheck } from '@condenast/formation-styleguide/src/assets/icons/simple-check.svg';
import copyToClipBoard from 'copy-to-clipboard';
import Tooltip from '../../helpers/tooltip';
import PropTypes from 'prop-types';
import { en as i18Data } from '../../languagePack';
type CopyToClipboardButtonPropType = {
  data: string;
  canSyndicate: boolean;
};

const CopyToClipboardButton = ({ data, canSyndicate }: CopyToClipboardButtonPropType): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const ref = React.useRef<null | ReturnType<typeof setTimeout> | ReturnType<typeof clearTimeout>>(null);

  const mouseLeaveHandler = () => {
    setIsHovered(false);
    if (isCopied) {
      ref.current = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const clickHandler = () => {
    copyToClipBoard(data);
    setIsCopied(true);
  };

  useEffect(() => {
    return () => {
      ref.current && clearTimeout(ref.current);
    };
  }, []);

  return (
    <div className="copy-button" data-testid="copy-button">
      <button
        disabled={!canSyndicate}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={mouseLeaveHandler}
        className={`button-secondary button--icon ${isHovered ? 'color-gray-x-light' : 'color-white'}`}
        onClick={clickHandler}
      >
        {isCopied ? <SimpleCheck /> : <CopyIcon />}
      </button>
      {isHovered && (
        <Tooltip
          className={isCopied ? 'tooltip-content-copied' : ''}
          message={isCopied ? i18Data['copiedArticle'] : i18Data['copyArticle']}
        />
      )}
    </div>
  );
};

CopyToClipboardButton.prototype = {
  data: PropTypes.string,
  canSyndicate: PropTypes.bool.isRequired,
};

export default CopyToClipboardButton;
