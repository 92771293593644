import styled from 'styled-components';

const ImageTabContentWrap = styled.div`
  max-width: 750px;
  height: auto;
  div {
    font-family: IBM Plex Sans;
  }

  .pills {
    position: sticky;
    top: 1.7rem;
    background-color: white;
    padding-top: 2%;
  }

  [role='alert'] {
    box-shadow: none;
  }

  .warning.with-details {
    div {
      font-size: 14px;
    }
  }
`;
const ImageChipWrap = styled.div`
  ${(props) =>
    props.active ? 'border: 1px solid #3246B9; background: #ECEFFF;' : 'border: 1px solid #dcdcdc; background: #fff;'}
  cursor: pointer;
  align-items: center;
  border-radius: 49px;
  margin-bottom: 16px;
  padding: 7px 8px 7px 8px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  text-align: center;
  display: inline-flex;
  &:nth-of-type(2) {
    margin-left: 8px;
  }
`;

const Text = styled.div`
  margin-left: 8px;
`;

const ImagesWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ImageBlock = styled.div`
  font-weight: 400;
  font-size: 12px;
  flex: 25%;
  max-width: 25%;
  min-width: 25%;
  @media (max-width: 992px) {
    min-width: 33%;
    max-width: 33%;
  }
  @media (max-width: 784px) {
    min-width: 50%;
    max-width: 50%;
  }
  div {
    img {
      max-width: 151px;
      max-height: 104px;
      display: inline-block;
      align-items: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      object-fit: cover;
      @media (max-width: 768px) {
        max-width: 154px;
      }
      @media (max-width: 600px) {
        max-width: 116px;
      }
    }
    div {
      font-size: 12px;
      font-weight: 400;
      color: #565656;
    }
  }
`;

const ThumbnailBind = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 4px;
`;

const ImageWrap = styled.div`
  border: 1px solid #dcdcdc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  align-items: center;
`;

const AuthorWrap = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 4px 8px 4px 8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #dcdcdc;
  border-top: 0;
`;

const NoteClearImage = styled.div`
  font-size: 14px;
  font-weight: 400;
  span {
    font-weight: 700;
  }
`;

const CopyUrl = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #3349c4;
  cursor: pointer;
`;

const SpinnerWrapper = styled.div`
  div[role='progressbar'] {
    margin: 22vh auto;
  }
`;

const NonImageContent = styled.div`
  text-align: center;
  padding-top: 1em;

  a {
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    color: #3349c4;
    border-bottom: 0;
  }
`;
export {
  ImageTabContentWrap,
  ImageChipWrap,
  Text,
  ImagesWrapper,
  ImageBlock,
  ImageWrap,
  NoteClearImage,
  CopyUrl,
  AuthorWrap,
  ThumbnailBind,
  SpinnerWrapper,
  NonImageContent,
};
