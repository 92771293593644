import styled from 'styled-components';
import React from 'react';
import { GlobalDraftIcon } from '../Icons';

const GlobalDraftTag = styled.div`
  background-color: #fdd13a;
  padding: 4px 8px;
  min-height: 0;
  border-radius: 24px;
  p {
    margin: 0;
  }
`;

const GlobalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GlobalDraftTitleTxt = styled.span`
  color: #171717;
  font-size: 14px;
  font-weight: 600;
  padding-left: 4px;
`;

const GlobalDraftTitle = (props: { title: string }): JSX.Element => {
  return (
    <>
      <GlobalDraftTag>
        <GlobalWrapper>
          <GlobalDraftIcon />
          <GlobalDraftTitleTxt>{props.title}</GlobalDraftTitleTxt>
        </GlobalWrapper>
      </GlobalDraftTag>
    </>
  );
};

export default GlobalDraftTitle;
