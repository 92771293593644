import * as React from 'react';
import { CopyButton } from './styles';
import copyToClipBoard from 'copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CopyIcon } from '@condenast/formation-styleguide/src/assets/icons/copy.svg';
import { ReactComponent as SimpleCheck } from '@condenast/formation-styleguide/src/assets/icons/simple-check.svg';

type CopyDraftProps = {
  copyText?: string;
  canSyndicate: boolean;
};

const CopyDraft = ({ copyText = '', canSyndicate }: CopyDraftProps): JSX.Element => {
  const [isCopied, setIsCopied] = React.useState(false);
  const ref = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const clickHandler = () => {
    copyToClipBoard(copyText);
    setIsCopied(true);
  };

  const mouseLeaveHandler = () => {
    if (isCopied) {
      ref.current = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    return () => {
      ref.current && clearTimeout(ref.current);
    };
  }, []);

  return (
    <CopyButton disabled={!canSyndicate} aria-label="copyDraft" onClick={clickHandler} onMouseLeave={mouseLeaveHandler}>
      {isCopied ? (
        <>
          <SimpleCheck data-testid="simple-check" />
          <span>
            <FormattedMessage
              id="create.modal.copied"
              description="Messsage to display when copy button is clicked"
              defaultMessage="Copied Text"
            />
          </span>
        </>
      ) : (
        <>
          <CopyIcon data-testid="copy-icon" />
          <span>
            <FormattedMessage id="create.modal.copy" description="Copy button copy" defaultMessage="Copy Text" />
          </span>
        </>
      )}
    </CopyButton>
  );
};

export default CopyDraft;
