import styled from 'styled-components';

const Main = styled.main``;

const Loader = styled.div``;

const SlatList = styled.ul`
  padding: 0;
  margin-top: 1.953rem;
`;

export { Main, Loader, SlatList };
