import React from 'react';
import SyndicationItem from './SyndicationItem';
import styled from 'styled-components';

type publishedSyndicaionsProps = {
  // eslint-disable-next-line
  publishedSyndicaions: any[];
};

const HistoryModal = styled.div`
  height: 222px;
  overflow-y: scroll;
  width: 291px;
  box-shadow: 0px 2px 8px rgba(43, 58, 68, 0.4);
  border-radius: 4px;
  padding: 8px 16px 16px;
  position: absolute;
  z-index: 999;
  background: #fff;
  top: 13.5rem;
`;

const SyndicationItemsModal = ({ publishedSyndicaions }: publishedSyndicaionsProps): JSX.Element => {
  return (
    <HistoryModal
      id="published-syndications-modal"
      onClick={(e: Event) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {publishedSyndicaions.map((relatedContent) => (
        <>
          <SyndicationItem key={relatedContent.marketBrandCode} {...relatedContent} />
        </>
      ))}
    </HistoryModal>
  );
};

export default SyndicationItemsModal;
