import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from '../../../../helpers/googleAnalytics';
import { RedirectToPreviewLink } from '../../../Preview';
import getUpdatedDate from '../../../../helpers/getDate';
import { versoText } from '../../../../helpers/textRenderer';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import Skeleton from 'react-loading-skeleton';

import { HorizontalSeparatorLine, AuthorDetails, Header, Body, Hed, Scroll, SyndicateModalBody } from '../../styles';
import markDownToReactUnwrapped from '../../../../helpers/mdToReactUnwrapped';
import BodyRenderer from '../../../../helpers/ReactRenderer';
import StoryImageInline from '../../StoryImageInline';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { SYNDICATION_COUNT } from '../../../../helpers/flags';

const Description = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
interface DraftPreviewSlatType {
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  canSyndicate: boolean;
  updatedAt: string;
  marketBrand: { name: string };
  type: string;
  language: string;
  sourceUri: string;
}
type DraftviewProptypes = {
  slatData: DraftPreviewSlatType;
  marketBrand: string;
};

const DraftPreviewV1 = ({ slatData, marketBrand }: DraftviewProptypes): JSX.Element => {
  const {
    state: { draftView },
  } = useGlobalStateValue();
  const { authors, updatedAt } = slatData;
  const { l10n, targetLang, translationStatus } = draftView;
  const previewData = l10n[targetLang] || slatData;
  const { body } = previewData;
  const hed = previewData.hed ? markDownToReactUnwrapped(previewData.hed) : '';
  // it is description from slatData and dek from the translation service
  const description = previewData?.dek || previewData?.description;
  const bodyText = versoText(body);
  const navigationClickHandler = () => {
    window.open(slatData.publishUri, '_blank', 'noopener,noreferrer');
    trackEvent('Syndicate slat', 'Clicked entity');
  };
  const translating = translationStatus === 'pending';
  const syndicationCountEnabled = useFeatureToggle(SYNDICATION_COUNT);
  return (
    <SyndicateModalBody>
      <Header>
        <RedirectToPreviewLink name={marketBrand} onClick={navigationClickHandler} isNameDisabled={false} />
      </Header>
      <Scroll>
        <AuthorDetails>
          {authors && authors.map((author) => <span key={author}>{author}</span>)}
          {getUpdatedDate(updatedAt)}
        </AuthorDetails>
        <Hed>{translating ? <Skeleton count={2} /> : hed}</Hed>
        <Description>{translating ? <Skeleton count={2} /> : description}</Description>
        {!syndicationCountEnabled && <HorizontalSeparatorLine />}
        {syndicationCountEnabled && <StoryImageInline slatData={slatData} />}
        <Body>{translating ? <Skeleton count={10} /> : <BodyRenderer body={bodyText} />}</Body>
      </Scroll>
    </SyndicateModalBody>
  );
};

DraftPreviewV1.propTypes = {
  slatData: PropTypes.object.isRequired,
};

export default DraftPreviewV1;
