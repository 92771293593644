import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ModalPortalPropTypes = {
  children: ReactNode;
};

const ModalPortal = ({ children }: ModalPortalPropTypes): JSX.Element => createPortal(children, document.body);

export default ModalPortal;
