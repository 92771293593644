import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import CautionIcon from '../../Icons/Caution';
import { Image, TopActions } from '../styles';

const WarningToastWrapper = styled.div`
  align-self: stretch;
  height: 48px;

  /* yellow/yellow 80 */
  background: #fffaeb;

  border-left: 4px #fdd13a solid;
  border-radius: 4px;
`;

const WarningToast = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  /* border left */
  height: 48px;
  .cautionIcon path {
    fill: #8a7428;
  }
`;

const WarningToastText = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* gray/gray 1 */
  color: #171717;
`;

interface ImageSlatType {
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  marketBrand: { name: string };
  canSyndicate: boolean;
  language: string;
  type: string;
  sourceUri: string;
}

type StoryImageSlatpropType = {
  slatData?: ImageSlatType;
};
const StoryImageInline = ({ slatData }: StoryImageSlatpropType): JSX.Element => {
  const intl = useIntl();
  return (
    <TopActions>
      <Image height={20.375}>
        <img
          alt={intl.formatMessage({
            id: 'storyimageinline.image.alt',
            defaultMessage: 'Preview',
            description: 'Text to display if image not loaded',
          })}
          className="thumbnailImage"
          src={slatData?.promoImageUri || '/image-placeholder.png'}
        />
      </Image>
      <WarningToastWrapper>
        <WarningToast>
          <CautionIcon className="cautionIcon" />
          <WarningToastText>
            <FormattedMessage
              id="create.modal.imageUsageWarning"
              description="Warning Check Image Usage Rights"
              defaultMessage="Check Image Usage Rights"
            />
          </WarningToastText>
        </WarningToast>
      </WarningToastWrapper>
    </TopActions>
  );
};

export default StoryImageInline;
