import React from 'react';
import { ReactComponent as SpinnerSVG } from '@condenast/formation-styleguide/src/assets/spinners/formation-spinner.svg';

type spinnerPropType = {
  [key: string]: string;
};
const Spinner = ({ size = 'small' }: spinnerPropType): JSX.Element => (
  <div className={`spinner--group spinners--${size}`}>
    <SpinnerSVG className="spinner__color" />
    <SpinnerSVG className="spinner__color--counterspin" />
  </div>
);

export default Spinner;
