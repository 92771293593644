import React from 'react';
import { Layout } from '@condenast/formation-ui-components';
import Nav from '../HeaderNavBar/Nav';
import PageContentWrap from '../PageContentWrapper';
import NotFound from '../NotFound';

const NotFoundPage = (): JSX.Element => {
  return (
    <Layout layoutType="topnav" className="not-found-page">
      <Nav slotKey="appNav" />
      <PageContentWrap slotKey="pageContent">
        <NotFound />
      </PageContentWrap>
    </Layout>
  );
};

export default NotFoundPage;
