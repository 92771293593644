import React from 'react';
import { Helmet } from 'react-helmet';
import SyndicationToast from './SyndicationToast';

export interface previewType {
  type: string;
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  marketBrand?: { name: string };
  canSyndicate: boolean;
}

export type PreviewPropTypes = {
  data: previewType;
};

const Preview = ({ data }: PreviewPropTypes): JSX.Element => (
  <>
    <Helmet>
      <title>Encore - {data.hed}</title>
    </Helmet>
    <SyndicationToast />
    <iframe
      className="preview-iframe"
      title={data.hed}
      src={`${data.publishUri}?google_nofetch`}
      data-cy="preview-iframe"
      data-testid="preview-iframe"
    ></iframe>
  </>
);

export default Preview;
