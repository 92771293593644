import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import { contentArticlePropType } from '../PropTypesShapes';
import SlatType from './SlatType';
import mdToReactUnwrapped from '../../helpers/mdToReactUnwrapped';
import { trackEvent } from '../../helpers/googleAnalytics';
import SyndicateButton from '../SyndicateButton/SyndicateButton';
import { contentSlatType, publicationDate } from './util/helper';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

type contentSlatprops = {
  slatData: contentSlatType;
};
const Slat = ({ slatData }: contentSlatprops) => {
  const timeSincePublication = publicationDate(slatData.isGlobalDraft ? slatData.updatedAt : slatData.publishDate);

  const trackSlatData = () => {
    window.open(slatData.publishUri, '_blank', 'noopener,noreferrer');
    trackEvent('Syndicate slat', 'Clicked entity');

    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'encore_slat',
      label: 'syndication_story_view',
      type: 'click',
    });
  };

  return (
    <>
      {/*eslint-disable */}
      <li className="slat" data-cy="content-slat" data-testid="slat" onClick={trackSlatData}>
        {/*eslint-enable */}
        <div className="slat--thumbnail thumbnail">
          <img
            alt="Preview"
            className="thumbnailImage"
            src={slatData.promoImageUri ? slatData.promoImageUri : '/image-placeholder.png'}
          />
        </div>
        <div className="slat--content slatContent">
          <span className={classNames('brand-market-name', { disabled: !slatData.canSyndicate })}>
            {slatData.marketBrand.name}
          </span>
          <div className={classNames('slat--title', { disabled: !slatData.canSyndicate })}>
            <Dotdotdot clamp={2}>{mdToReactUnwrapped(slatData.hed)}</Dotdotdot>
          </div>
          <div>
            <SlatType type={slatData.type} />
            <span className="slat--details slatDetails">{timeSincePublication}</span>
            {slatData.authors.map((item) => (
              /*eslint-disable */
              <span
                className="smallMarginLeft"
                key={item}
                onClick={() => trackEvent('Syndicate slat', 'Clicked author name')}
              >
                {item}
              </span>
              /*eslint-enable */
            ))}
          </div>
        </div>
        <div className="slat--actions">
          {/*eslint-disable */}
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SyndicateButton trackingCategory="Syndicate slat" content={slatData} disabled={!slatData.canSyndicate} />
          </span>
          {/*eslint-enable */}
        </div>
      </li>
    </>
  );
};

Slat.propTypes = {
  slatData: contentArticlePropType.isRequired,
};

export default React.memo(Slat);
