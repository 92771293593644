import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ReactRenderer, { ReactRendererProvider } from '@atjson/renderer-react';
import VersoSource from '@condenast/atjson-source-verso/dist/commonjs/source';

type ReactRendererProp = {
  url: string;
  title: string;
  children: ReactNode;
};

const StyledAnchor = styled.a`
  && {
    font-weight: 500;
    border-bottom: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

const components = {
  Bold: styled.strong`
    font-weight: bold;
  `,
  Italic: styled.em`
    font-style: italic;
  `,

  Paragraph: styled.p``,

  SmallCaps: styled.span`
    font-variant: all-small-caps;
  `,

  Strikethrough: styled.span`
    text-decoration: line-through;
  `,

  Subscript: styled.sub``,

  Superscript: styled.sup``,

  LineBreak: styled.br``,

  Link: (props: ReactRendererProp) => {
    return (
      <StyledAnchor href={props.url} target="__blank" rel="noreferrer noopener" title={props.title}>
        {props.children}
      </StyledAnchor>
    );
  },
  Heading: styled.h2`
    font-size: 24px;
    line-height: 1.25em;
    font-weight: 500;
    margin: 0.5rem 0 1rem 0;
    a {
      color: #171717;
    }
  `,
};

const Wrapper = styled.div``;

type Body = {
  body: VersoSource;
};

const BodyRenderer = ({ body }: Body) => {
  const ReactText = () => {
    try {
      body.annotations = body.annotations.filter(
        (annotation: { type: string }) => annotation.type !== '-verso-callout'
      );
      return ReactRenderer.render(body);
    } catch (error) {
      //do nothing
    }
  };

  return (
    <Wrapper>
      <ReactRendererProvider value={components}>{ReactText()}</ReactRendererProvider>
    </Wrapper>
  );
};

export default React.memo(BodyRenderer);
