import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { auth } from '../components/App/Security';

type Level = CaptureContext | undefined;

type Message = {
  level: Level;
  message: string;
};

type User = {
  email: string;
  name: string;
};

export interface SentryOptions {
  tags?: {
    [key: string]: string;
  };
  extraData?: {
    [key: string]: string | boolean;
  };
  captureMessage?: Message;
  exceptionMessage?: Message;
}

/**
 * Set custom tracking events for sentry
 * e.g tags, user context, extra data, exception, message
 * @param tags Optional
 * @param extraData Optional
 * @param captureMessage Optional
 * @param exceptionMessage Optional
 * Usage: sentryTrack({
 *   tags: { key: value },
 *   extraData: { key: value },
 *   captureMessage: {
 *       level: 'info',
 *       message: 'Encore-Error-info: Modal Syndicate'
 *   },
 *   exceptionMessage: {
 *        level: 'error',
 *        message: 'Encore-Error-info: Modal Syndicate'
 *    }
 *  })
 */

export function sentryTrack(options: SentryOptions): void {
  const getUser = async () => {
    let user: User = {} as User;
    const isAuthenticated = await auth.isAuthenticated();
    if (isAuthenticated) {
      try {
        user = await auth.getUser();
      } catch (e) {
        throw (e as Error)?.message;
      }
    }
    return user;
  };

  Sentry.withScope(async (scope) => {
    const user = await getUser();
    if (user && user.email) {
      const { email, name } = user;
      scope.setUser({ email, name });
    }
    /** set tags */
    options?.tags && Object.entries(options?.tags).map(([key, value]) => scope.setTag(key, value));

    /** set extra Data */
    options?.extraData && Object.entries(options?.extraData).map(([key, value]) => scope.setExtra(key, value));
    /*
    
    /** set capture Message */
    options?.captureMessage && Sentry.captureMessage(options.captureMessage.message, options.captureMessage.level);

    /** set exception Message */
    options?.exceptionMessage &&
      Sentry.captureException(options.exceptionMessage.message, options.exceptionMessage.level);
  });
}
