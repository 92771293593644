import React from 'react';
import PropTypes from 'prop-types';

type TooltipPropsType = {
  className: string;
  message: string;
};

const Tooltip = ({ className, message }: TooltipPropsType): JSX.Element => (
  <div data-testid="tooltip" className={`tooltip ${className || ''}`}>
    {message}
  </div>
);

Tooltip.prototype = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default Tooltip;
