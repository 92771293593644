import React, { memo, ReactNode } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

type loadingSkeletonPropTypes = {
  color?: string;
  highlightColor?: string;
  children?: ReactNode;
};
const LoadingSkeleton = ({
  color = '#D3D2D3',
  highlightColor = '#DFDFDF',
  children,
}: loadingSkeletonPropTypes): JSX.Element => (
  <SkeletonTheme color={color} highlightColor={highlightColor}>
    {children}
  </SkeletonTheme>
);

export default memo(LoadingSkeleton);
