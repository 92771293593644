import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { isProd } from '../helpers/isProdCheck';
import { UserType } from '../components/App/AuthContext';

export const useIntercomBoot = (user: UserType): void => {
  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    if (user && isProd()) {
      boot({
        userId: user.email,
        email: user.email,
        name: user.name,
        customAttributes: {
          preferred_username: user.preferred_username,
          name: user.name,
        },
      });
    }
    return () => shutdown();
  }, [user, boot, shutdown]);
};
