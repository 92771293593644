import React from 'react';
import { HelpIcon } from '@condenast/platform-icons';

import { trackEvent } from '../../helpers/googleAnalytics';
import Modal from '../Modal';
import useToggle from '../../hooks/useToggle';
import {
  HelpButton,
  HelpHeader,
  BorderBottom,
  ResourceHeader,
  ResourceDescription,
  SectionWrap,
  HeadWrap,
} from './styles';
import RedirectToPreviewLink from '../Preview/RedirectToPreviewLink';
const helpModalData = require('../../utils/help-modal-data.json');
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

const HelpResource = () => {
  const [isOpen, toggle] = useToggle(false);

  const handleOnClick = () => {
    toggle();
    trackEvent('Nav bar', 'Syndication Notice Button Clicked');
    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'help_resource',
      label: 'help_encore_resources',
      type: 'click',
    });
  };

  return (
    <>
      <HelpButton data-cy="help-button" aria-label="Help Button" data-testid="help-button" onClick={handleOnClick}>
        <HelpIcon size={'regular'} />
      </HelpButton>
      <Modal toggle={toggle} isOpen={isOpen} isCentered size="md" style={{ 'overflow-y': 'auto', 'max-height': '90%' }}>
        <HeadWrap>
          <Modal.Header closeButton>
            <HelpHeader>Encore Resources</HelpHeader>
          </Modal.Header>
        </HeadWrap>
        <Modal.Body style={{ 'overflow-y': 'auto;' }}>
          {helpModalData.map((info) => {
            return (
              <>
                <SectionWrap>
                  <ResourceHeader data-cy={info.datacy}>{info.head}</ResourceHeader>
                  <ResourceDescription>{info.description}</ResourceDescription>
                  {info.hrefLink ? (
                    <RedirectToPreviewLink
                      key={info.hrefDescription}
                      name={info.hrefDescription}
                      onClick={() => {
                        window.open(info.hrefLink, '_blank', 'noopener,noreferrer');
                      }}
                      isNameDisabled={false}
                      fontWeight={'700'}
                      textTransformation={'none'}
                      fontSize={'14px'}
                    />
                  ) : (
                    ''
                  )}
                </SectionWrap>
                {info.hrefLink ? <BorderBottom /> : ''}
              </>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpResource;
