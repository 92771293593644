import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Header = styled.h4`
  margin: 0;
  color: #171717;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;
type alertHeaderPropTypes = {
  children?: ReactNode;
};
const AlertHeader = ({ children }: alertHeaderPropTypes): JSX.Element => (
  <>{!!children && <Header>{children}</Header>}</>
);

export default AlertHeader;
