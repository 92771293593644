import styled from 'styled-components';

const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: 0;
  }
`;

export default Button;
