import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import DiscoveryList from '../DiscoveryList/DiscoveryList';
import Alert from '../Alert';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import useApi from '../../hooks/useApi';
import { setGlobalDraftsTargetBrands } from '../../actions/globalDraft';
import { setMarketBrands } from '../../actions/draftView';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

const GlobalDraftsLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  margin-bottom: 36px;
  input[type='checkbox'] + label {
    font-weight: 700;
  }
`;

const FILTERS = {
  BRANDS: 'brands',
  DISPLAY: 'display',
};

const getNotificationDetails = (selectedBrand: string) => {
  return {
    title: 'Warning',
    body: `Stories listed here are unpublished drafts for use by ${selectedBrand} websites. This content is not currently eligible for general syndication. Please contact the ${selectedBrand} Content Manager for adaptation and publishing details.`,
  };
};

const GloablDrafts = () => {
  const [marketBrandUrl, setMarketBrandUrl] = useState('');
  const {
    state: {
      globalDraft: { selectedGlobalDraft },
    },
    dispatch,
  } = useGlobalStateValue();
  const getParam = (param: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramString = param == 'brands' && selectedGlobalDraft ? selectedGlobalDraft?.value : urlParams.get(param);
    return paramString?.split(',') || [];
  };
  const getAllParam = () => {
    // eslint-disable-next-line
    const paramsAsObj: any = {};
    Object.values(FILTERS).forEach((name) => {
      paramsAsObj[name] = getParam(name);
    });
    return paramsAsObj;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data }: any = useApi('get', marketBrandUrl);
  const parsedParams = getAllParam();
  const { title, body } = getNotificationDetails(selectedGlobalDraft.label);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (data) {
      if (selectedGlobalDraft.value) {
        dispatch && dispatch(setGlobalDraftsTargetBrands(data?.data));
      } else {
        dispatch && dispatch(setMarketBrands(data));
      }
    }
  }, [data]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setMarketBrandUrl(`/api/v1/market-brands?brand=${selectedGlobalDraft.value}`);
    trackCustomSnowplowActions(ActionType.EncoreContent, {
      target_brand: '',
      source_brand: selectedGlobalDraft.value,
      source_id: '',
      target_id: null,
      content_type: 'global_drafts',
      source_lang: '',
      target_lang: '',
    });
  }, [selectedGlobalDraft]);

  return (
    <>
      {selectedGlobalDraft?.label && (
        <>
          <GlobalDraftsLabelWrapper>{selectedGlobalDraft.label} Shared Drafts</GlobalDraftsLabelWrapper>
          <Alert open={true}>
            <Alert.Header>{title}</Alert.Header>
            <Alert.Body>{body}</Alert.Body>
          </Alert>
          <DiscoveryList {...parsedParams} key={parsedParams[FILTERS.BRANDS]} isGlobalDraft={true} />
        </>
      )}
    </>
  );
};

export default withRouter(GloablDrafts);
