import React, { ReactNode, useEffect } from 'react';
import ModalPortal from './ModalPortal';
import { ModalWrapper, ModalOverlay, ModalContent } from './styles';
import ModalHeader from './Modal.Header';
import ModalBody from './Modal.Body';
import ModalFooter from './Modal.Footer';
import { ModalCloseButton } from './styles';
type ModalPropTypes = {
  children?: ReactNode;
  toggle: () => void;
  isCentered?: boolean;
  isOpen: boolean;
  onClose: () => void;
  size?: string;
  style?: React.CSSProperties;
};
type EventPropType = {
  stopPropagation: () => void;
  charCode?: string;
  keyCode?: number;
};
const Modal = ({
  children,
  toggle,
  isCentered = false,
  isOpen = false,
  onClose,
  size,
  style,
}: ModalPropTypes): JSX.Element => {
  const handleCloseModal = (event: EventPropType) => {
    event.stopPropagation();
    if (!toggle) return;
    if (typeof toggle === 'function') toggle();
    if (typeof onClose === 'function') onClose();
  };

  // eslint-disable-next-line
  const handleOnEsc = (event: EventPropType) => {
    event.stopPropagation();
    if (event.charCode || (event.keyCode === 27 && isOpen)) handleCloseModal(event);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleOnEsc);
    return () => document.removeEventListener('keydown', handleOnEsc);
  }, [handleOnEsc]);

  useEffect(() => {
    const closeButton = document.querySelector(ModalCloseButton);
    !!closeButton && closeButton.addEventListener('click', handleCloseModal);
    return () => !!closeButton && closeButton.removeEventListener('click', handleCloseModal);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <ModalPortal>
      {isOpen && (
        <ModalWrapper data-testid="modal" data-cy="modal" isCentered={isCentered}>
          <ModalContent style={style} size={size}>
            {children}
          </ModalContent>
          <ModalOverlay data-testid="modal-overlay" data-cy="modal-overlay" onClick={handleCloseModal} />
        </ModalWrapper>
      )}
    </ModalPortal>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
