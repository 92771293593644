import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import getUpdatedDate from '../../../../helpers/getDate';
import { useGlobalStateValue } from '../../../../hooks/useGlobalContext';
import Skeleton from 'react-loading-skeleton';

import { HorizontalSeparatorLine, AuthorDetails, Hed, Scroll } from '../../styles';
import markDownToReactUnwrapped from '../../../../helpers/mdToReactUnwrapped';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { RIGHTS_PHASE_ONE, SYNDICATION_COUNT } from '../../../../helpers/flags';
import DraftPreviewTablist from '../DraftPreviewTablist';
import DraftPreviewToast from './DraftPreviewToast';
import { ImageWrapper } from './styles';

const Description = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}
`;

const TextMeta = styled.div`
  width: 100%;
`;

const SyndicateModalBody = styled.div`
  height: 85vh;
  overflow: hidden;
  width: calc(2 / 3 * 100%);
`;

const Meta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ThumbnailImage = styled.img`
  border-radius: 4px;
  width: inherit;
  object-fit: contain;
`;
interface DraftPreviewSlatType {
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  canSyndicate: boolean;
  updatedAt: string;
  marketBrand: { name: string };
  type: string;
  language: string;
  sourceUri: string;
}
type DraftviewProptypes = {
  slatData: DraftPreviewSlatType;
  marketBrand: string;
};

const DraftPreviewWithRights = ({ slatData }: DraftviewProptypes): JSX.Element => {
  const intl = useIntl();
  const {
    state: { draftView },
  } = useGlobalStateValue();
  const { authors, updatedAt } = slatData;
  const { l10n, targetLang, translationStatus } = draftView;
  const previewData = l10n[targetLang] || slatData;
  const hed = previewData.hed ? markDownToReactUnwrapped(previewData.hed) : '';
  // it is description from slatData and dek from the translation service
  const description = previewData?.dek || previewData?.description;
  const translating = translationStatus === 'pending';
  const syndicationCountEnabled = useFeatureToggle(SYNDICATION_COUNT);
  const rightsPhaseOneEnabled = useFeatureToggle(RIGHTS_PHASE_ONE);
  return (
    <SyndicateModalBody style={{ padding: 'unset' }}>
      <Scroll rightsPhaseOneEnabled={rightsPhaseOneEnabled}>
        <Meta>
          <TextMeta>
            <AuthorDetails rightsPhaseOneEnabled={rightsPhaseOneEnabled}>
              {authors && authors.map((author) => <span key={author}>{author}</span>)}
              {getUpdatedDate(updatedAt)}
            </AuthorDetails>
            <Hed rightsPhaseOneEnabled={rightsPhaseOneEnabled}>{translating ? <Skeleton count={2} /> : hed}</Hed>
            <Description>{translating ? <Skeleton count={2} /> : description}</Description>
          </TextMeta>
          <ImageWrapper>
            <ThumbnailImage
              alt={intl.formatMessage({
                id: 'storythumbline.image.alt',
                defaultMessage: 'Preview',
                description: 'Text to display if thumbnail image is not loaded',
              })}
              src={slatData?.promoImageUri || '/image-placeholder.png'}
            />
          </ImageWrapper>
        </Meta>
        {!syndicationCountEnabled && <HorizontalSeparatorLine />}
        <DraftPreviewTablist />
        <DraftPreviewToast />
      </Scroll>
    </SyndicateModalBody>
  );
};

DraftPreviewWithRights.propTypes = {
  slatData: PropTypes.object.isRequired,
};

export default DraftPreviewWithRights;
