import React, { useEffect, useState } from 'react';
import { Table, ThemeProvider, CSSVariables, IntlProvider, Link } from '@condenast/gemini';
import styled from 'styled-components';
import useApi from '../../../../hooks/useApi';
import { withUser } from '../../../App/AuthContext';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { relsType, relType } from '../../types';
import { useIntl } from 'react-intl';
import defaultLanguageResource from '../../../../../src/translations/en.json';
import { RedirectIcon } from '@condenast/gemini/icons';
import { ContentsResponseType } from '../../../../types';

const PublishedContentWrap = styled.div`
  padding-top: 16px;
  [role='rowgroup'] {
    font-size: 14px;
    background: var(--gray-gray-6, #f3f3f3);
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
  }
  [role="columnheader"]: nth-child(2) {
    justify-content: center;
  }
  border-radius: 4px;
`;

const LanguageLabel = styled.span`
  border-radius: 4px;
  border: 1px solid var(--gray-gray-4, #8b8b8b);
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 6px;
  gap: 4px;
  text-wrap: nowrap;
`;

const BrandName = styled.span`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
  word-break: break-all;
  min-width: 73px;
`;

const RedirectToPreviewLink = styled.span`
  display: flex;
  align-items: center;
  color: blue;
  cursor: pointer;

  a {
    color: blue;
    margin-right: 4px;
    font-size: 14px;
  }
`;

const PublishDetails = styled.span`
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  padding: 8px 0px;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  word-break: break-all;
`;

const Name = styled(Table.Cell)`
  justify-content: center;
`;

const DestinationDetailsWrapper = styled(Table.Cell)`
  display: flex;
`;

const PublishDateWrapper = styled(Table.Cell)`
  justify-content: center;
`;

type PublishSyndicationPropTypes = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
  onDataUpdate: (data: Array<relType>) => void;
};

const PublishedSyndications = ({ match, history, onDataUpdate }: PublishSyndicationPropTypes): JSX.Element => {
  const intl = useIntl();
  const [data, setData] = useState<null | relsType>(null);
  const contentId = match?.params?.id;
  const relatedContents = data?.rels?.filter(({ id }) => id !== contentId);
  const isGlobalDraft = history.location.pathname.startsWith('/globalDrafts');
  const { data: reslsData } = useApi('get', `/api/v1/contents/${contentId}/rels?publishStatus=published`);
  const { data: slat } = useApi<ContentsResponseType>('get', `/api/v1/contents/markets?q=${contentId}`);
  const slatData = slat?.data?.[0];
  useEffect(() => {
    if (reslsData !== null) {
      setData(reslsData as relsType);
    }
    if (relatedContents !== null) {
      onDataUpdate(relatedContents as Array<relType>);
    }
  }, [reslsData, relatedContents, onDataUpdate]);

  const formatDate = (publishDate: string) => {
    const dateObject = new Date(publishDate);
    const day = intl.formatDate(dateObject, { day: 'numeric' });
    const month = intl.formatDate(dateObject, { month: 'short' });
    const year = intl.formatDate(dateObject, { year: 'numeric' });
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <ThemeProvider theme="light">
        <IntlProvider>
          <CSSVariables />
          <PublishedContentWrap>
            {relatedContents && relatedContents.length > 0 ? (
              <Table gridTemplateColumns="3fr 2fr 1fr">
                <Table.Head>
                  <Table.Row>
                    <Table.Header>Destination</Table.Header>
                    <Table.Header>Publish Date</Table.Header>
                    <Table.Header></Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {relatedContents?.map((rel, index) => (
                    <Table.Row key={index}>
                      <DestinationDetailsWrapper>
                        <BrandName>{rel.marketBrand.name}</BrandName>
                        <LanguageLabel>
                          {defaultLanguageResource[rel.language as keyof typeof defaultLanguageResource] ||
                            rel.language}
                        </LanguageLabel>
                      </DestinationDetailsWrapper>
                      <PublishDateWrapper>
                        <PublishDetails>{formatDate(rel.publishDate)}</PublishDetails>
                      </PublishDateWrapper>
                      <Name>
                        <RedirectToPreviewLink
                          onClick={() => window.open(rel.publishUri, '_blank', 'noopener noreferrer')}
                        >
                          <Link to={rel.publishUri} target="_blank" rel="noopener noreferrer">
                            View
                          </Link>
                          <RedirectIcon size="small"></RedirectIcon>
                        </RedirectToPreviewLink>
                      </Name>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div>
                <p>
                  {`This ${isGlobalDraft ? 'global draft' : slatData?.type} does not have any published syndications`}
                </p>
              </div>
            )}
          </PublishedContentWrap>
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};

export default withRouter(withUser(PublishedSyndications));
