import styled from 'styled-components';

const CopyButton = styled.button`
  margin-bottom: 1rem;
  color: #3349c4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21rem;
  margin-top: 0.5rem;
  border: 1px solid #dcdcdc;
  background: #fff;
  border-radius: 4px;
  :hover {
    border: ${(props) => !props.disabled && '1px solid #3953e7'};
  }
  :focus {
    box-shadow: ${(props) => !props.disabled && '0px 0px 0px 2px rgba(61, 90, 254, 0.4)'};
  }
  :active {
    background: ${(props) => !props.disabled && '#ecefff'};
    border: ${(props) => !props.disabled && '2px solid #3953e7'};
  }
  svg {
    fill: #3349c4;
    margin-right: 0.5rem;
  }
`;

export { CopyButton };
