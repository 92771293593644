import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon } from '../Icons';
import { SelectWrapper, SelectDropdownWrapper, SelectUlWrapper, SelectLiWrapper, SelectDropdownButton } from './styles';
import SelectTitle from './Select.Title';

type ValueType = {
  value: string;
  label: string;
};

type SelectDataType = {
  value?: string | number;
  options: Array<ValueType> | undefined;
  onChange: (value: ValueType) => void;
  children?: JSX.Element;
  type?: string;
};

const Select = ({ value: defaultValue = '', options, onChange, children, type }: SelectDataType): JSX.Element => {
  const ref = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<ValueType>({ value: '', label: '' });
  const [isOutBottom, setIsOutBottom] = useState(false);

  const handleClick = (ev: Event): void => {
    if (ref.current && !ref.current.contains(ev.target as Element)) setOpen(false);
  };

  const handleOnClick = (value: ValueType) => {
    onChange && onChange(value);
    setSelectedOption(value);
    setOpen(false);
  };

  const handleButtonOnClick = () => setOpen(!isOpen);

  useEffect(() => {
    /*eslint-disable */
    /** Check if the bottom has enough space to render the dropdown panel */
    const handleWindowResize = () => {
      const bottom: number | null = buttonRef.current && buttonRef.current.getBoundingClientRect().bottom;
      const belowHeight = bottom ? window.innerHeight - bottom : window.innerHeight;
      const isOutOfViewport = belowHeight < (window.innerHeight * 33) / 100;
      if (!!isOutOfViewport) {
        setIsOutBottom(isOutOfViewport);
      } else {
        setIsOutBottom(false);
      }
    };
    handleWindowResize();
    document.addEventListener('mousedown', handleClick, false);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    /*eslint-disable */
    const findDefaultOption = options && options.find(({ value }) => value === defaultValue);
    findDefaultOption && setSelectedOption(findDefaultOption);
  }, [options, defaultValue]);
  /*eslint-enable */

  return (
    <SelectWrapper ref={ref}>
      <SelectDropdownButton ref={buttonRef} aria-expanded={isOpen} onClick={handleButtonOnClick} data-cy={type}>
        {!!children && !defaultValue ? children : selectedOption.label}
        <ChevronDownIcon />
      </SelectDropdownButton>
      <SelectDropdownWrapper isOpen={isOpen} ref={listRef} isOutBottom={isOutBottom}>
        <SelectUlWrapper>
          {options?.map((option) => (
            <SelectLiWrapper
              tabIndex={0}
              key={option.value}
              role="option"
              aria-selected="false"
              onClick={() => handleOnClick(option)}
            >
              {option.label}
            </SelectLiWrapper>
          ))}
        </SelectUlWrapper>
      </SelectDropdownWrapper>
    </SelectWrapper>
  );
};

Select.Title = SelectTitle;

export default Select;
