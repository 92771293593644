import CopilotMarkdownSource from '@condenast/atjson-source-copilot-markdown';
import PlainTextRenderer from '@atjson/renderer-plain-text';
import VersoSource from '@condenast/atjson-source-verso';

const versoText = (body: string): VersoSource => {
  if (typeof body !== 'string') {
    body = '';
  }
  const documentBody = CopilotMarkdownSource.fromRaw(body).convertTo(VersoSource);
  documentBody.annotations = documentBody.annotations.filter(
    (annotation: { type: string }) => annotation.type !== 'unknown'
  );
  return documentBody;
};

const plainTextRenderer = (body: string): string => {
  if (typeof body !== 'string') {
    body = '';
  }
  const documentBody = CopilotMarkdownSource.fromRaw(body);
  documentBody.annotations = documentBody.annotations.filter((annotation) => annotation.type !== 'unknown');
  return PlainTextRenderer.render(documentBody);
};

export { versoText, plainTextRenderer };
