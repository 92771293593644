import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Select from '../Select/Select';
import { languages } from '../../languagePack';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import defaultLanguageResource from '../../translations/en.json';
import { LanguageIcon } from '../Icons';
import { setLocale, setTargetLang } from '../../actions/locale';
import { COOKIE_TARGET_LANG } from '../../constants/cookie-constants';
import { getCookie, setCookie } from '../../utils/cookies';

type LanguageSelectorPropTypes = {
  value: string;
};
interface defaultLanguageResourceType {
  'locales.default': string;
  'locales.de-DE': string;
  'locales.en': string;
  'locales.en-US': string;
  'locales.es-ES': string;
  'locales.fr-FR': string;
  'locales.it-IT': string;
  'locales.ja-JP': string;
  'locales.ru-RU': string;
  'locales.zh-Hant-TW': string;
  [key: string]: string;
}
const LanguageSelectorWrapper = styled.div`
  min-width: 380px;
`;
const LanguageSelector = (): JSX.Element => {
  const [languageOptionsObject, setLanguageOptionsObject] = useState([]);
  const {
    state: {
      locales: { defaultLocale, targetLang },
    },
    dispatch,
  } = useGlobalStateValue();
  const handleOnChange = ({ value }: LanguageSelectorPropTypes): void => {
    dispatch && dispatch(setLocale(value || defaultLocale));
    dispatch && dispatch(setTargetLang(value));
    setCookie(COOKIE_TARGET_LANG, value, 365);
  };
  const customOption = ({ value }: LanguageSelectorPropTypes) => {
    const props = {
      id: value ? `locales.${value}` : `locales.default`,
      description: 'Locale language selection options',
      defaultMessage: (defaultLanguageResource as defaultLanguageResourceType)[`locales.${value}`],
    };
    return <FormattedMessage {...props} />;
  };
  useEffect(() => {
    if (getCookie(COOKIE_TARGET_LANG) === null) {
      setCookie(COOKIE_TARGET_LANG, '', 365);
    }
    // eslint-disable-next-line
    const options: any = languages.map((lang) => ({
      value: lang.value,
      label: customOption(lang),
    }));
    setLanguageOptionsObject(options);
  }, []);
  return (
    <>
      <LanguageIcon />
      <LanguageSelectorWrapper data-testid="translation-dropdown">
        <Select value={targetLang} options={languageOptionsObject} onChange={handleOnChange} />
      </LanguageSelectorWrapper>
    </>
  );
};
export default LanguageSelector;
