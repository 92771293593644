import React, { useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';

import { auth } from '../components/App/Security';
import { trackCustomSnowplowActions, ActionType } from '../analytics/snowplow';
import { convertToSnakeCase } from '../utils/commonMethods';
/**
 * A custom hook to make post, put, delete api calls using axios
 * @param {*} url
 * @param {*} opts
 * Usage for get calls
 * const { callApi, data, loading, status, error } = useApi('get', 'url')
 * callApi - callback function to invoke the api(useful in any events operations)
 * data - api response
 * loading - a boolean to show laoding state
 * statu - api status value
 * error - error object
 * Usage for Post calls
 * const { callApi } = useApi('post', 'url')
 * In event methods-
 * e.g const mymethod = async () => {
 *   const data = {
 *     firstName: 'Dota'
 *   }
 *   const config = {
 *    data,
 *    url: 'url' // optional
 *    headers: {} // optional
 *   }
 *   const response =  await = useAPi(config)
 *   response contains same as get calls except callApi callback { data, loading, status, error }
 * }
 */

interface ResponseType<D> {
  data: D | null;
  status: number | null | undefined;
  error: Error | null;
}

const useApi = <T,>(
  method: string,
  url: string | null
): {
  callApi: (o?: T) => Promise<ResponseType<T>>;
  data: T | null;
  loading: boolean;
  status: number | null | undefined;
  error: Error | null;
} => {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState<number | null | undefined>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  let unmounted = false;

  // eslint-disable-next-line
  const callApi = async (opts: any = {}): Promise<ResponseType<T>> => {
    const response: ResponseType<T> = {
      data: null,
      status: null,
      error: null,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${await auth.getAccessToken()}`,
        'User-Agent': 'Encore-web-app',
      },
      ...opts,
    };
    setLoading(true);
    try {
      const { data, status } = await axios({ method, url, ...config });
      if (!unmounted) {
        setData(data);
        setStatus(status);
        setLoading(false);
        response.data = data;
        response.status = status;
      }
    } catch (error) {
      if (!unmounted) {
        const err = error as AxiosError;
        setError(err);
        setLoading(false);
        response.status = err?.response?.status;
        response.error = err;
        trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
          component: 'encore_error',
          label: convertToSnakeCase(err?.response?.data?.error),
          type: 'error',
        });
      }
    }
    return response;
  };

  const fetchData = useCallback(callApi, [method, unmounted, url]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (method === 'get') url && fetchData();
    return () => {
      unmounted = true;
    };
  }, [fetchData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return { callApi, data, loading, status, error };
};

export { useApi };

export default useApi;
