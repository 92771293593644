import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ElementType, useKeyboardNavigation } from '../../hooks/useKeyboardNavigation';

type TabListProps = {
  tabs: {
    id: string;
    Label: ReactNode | string;
    PanelContent?: ReactNode | string;
    onClick?: () => void;
    default?: boolean;
  }[];
  tabListStyle?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  tabPanelStyle?: React.CSSProperties;
  activeTab?: string;
};

const Tab = styled.li<{ style?: React.CSSProperties }>`
  line-height: 40px;
  margin: 0px 16px 0px 0px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  flex: none;
  list-style-type: none;

  &:hover,
  &:focus {
    border-bottom: 1px solid #3246b9;
  }

  &[aria-selected='true'] {
    font-weight: 700;
    color: #3246b9;
    border-bottom: 2px solid #3246b9;
  }
  /* should be the last line */
  ${(props) => props.style};
`;

const TabListContainer = styled.ul`
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  box-sizing: border-box;
  overflow-x: auto;
  margin-bottom: 0;
`;

const TabPanel = styled.div<{ isActive: boolean; style?: React.CSSProperties }>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  /* should be the last line */
  ${(props) => props.style};
`;

export const TabList: React.FC<TabListProps> = ({
  tabs,
  tabListStyle,
  tabStyle,
  tabPanelStyle,
  activeTab,
  ...props
}) => {
  const containerRef = React.createRef<HTMLUListElement>();

  const {
    // tabRefs,
    // cursor,
    // cursorMode,
    onKeyDown,
    onMouseMove,
    onFocus,
    onBlur,
  } = useKeyboardNavigation(ElementType.TabList, tabs.length, {
    onEnter: (cursor: number) => {
      tabs[cursor].onClick?.();
    },
  });

  return (
    <>
      <TabListContainer
        role="tablist"
        ref={containerRef}
        tabIndex={0}
        {...props}
        style={tabListStyle}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {tabs.map(({ id, Label, onClick }, index) => (
          <Tab
            key={id}
            aria-selected={activeTab === id}
            onMouseMove={() => {
              onMouseMove(index);
            }}
            onClick={() => {
              onClick?.();
            }}
            style={tabStyle}
            // ref={tabRefs[index]}
          >
            {Label}
          </Tab>
        ))}
      </TabListContainer>
      {tabs.map(({ id, PanelContent }) => (
        <TabPanel key={`panel-${id}`} isActive={activeTab === id} role="tabpanel" tabIndex={0} style={tabPanelStyle}>
          {PanelContent}
        </TabPanel>
      ))}
    </>
  );
};

TabList.displayName = 'ARIA.TabList';
