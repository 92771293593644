import React, { ReactNode } from 'react';
import config from '../../config';
import { Security, Auth } from '@okta/okta-react';
import { AuthProvider } from './AuthContext';
import { createBrowserHistory } from 'history';

type AppSecurityProps = {
  children: ReactNode;
};

type Authenticate = {
  history: () => string;
  issuer: () => string;
  redirect: () => string;
  client_id: string;
};

type ConfigType = {
  issuer: string;
  redirect_uri: string;
  client_id: string;
};
type EnvType = {
  test: ConfigType;
  development: ConfigType;
  production: ConfigType;
};

const env: string = process.env.NODE_ENV || 'development';
const authConfig: ConfigType = config[env as keyof EnvType];
const expireCookie = (cookie: string) => {
  document.cookie = `${cookie}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const auth = new Auth({
  history: createBrowserHistory(),
  issuer: authConfig.issuer,
  redirect_uri: authConfig.redirect_uri,
  client_id: authConfig.client_id,
  onAuthRequired: ({ auth }: { auth: Authenticate }) => {
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('okta-token-storage');
    expireCookie('okta-oauth-nonce');
    expireCookie('okta-oauth-state');

    auth.redirect();
  },
});

const AppSecurity = ({ children }: AppSecurityProps): JSX.Element => (
  <Security auth={auth}>
    <AuthProvider>{children}</AuthProvider>
  </Security>
);

export default AppSecurity;
