import { LOCALE_CONSTANTS } from '../constants/reducer-constants';
import { ReducerActionType } from '../types';

const { SET_LOCALE, SET_TARGET_LANG } = LOCALE_CONSTANTS;

export const setLocale = (payload: string): ReducerActionType => {
  return {
    type: SET_LOCALE,
    payload,
  };
};

export const setTargetLang = (payload: string): ReducerActionType => {
  return {
    type: SET_TARGET_LANG,
    payload,
  };
};
