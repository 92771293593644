import styled, { css } from 'styled-components';
import Button from '../StyledComponents/Button';
import { modalSize } from './config';

const ModalWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  ${({ isCentered }) =>
    isCentered &&
    css`
      align-items: center;
    `}
`;
const ModalContent = styled.aside`
  width: ${({ size = 'sm' }: { size: keyof typeof modalSize }) => modalSize[size] || modalSize['sm']};
  display: flex;
  flex-direction: column;
  position: relative;
  outline: 0px;
  border-radius: 0.375rem;
  background: rgb(255, 255, 255);
  color: inherit;
  margin-top: 1rem;
  margin-bottom: 3.75rem;
  z-index: 1500;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    width: 95%;
  }

  /* Small devices (landscape phones, less than 768px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 85%;
  }

  /* Medium devices */
  @media (max-width: 992px) {
    margin: 1em 1em;
    width: 100%;
  }
  ${(props) => props?.style};
`;

const ModalHeaderWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
`;

const ModalBodyWrapper = styled.div`
  padding: 0.5rem 1.5rem;
  flex: 1 1 0%;
  ${(props) => props?.style};
`;

const ModalFooterWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
`;

const ModalCloseButton = styled(Button)`
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background: transparent;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  :hover {
    color: #333;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1400;
`;

export {
  ModalWrapper,
  ModalContent,
  ModalHeaderWrapper,
  ModalBodyWrapper,
  ModalFooterWrapper,
  ModalCloseButton,
  ModalOverlay,
};
