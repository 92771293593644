import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import AlertHeader from './Alert.Header';
import AlertBody from './Alert.Body';
import { CloseIcon, CautionIcon } from '@condenast/platform-icons';

const AlertWrapper = styled.div`
  display: flex;
  color: #171717;
  border: 1px solid #ddd;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 4px;
  border-left: 4px solid #fdd13a;
  margin-bottom: 2rem;
`;

const AlertContentWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const CloseLink = styled.a`
  border: 0;
  position: absolute;
  right: 0;
  color: #171717;
  text-decoration: none;
  :hover {
    color: #333;
  }
`;

const IconWraper = styled.div`
  margin: 0 1rem 0 0.5rem;
`;

/**
 * Usage
 * <Alert open={true}>
 *    <Alert.Header>
 *        GQ Blobal Story Drafts
 *    </Alert.Header>
 *    <Alert.Body>
 *        Body text
 *    </Alert.Body>
 * </Alert>
 */
type alertPropType = {
  open: boolean;
  children?: ReactNode;
};
const Alert = ({ open = false, children }: alertPropType): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  if (!isOpen) return null;

  return (
    <AlertWrapper>
      <IconWraper>
        <CautionIcon data-testid="alert-icon" color="#B8992F" size={'small'} />
      </IconWraper>
      <AlertContentWrapper>
        <CloseLink data-testid="alert-close-icon" onClick={handleClose}>
          <CloseIcon size="small" color="#171717" />
        </CloseLink>
        {children}
      </AlertContentWrapper>
    </AlertWrapper>
  );
};

Alert.Header = AlertHeader;
Alert.Body = AlertBody;

export default Alert;
