import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

/* eslint-disable react-hooks/exhaustive-deps */
const useSetSelectedDestination = (): [string | null, Dispatch<SetStateAction<string | null>>] => {
  const intl = useIntl();
  const [selectedDestination, setSelectedDestination] = useState<string | null>(null);
  useEffect(() => {
    setSelectedDestination(
      intl.formatMessage({
        id: 'create.modal.createDraftInCopilot',
        defaultMessage: 'Create a draft in Copilot for...',
        description: 'Placeholder for brand selection',
      })
    );
  }, []);
  return [selectedDestination, setSelectedDestination];
};
export default useSetSelectedDestination;
