import { useEffect } from 'react';
import { setDraftFormSourceLanguage, setDraftViewTranslationResponse } from '../../../actions/draftView';
import { useGlobalStateValue } from '../../../hooks/useGlobalContext';

/* eslint-disable react-hooks/exhaustive-deps */

// Used to update content details to global reducer
type slatDataTypes = {
  hed: string;
  description: string;
  dek: string;
  body: string;
  language: string;
};
const useUpdateContentDetails = (slatData: slatDataTypes): void => {
  const { dispatch } = useGlobalStateValue();
  useEffect(() => {
    const { hed, description: dek, body, language: sourceLang } = slatData;
    // Set the source language to reducer
    dispatch && dispatch(setDraftFormSourceLanguage(sourceLang));
    // eslint-disable-next-line
    const payload: any = {};
    payload[sourceLang] = { hed, dek, body };
    // Set the slatdata to l10n object against sourcelanguage
    dispatch && dispatch(setDraftViewTranslationResponse(payload));
  }, []);
};

export default useUpdateContentDetails;
