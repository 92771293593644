const syndicationErrors = {
  getNotSyndicatable: () => ({
    title: 'Oh no! This content cannot be syndicated…',
    message: 'Please contact <a target="_blank" href="mailto:support@condenastint.com">support@condenastint.com</a>',
  }),
  getDefault: () => ({
    title:
      'Oh no! We are having problems creating your syndication. Please use the Chat Bubble to contact Product Support.',
    message:
      'Please try again later and contact <a target="_blank" href="mailto:support@condenastint.com">support@condenastint.com</a>',
  }),
};

function getSyndicationErrorMessage(brandCode, errorCode = 500) {
  if (errorCode >= 400 && errorCode < 500) {
    return syndicationErrors.getNotSyndicatable();
  }
  return syndicationErrors.getDefault();
}

export default getSyndicationErrorMessage;
