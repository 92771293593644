import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import * as queryString from 'query-string';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import DiscoverySlat from './DiscoverySlat';
import useApi from '../../hooks/useApi';
import { trackEvent } from '../../helpers/googleAnalytics';
import { Main, SlatList } from './styles';
import Loader from './Loader/index';
import { LEGACY_SYNDICATION, TRANSLATED_DISCOVERY, TRANSLATED_SYNDICATION } from '../../helpers/flags';
import { useGlobalStateValue } from '../../hooks/useGlobalContext';
import { DiscoverySlatType, ResponseType } from './util/helper';

const apiUrl = '/api/v1/contents/';

type DiscoveryListpropTypes = {
  brands: string[];
  markets: string[][];
  display?: string[];
  languages?: string[];
  q?: string[];
  dateRange: string[];
  isGlobalDraft?: null | boolean;
  data?: [];
  hasMore: boolean;
};

const DiscoveryList = ({
  brands,
  display,
  languages,
  markets,
  q,
  dateRange,
  isGlobalDraft = false,
}: DiscoveryListpropTypes): JSX.Element => {
  let isFeatureEnabled: boolean = useFeatureToggle(TRANSLATED_SYNDICATION);

  if (useFeatureToggle(LEGACY_SYNDICATION)) {
    // Tried && !condition. Hooks don't work that way apparently
    isFeatureEnabled = false;
  }
  const translatedDiscovery = useFeatureToggle(TRANSLATED_DISCOVERY);
  const [contents, setContents] = useState<DiscoverySlatType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [url, setUrl] = useState<string>('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const didMount = useRef(false);
  const {
    state: {
      locales: { targetLang },
    },
  } = useGlobalStateValue();

  const { data, loading: apiLoader } = useApi<ResponseType>('get', url);

  const discoveryList = data;
  const processLoadedContents = (res: ResponseType) => {
    const { data = [], hasMore } = res;
    const newContents = data as DiscoverySlatType[];
    setHasMore(hasMore);
    newContents?.length && setContents(uniqBy([...contents, ...newContents], (item) => item['id']));
    setLoading(false);
  };

  const fetchNextPage = () => {
    if (!loading) {
      setPage((prev) => (!prev ? 1 : prev + 1));
    }
  };

  const fetchContents = () => {
    setLoading(true);
    let qs = queryString.stringify(
      {
        brands,
        markets,
        languages,
        display,
        q,
        dateRange,
        page,
        excludeSyndicated: true,
        targetLang: targetLang || 'none',
        isGlobalDraft,
      },
      { arrayFormat: 'comma' }
    );
    if (isGlobalDraft) {
      qs += '&requestSource=sharedDraft';
    }
    setUrl(`${apiUrl}markets?${qs}`);
    if (page > 1) {
      trackEvent('Result list', 'Loading more results', { label: `Page ${page}` });
    }
  };

  const debouncedFetchNextPage = debounce(fetchNextPage, 500);

  useEffect(() => {
    discoveryList && !apiLoader && processLoadedContents(discoveryList);
    // eslint-disable-next-line
  }, [discoveryList, apiLoader]);

  useEffect(() => {
    (() => {
      if (!page) {
        fetchNextPage();
      } else {
        !loading && fetchContents();
      }
    })();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (translatedDiscovery && didMount.current) {
      setPage(0);
      setContents([]);
      document.querySelector('.home-page .content-container')?.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (!didMount.current) {
      didMount.current = true;
    }
    trackEvent('Discovery', 'Encore Translation', { dimension1: '', dimension2: targetLang });
    // eslint-disable-next-line
  }, [targetLang]);
  /* eslint-enable */

  return (
    <Main>
      <InfiniteScroll
        loadMore={debouncedFetchNextPage}
        initialLoad={false}
        pageStart={0}
        hasMore={hasMore}
        loader={<Loader key={0} count={5} />}
        useWindow={false}
        getScrollParent={() =>
          document.querySelector('.home-page .content-container') ||
          document.querySelector('.admin-page .content-container')
        }
      >
        {!hasMore && !contents.length ? (
          <p>No results</p>
        ) : (
          <SlatList data-cy="slat--list">
            <DiscoverySlat contents={contents} isFeatureEnabled={isFeatureEnabled} />
          </SlatList>
        )}
      </InfiniteScroll>
    </Main>
  );
};

export default React.memo(DiscoveryList);
