import { GLOBAL_DRAFT_CONSTANTS } from '../constants/reducer-constants';
const { SET_GLOBAL_DRAFT, SET_GLOBAL_DRAFT_TARGET_BRANDS } = GLOBAL_DRAFT_CONSTANTS;
import { SelectedGlobalDraftType } from '../types';

type GlobalDraftReducerActionType = {
  type: string;
  payload: SelectedGlobalDraftType;
};

type GlobalDraftsTargetBrandsActionType = {
  type: string;
  payload: string[];
};

export const setGlobalDraft = (payload: SelectedGlobalDraftType): GlobalDraftReducerActionType => {
  return {
    type: SET_GLOBAL_DRAFT,
    payload,
  };
};

export const setGlobalDraftsTargetBrands = (payload: string[]): GlobalDraftsTargetBrandsActionType => {
  return {
    type: SET_GLOBAL_DRAFT_TARGET_BRANDS,
    payload,
  };
};
