import React from 'react';
import PropTypes from 'prop-types';
import { RIGHTS_PHASE_ONE } from '../../../helpers/flags';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import DraftPreviewWithRights from './DraftPreviewWithRights';
import DraftPreviewV1 from './DraftPreviewV1';

interface DraftPreviewSlatType {
  id: string;
  promoImageUri: string;
  authors: string[];
  publishUri: string;
  hed: string;
  body: string;
  description: string;
  publishDate: string;
  marketBrandCode: string;
  canSyndicate: boolean;
  updatedAt: string;
  marketBrand: { name: string };
  type: string;
  language: string;
  sourceUri: string;
}
type DraftviewProptypes = {
  slatData: DraftPreviewSlatType;
  marketBrand: string;
};

const DraftPreview = ({ slatData, marketBrand }: DraftviewProptypes): JSX.Element => {
  const rightsPhaseOneEnabled = useFeatureToggle(RIGHTS_PHASE_ONE);
  if (rightsPhaseOneEnabled) {
    return <DraftPreviewWithRights slatData={slatData} marketBrand={marketBrand} />;
  }
  return <DraftPreviewV1 slatData={slatData} marketBrand={marketBrand} />;
};

DraftPreview.propTypes = {
  slatData: PropTypes.object.isRequired,
};

export default DraftPreview;
