import React, { ReactNode } from 'react';
import { ModalHeaderWrapper, ModalCloseButton } from './styles';

type ModalFooterropTypes = {
  children?: ReactNode;
  closeButton?: boolean;
};

const ModalHeader = ({ children, closeButton }: ModalFooterropTypes): JSX.Element => (
  <>
    {!!children && (
      <ModalHeaderWrapper>
        {children}
        {!!closeButton && (
          <ModalCloseButton data-cy="close-button">
            <span data-testid="span" aria-hidden="true">
              ×
            </span>
          </ModalCloseButton>
        )}
      </ModalHeaderWrapper>
    )}
  </>
);

export default ModalHeader;
