import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ToggleFeature } from '@flopflip/react-broadcast';
import { Toast } from '@condenast/formation-ui-components';
import { Modal } from '../modals/Modal';
import useApi from '../../hooks/useApi';
import { withUser } from '../App/AuthContext';
import { TARGET_DEMO_BRAND } from '../../helpers/flags';
import getSyndicationErrorMessage from './errors';
import Spinner from '../Spinner';
import { trackEvent } from '../../helpers/googleAnalytics';
import { trackCustomSnowplowActions, ActionType } from '../../analytics/snowplow';

import sortBrandMarkets from './sortBrandMarkets';

const DEMO_BRANDS = [
  { name: 'Demo', code: 'dmo' },
  { name: 'Demo EU', code: 'demoeu' },
  { name: 'Demo AP', code: 'demoap' },
];

const reducer = (state, action) => {
  switch (action?.type) {
    case 'setTargetBrand':
      return { ...state, status: 'ready', targetBrand: action.payload };
    case 'pending':
      return { ...state, status: 'pending' };
    case 'error':
      return { ...state, status: 'error', mesage: action.payload };
    case 'duplicate':
      return { ...state, status: 'duplicate', targetCopilotUri: action.payload };
    case 'success':
      return { ...state, status: 'success', targetCopilotUri: action.payload };
    default:
      return state;
  }
};

const initialState = {
  status: 'ready',
};

const SyndicationModal = ({ content = {}, user, onDismiss }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const targetBrand = state.targetBrand;

  const onChangeHadler = (e) => setTargetBrand(e.target.value);

  const setTargetBrand = (targetBrand) => {
    if (!targetBrand) return;
    trackEvent('Modal - Syndicate', 'Target brand change', { label: targetBrand });
    trackCustomSnowplowActions('encore_content', {
      target_brand: targetBrand,
      source_brand: content.marketBrandCode,
      source_id: content.id,
      target_id: null,
      content_type: content.type,
      source_lang: content.language,
      target_lang: '',
    });
    return dispatch({ type: 'setTargetBrand', payload: targetBrand });
  };

  const syndicating = state.status === 'pending';

  const [errorMessage, setErrorMessage] = useState({ isVisible: false, title: '', message: '' });
  const sourceBrand = content.marketBrandCode;

  useEffect(() => {
    const savedTargetBrand = localStorage.getItem('targetBrand');
    const nextTargetBrand = savedTargetBrand && savedTargetBrand !== sourceBrand ? savedTargetBrand : '';
    setTargetBrand(nextTargetBrand);
  }, [sourceBrand]);

  useEffect(() => {
    !!targetBrand && localStorage.setItem('targetBrand', targetBrand);
  }, [targetBrand]);

  const { data } = useApi(`get`, `/api/v1/market-brands`);
  const { callApi } = useApi(`post`, `/api/v1/syndicate`);
  const targetBrands = data && data.data ? sortBrandMarkets(data.data) : [];

  async function onSyndicate() {
    if (syndicating) {
      return;
    }

    dispatch({ type: 'pending' });
    trackEvent('Modal - Syndicate', 'Create Draft button pressed');
    const { type, sourceUri, marketBrandCode: sourceBrand, targetLang, l10n } = content;
    const data = {
      sourceUri,
      sourceBrand,
      targetBrand,
      syndicationAuthor: user.name,
      type,
      translate: false,
      targetLang,
      l10n,
    };

    trackCustomSnowplowActions(ActionType.EncoreComponentTrackingEvent, {
      component: 'syndication_modal',
      label: 'encore_syndication_preview',
      type: 'click',
    });

    const config = {
      data,
      validateStatus: (status) => {
        return [200, 409].includes(status);
      },
    };
    const { data: syndicateData, error, status } = await callApi(config);

    if (error) {
      dispatch({ type: 'error' });
      const errorTitleAndMessage = getSyndicationErrorMessage(
        targetBrand,
        error?.response && error?.response?.status ? error?.response?.status : null
      );
      setErrorMessage({ isVisible: true, ...errorTitleAndMessage });
    } else {
      dispatch({ type: status === 200 ? 'success' : 'duplicate', payload: syndicateData?.targetCopilotUri });
    }
  }

  function renderBrandOption(brand) {
    return (
      <option name={brand.name} key={brand.code} value={brand.code} disabled={brand.code === sourceBrand}>
        {brand.name}
      </option>
    );
  }

  const marketTitles = {
    ready: 'Syndicate content',
    pending: 'Creating draft in Copilot...',
    success: 'Draft complete',
    duplicate: 'Content already syndicated',
  };

  return (
    <Modal
      onDismiss={onDismiss}
      title={marketTitles[state.status] || marketTitles.ready}
      customClassName="modal--syndication"
    >
      <div className="grid-container cols-4">
        <div className="span-3">
          <div className="select-wrapper">
            <select
              className="select-standard"
              data-testid="select-from-copilot"
              value={targetBrand}
              onChange={onChangeHadler}
            >
              <option data-testid="opt-list" value="" disabled>
                Create a draft in Copilot for...
              </option>
              <ToggleFeature flag={TARGET_DEMO_BRAND}>
                <>{DEMO_BRANDS.map(renderBrandOption)}</>
              </ToggleFeature>
              {targetBrands.map(renderBrandOption)}
            </select>
          </div>
        </div>
        {['success', 'duplicate'].includes(state.status) && state.targetCopilotUri ? (
          <a
            href={state.targetCopilotUri}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackEvent('Modal - Syndicate', 'View in Copilot clicked')}
            className="copilot-link"
          >
            View in Copilot
          </a>
        ) : (
          <div className={classNames({ 'progress--group': syndicating })}>
            <button
              disabled={syndicating || !targetBrand}
              type="submit"
              className="button button--primary"
              onClick={onSyndicate}
            >
              Create draft
            </button>
            {syndicating && <Spinner size="x-small" />}
          </div>
        )}
      </div>
      {state.status === 'error' && errorMessage.isVisible ? (
        <Toast
          onClose={() => {
            setErrorMessage({ ...errorMessage, isVisible: false });
          }}
          show={errorMessage.isVisible}
          title={errorMessage.title}
          type="danger"
          customClassName="toast--syndication"
        >
          <div dangerouslySetInnerHTML={{ __html: errorMessage.message }} />
        </Toast>
      ) : (
        <p data-testid="image-rights-text" className="rights-information" data-cy="rights-information">
          Have you checked <strong>image rights</strong>? Images must be checked before publishing on Copilot. All Getty
          images can be reused in context.
        </p>
      )}
    </Modal>
  );
};

SyndicationModal.propTypes = {
  content: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default withUser(SyndicationModal);
