import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function sentryInit(): void {
  Sentry.init({
    beforeSend(event, hint) {
      const error = hint?.originalException;
      /** we don't want non erros to log */
      if (error && (error as Error).message && (error as Error).message.match(/Non-Error/i)) {
        return null;
      }
      return event;
    },
    environment: process.env.REACT_APP_NODE_SENTRY_ENV || 'development',
    dsn: 'https://a61665dc62e54877a1a94eabac5107a4@o91515.ingest.sentry.io/1528361',
    integrations: [new Integrations.BrowserTracing()],
    autoSessionTracking: true,
  });
}
